import React, { useEffect } from "react";
import * as THREE from "three";

import { useLoader, useThree } from "@react-three/fiber";
import { tilePaths } from "./lookup";
import { animated, SpringValue, useSpring } from "@react-spring/three";
import { useGesture } from "@use-gesture/react";
import BaseTile from "./BaseTile";
export type Props = {
  code: string;
  x: number;
  scale?: number;
  isActive?: boolean;
  allowDrag?: boolean;
  shouldReset?: boolean;
  startDrag?: () => void;
  endDrag?: () => void;
  onClick?: () => void;
  rotateX?: number | SpringValue<number>;
  rotateY?: number | SpringValue<number>;
  positionZ?: number | SpringValue<number>;
};

function GestureTile({
  x: start = 0,
  isActive = false,
  allowDrag = false,
  shouldReset = true,
  startDrag = () => {},
  endDrag = () => {},
  code,
  scale = 1,
  onClick = () => {},
  rotateX = 0,
  rotateY = 0,
  positionZ = 0,
}: Props) {
  const { viewport } = useThree();
  const { factor } = viewport;
  const [{ x, y, z }, api] = useSpring(() => ({ x: 0, y: 0, z: positionZ }));
  const dragConfig = {
    bounds: { left: 0, right: 0, bottom: 0 },
  };
  const bind = useGesture(
    {
      onDrag: ({ down, first, last, movement: [mx, my] }) => {
        const allowMove = isActive && allowDrag;
        if (allowMove && my < -150 && last) {
          onClick();
          endDrag();
          api.start({
            x: 10 - start,
            y: 0,
            z: -1.5,
          });
        } else {
          if (down && first) {
            startDrag();
          } else if (!down && last) {
            endDrag();
          }
          api.start({
            x: down && allowMove ? mx / factor : 0,
            y: down && allowMove ? -my / factor : 0,
            z: 0,
            immediate: down,
          });
        }
      },
    },
    { drag: dragConfig, hover: { mouseOnly: true } }
  );

  const texture = useLoader(THREE.TextureLoader, tilePaths[code]);
  texture.minFilter = THREE.LinearFilter;

  useEffect(() => {
    if (!isActive && shouldReset) {
      api.start({ x: 0, y: 0, z: 0 });
    }
  }, [isActive, api, shouldReset]);
  return (
    <animated.group
      {...(bind() as any)}
      rotation-x={rotateX}
      rotation-y={rotateY}
      position-x={x}
      position-z={z}
      position-y={y}
    >
      <BaseTile x={start} isActive={isActive} code={code} scale={scale} />
    </animated.group>
  );
}

export default GestureTile;
