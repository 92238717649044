import React from "react";
import { useSpring, useChain, useSpringRef } from "@react-spring/three";

import GestureTile from "../../components/three/GestureTile";
export type Props = {
  code: string;
  x: number;
  scale?: number;
  onClick?: () => void;
};

function Tile({ x = 0, code, scale = 1, onClick = () => {} }: Props) {
  const springRef = useSpringRef();
  const transitionRef = useSpringRef();

  const { positionZ } = useSpring({
    from: { positionZ: -15 },
    to: { positionZ: 0 },
    ref: springRef,
  });
  const { rotateX } = useSpring({
    from: { rotateX: Math.PI / 2 },
    to: { rotateX: 0 },
    ref: transitionRef,
  });

  useChain([springRef, transitionRef]);

  return (
    <GestureTile
      x={x}
      code={code}
      scale={scale}
      onClick={onClick}
      positionZ={positionZ}
      rotateX={rotateX}
    />
  );
}

export default Tile;
