import styled from "styled-components";

export const CaptureText = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  border: 0.2rem solid ${({ theme }) => theme.primary};
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  padding: 1.5rem;
  color: ${({ theme }) => theme.primary};
  border-radius: 3rem;
  user-select: none;
`;
