import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import Loader from "../../components/common/Loader";
import { updateLocale } from "../auth/reducer";
import {
  selectClientId,
  selectLocale,
  selectProfileImage,
  selectTurnTime,
} from "../auth/selector";
import { usePlayMatchMutation } from "../match/service";
import Home from "./Home";
import { useStatisticQuery } from "./service";
import { useSelector } from "react-redux";

const Container = () => {
  const image = useSelector(selectProfileImage);
  const { data: statistics, isLoading } = useStatisticQuery();
  const navigate = useNavigate();
  const [playMatch, playStatus] = usePlayMatchMutation();
  const dispatch = useAppDispatch();
  const clientId = useSelector(selectClientId);
  const turnTime = useSelector(selectTurnTime);
  const locale = useSelector(selectLocale);

  useEffect(() => {
    if (image) {
      navigate("/profile");
    }
  }, [navigate, image]);

  useEffect(() => {
    if (playStatus.isSuccess) {
      navigate(`/game/${playStatus.data.id}`);
    }
  }, [playStatus.isSuccess, navigate, playStatus?.data?.id]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Home
        selectLocale={(l) => dispatch(updateLocale(l))}
        locale={locale}
        statistics={statistics}
        playVsBots={() => playMatch({ turnTime: turnTime })}
        clientId={clientId}
      />
    );
  }
};

export default Container;
