import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GameFrame } from "../../api/mahjong-api";
import { getLatestFrame } from "../match/match-utility";
import { matchApi } from "../match/service";

// Define a type for the slice state
interface GameState {
  currentFrame?: GameFrame;
  previousFrames: GameFrame[];
  bufferFrames: GameFrame[];
}

// Define the initial state using that type
const initialState: GameState = {
  currentFrame: undefined,
  previousFrames: [],
  bufferFrames: [],
};

export const gameSlice = createSlice({
  name: "game",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateGameFrame: (state, action: PayloadAction<GameFrame>) => {
      // state.previousFrames.unshift(action.payload);
      // state.currentFrame = action.payload;
      state.bufferFrames.push(action.payload);
    },
    nextFrame: (state) => {
      if (state.bufferFrames.length) {
        state.currentFrame = state.bufferFrames.shift();
      }
    },
    nextFrameClear: (state) => {
      if (state.bufferFrames.length) {
        state.currentFrame = state.bufferFrames.shift();
      } else {
        state.currentFrame = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addMatcher(
      matchApi.endpoints.getMatch.matchFulfilled,
      (state, action) => {
        const latestFrame = getLatestFrame(action.payload.match);
        if (latestFrame) {
          state.currentFrame = latestFrame;
          state.bufferFrames = [];
        }
      }
    );
  },
});

export const { updateGameFrame, nextFrame, nextFrameClear } = gameSlice.actions;

export default gameSlice.reducer;
