/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Calculation {
  /** @format int32 */
  id: number;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt?: string | null;

  /** @format date-time */
  deletedAt?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  hand: Hand;
  outOn: Tile;
  seatWind: Wind;
  roundWind: Wind;

  /** @format int32 */
  turn?: number | null;
  result?: HandResult;
}

export enum CaptureKind {
  Win = "Win",
  Kong = "Kong",
  Pung = "Pung",
  Chow = "Chow",
  Pass = "Pass",
  Draw = "Draw",
  ActiveDraw = "ActiveDraw",
}

export interface CaptureRequest {
  /** @format int32 */
  matchId: number;

  /** @format int32 */
  tileId: number;
  kind: CaptureKind;
  meldIds: number[];
  codes: string[];
}

export interface ContinueMatchRequest {
  /** @format int32 */
  matchId: number;
}

export interface CreateCalculationRequest {
  outOn: Tile;
  flowers: Tile[];
  concealed: Tile[];
  melded: Meld[];
  seatWind: Wind;
  roundWind: Wind;
}

export interface CreateCalculationResponse {
  /** @format int32 */
  id: number;
}

export interface CreateMatchRequest {
  name: string;

  /** @format int32 */
  rounds: number;

  /** @format int32 */
  openSpots: number;

  /** @format int32 */
  minPoints: number;
  replayDealerWins: boolean;

  /** @format int32 */
  turnTime: number;

  /** @format int32 */
  matchTemplateId?: number | null;

  /** @format int32 */
  playerId?: number | null;
}

export interface CreateMatchResponse {
  errors?: Record<string, string>;

  /** @format int32 */
  id?: number;
}

export interface Discard {
  tile?: Tile;
  discardPlayerId?: string | null;

  /** @format date-time */
  discardTime?: string;
  claimedBy?: string | null;

  /** @format int32 */
  frameId?: number | null;
}

export interface DiscardRequest {
  /** @format int32 */
  matchId: number;

  /** @format int32 */
  tileId?: number;
}

export interface DrawTileRequest {
  /** @format int32 */
  matchId: number;
}

export enum Face {
  E = "E",
  S = "S",
  W = "W",
  N = "N",
  RD = "RD",
  GD = "GD",
  WD = "WD",
  Wild = "Wild",
  Hidden = "Hidden",
  C1 = "C1",
  C2 = "C2",
  C3 = "C3",
  C4 = "C4",
  C5 = "C5",
  C6 = "C6",
  C7 = "C7",
  C8 = "C8",
  C9 = "C9",
  D1 = "D1",
  D2 = "D2",
  D3 = "D3",
  D4 = "D4",
  D5 = "D5",
  D6 = "D6",
  D7 = "D7",
  D8 = "D8",
  D9 = "D9",
  B1 = "B1",
  B2 = "B2",
  B3 = "B3",
  B4 = "B4",
  B5 = "B5",
  B6 = "B6",
  B7 = "B7",
  B8 = "B8",
  B9 = "B9",
  F1 = "F1",
  F2 = "F2",
  F3 = "F3",
  F4 = "F4",
  S1 = "S1",
  S2 = "S2",
  S3 = "S3",
  S4 = "S4",
}

export interface FindCalculationsResponse {
  calculations?: Calculation[] | null;
}

export interface FindGameFramesResponse {
  frames?: GameFrame[] | null;
}

export interface FindGameResultsResponse {
  results: GameResult[];
}

export interface FindMatchesResponse {
  matches?: Match[] | null;
}

export interface FindPlayerSummariesResponse {
  players?: PlayerSummary[] | null;
}

export interface Game {
  /** @format int32 */
  id: number;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt?: string | null;

  /** @format date-time */
  deletedAt?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  tileSet?: WallTileSet;
  discards?: Discard[] | null;
  seats?: Record<string, Seat>;
  activeClientId?: string | null;
  wind?: Wind;
  isComplete?: boolean;

  /** @format int32 */
  matchId?: number;
  currentFrame?: GameFrame;
  lastDiscard?: Discard;
}

export interface GameDayResult {
  /** @format int32 */
  year: number;

  /** @format int32 */
  month: number;

  /** @format int32 */
  day: number;
  clientId: string;
  player?: Player;

  /** @format int32 */
  points: number;
  lastFrame?: GameFrame;
}

export interface GameFrame {
  /** @format int32 */
  matchId?: number;

  /** @format int32 */
  gameId?: number;
  requestClientId: string;

  /** @format int32 */
  sequence: number;
  requestType: string;
  requestValue: string;
  isComplete: boolean;

  /** @format int32 */
  wallCount: number;
  discards: Discard[];
  activeClientId: string;
  seats: Record<string, Seat>;
  wind: Wind;
  matchComplete: boolean;
  captureOptions?: Record<string, CaptureRequest[]>;
}

export interface GameResult {
  /** @format int32 */
  matchId?: number;

  /** @format int32 */
  gameId?: number;
  clientId?: string | null;
  player?: Player;

  /** @format int32 */
  points?: number;
}

export interface GetMatchResponse {
  match?: Match;
}

export interface GetMatchTemplateResponse {
  /** @format int32 */
  id?: number;
  template?: MatchTemplate;
  results?: GameResult[] | null;
  previousResults?: GameDayResult[] | null;
}

export interface GetPlayerStatisticsResponse {
  /** @format int32 */
  gamesPlayed?: number;

  /** @format int32 */
  gamesWon?: number;

  /** @format int32 */
  matchesPlayed?: number;

  /** @format int32 */
  matchesWon?: number;
}

export interface GetPlayerSummaryResponse {
  player?: PlayerSummary;
}

export interface GetProfileResponse {
  email: string;
  imagePath: string;
  firstName?: string | null;
  lastName?: string | null;
  locale?: string | null;
  userId?: string | null;

  /** @format int32 */
  playerId?: number;
}

export interface GetRulesResponse {
  rules?: Rule[] | null;
  meldRules?: Rule[] | null;
  specialRules?: Rule[] | null;
  generalRules?: Rule[] | null;
}

export interface GetStatisticsResponse {
  errors?: Record<string, string>;

  /** @format int32 */
  players?: number;

  /** @format int32 */
  games?: number;
}

export interface GetTilesResponse {
  winds: Tile[];
  characters: Tile[];
  bamboos: Tile[];
  dots: Tile[];
  dragons: Tile[];
  flowers: Tile[];
}

export interface Hand {
  flowers: Tile[];
  concealed: Tile[];
  melded: Meld[];
  drawn?: Tile;
}

export interface HandResult {
  matchedRules?: PatternMatchResult[] | null;
  kind?: ResultKind;

  /** @format int32 */
  totalPoints?: number;
}

export interface History {
  rounds?: Round[] | null;
}

export interface JoinMatchRequest {
  /** @format int32 */
  matchId: number;

  /** @format int32 */
  playerId?: number | null;
}

export enum Kind {
  Character = "Character",
  Bamboo = "Bamboo",
  Dot = "Dot",
  Wind = "Wind",
  Dragon = "Dragon",
  Wild = "Wild",
  Flower = "Flower",
  Unknown = "Unknown",
}

export interface Match {
  /** @format int32 */
  id: number;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt?: string | null;

  /** @format date-time */
  deletedAt?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  name?: string | null;
  settings?: MatchSettings;
  history?: History;

  /** @format int32 */
  matchTemplateId?: number | null;
  clients?: MatchClient[] | null;
  status?: MatchStatus;
  result?: MatchResult;
}

export interface MatchClient {
  clientId: string;
  player?: Player;
}

export interface MatchResult {
  gameResults?: GameResult[] | null;
}

export interface MatchSettings {
  /** @format int32 */
  totalRounds?: number;
  replayDealerWins?: boolean;

  /** @format int32 */
  minPoints?: number;

  /** @format int32 */
  turnTime?: number;
}

export enum MatchStatus {
  Waiting = "Waiting",
  Started = "Started",
  Completed = "Completed",
}

export interface MatchTemplate {
  /** @format int32 */
  id: number;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt?: string | null;

  /** @format date-time */
  deletedAt?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;

  /** @format int32 */
  year?: number;

  /** @format int32 */
  month?: number;

  /** @format int32 */
  day?: number;
  games?: Game[] | null;
  round?: Wind;
  seat?: Wind;
}

export interface Meld {
  isHidden?: boolean;
  isConcealed?: boolean;
  meldKind?: MeldKind;
  tiles: Tile[];
  code: string;
  meldWith?: Tile;
}

export enum MeldKind {
  Chow = "Chow",
  Pung = "Pung",
  Kong = "Kong",
  Eye = "Eye",
}

export enum Pattern {
  Kongs = "Kongs",
  Winds = "Winds",
  Honors = "Honors",
  TwoConcealedPungs = "TwoConcealedPungs",
  ThreeConcealedPungs = "ThreeConcealedPungs",
  FourConcealedPungs = "FourConcealedPungs",
  FiveConcealedPungs = "FiveConcealedPungs",
  WarriorEyes = "WarriorEyes",
  PairPungOut = "PairPungOut",
  SingleTileOut = "SingleTileOut",
  FourToOne = "FourToOne",
  AllConcealed = "AllConcealed",
  AllConcealedSelfPick = "AllConcealedSelfPick",
  AllRevealed = "AllRevealed",
  NoTouchOneNine = "NoTouchOneNine",
  PureOneSuit = "PureOneSuit",
  MixedOneSuit = "MixedOneSuit",
  TwoSuits = "TwoSuits",
  AllChows = "AllChows",
  AllChowsNoHonorsNoFlowers = "AllChowsNoHonorsNoFlowers",
  AllPungs = "AllPungs",
  PureOneNine = "PureOneNine",
  MixedOneNine = "MixedOneNine",
  ConnectedOneNine = "ConnectedOneNine",
  TwoBrothers = "TwoBrothers",
  ThreeBrothers = "ThreeBrothers",
  FiveHouses = "FiveHouses",
  SmallThreeWinds = "SmallThreeWinds",
  LargeThreeWinds = "LargeThreeWinds",
  SmallFourWinds = "SmallFourWinds",
  LargeFourWinds = "LargeFourWinds",
  MixedDragon = "MixedDragon",
  MixedConcealedDragon = "MixedConcealedDragon",
  PureDragon = "PureDragon",
  PureConcealedDragon = "PureConcealedDragon",
  MixedTwoSisters = "MixedTwoSisters",
  MixedThreeSisters = "MixedThreeSisters",
  PureTwoSisters = "PureTwoSisters",
  PureThreeSisters = "PureThreeSisters",
  PureFourSisters = "PureFourSisters",
  SmallThreeDragons = "SmallThreeDragons",
  LargeThreeDragons = "LargeThreeDragons",
  SelfPick = "SelfPick",
  NoHonors = "NoHonors",
  NoHonorsNoFlowers = "NoHonorsNoFlowers",
  Flowers = "Flowers",
  NoFlowers = "NoFlowers",
  LiguLigu = "LiguLigu",
  ThirteenOrphans = "ThirteenOrphans",
  SixteenNoMelds = "SixteenNoMelds",
  ThreeNoMelds = "ThreeNoMelds",
  Dealer = "Dealer",
}

export interface PatternMatchResult {
  pattern: Pattern;

  /** @format int32 */
  points: number;
}

export interface PlayMatchRequest {
  /** @format int32 */
  playerId?: number | null;

  /** @format int32 */
  turnTime?: number;
}

export interface PlayMatchTemplateRequest {
  /** @format int32 */
  playerId?: number | null;

  /** @format int32 */
  turnTime?: number;

  /** @format int32 */
  matchTemplateId?: number;
}

export interface Player {
  /** @format int32 */
  id: number;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt?: string | null;

  /** @format date-time */
  deletedAt?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  name?: string | null;
  imageUrl?: string | null;
  email?: string | null;
  userId?: string | null;
}

export interface PlayerSummary {
  /** @format int32 */
  id: number;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt?: string | null;

  /** @format date-time */
  deletedAt?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  name?: string | null;
  imageUrl?: string | null;
  email?: string | null;
  userId?: string | null;

  /** @format int32 */
  gamesWon?: number;

  /** @format int32 */
  gamesPlayed?: number;
}

export enum ResultKind {
  Win = "Win",
  Lose = "Lose",
  Draw = "Draw",
  SelfPick = "SelfPick",
}

export interface Round {
  wind?: Wind;
  games?: Game[] | null;
}

export interface Rule {
  pattern?: Pattern;
  examples?: Calculation[] | null;
}

export interface Seat {
  clientId: string;
  wind: Wind;
  player?: Player;
  hand: Hand;
  outOn?: Tile;
  result?: HandResult;
  options: TurnRequest[];
}

export interface StartMatchRequest {
  /** @format int32 */
  matchId: number;
}

export interface Tile {
  /** @format int32 */
  id: number;
  face: Face;

  /** @format int32 */
  number?: number | null;
  code: string;
  kind: Kind;
}

export interface TurnRequest {
  /** @format int32 */
  matchId: number;
  kind: CaptureKind;
  meldIds: number[];
  codes: string[];
}

export interface UpdateCalculationRequest {
  outOn: Tile;
  flowers: Tile[];
  concealed: Tile[];
  melded: Meld[];
  seatWind: Wind;
  roundWind: Wind;

  /** @format int32 */
  id?: number;
}

export interface UpdatePlayerByUserIdRequest {
  userId?: string | null;
  name?: string | null;
  email?: string | null;
  imageUrl?: string | null;
  clientId?: string | null;
}

export interface WallTileSet {
  tiles?: Tile[] | null;

  /** @format int32 */
  skip?: number;

  /** @format int32 */
  wallCount?: number;
  hasDealt?: boolean;
}

export enum Wind {
  East = "East",
  South = "South",
  West = "West",
  North = "North",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Mahjong API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthExternalLoginCreate
     * @request POST:/api/auth/external-login
     * @secure
     */
    authExternalLoginCreate: (
      query?: { provider?: string; returnUrl?: string; clientId?: string },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/auth/external-login`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthExternalAuthCallbackList
     * @request GET:/api/auth/external-auth-callback
     * @secure
     */
    authExternalAuthCallbackList: (query?: { returnUrl?: string; clientId?: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/external-auth-callback`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calculation
     * @name CalculationsTilesList
     * @summary Finds all the sample mahjong hands
     * @request GET:/api/calculations/tiles
     * @secure
     */
    calculationsTilesList: (params: RequestParams = {}) =>
      this.request<GetTilesResponse, any>({
        path: `/api/calculations/tiles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calculation
     * @name CalculationsRulesList
     * @summary Find all the rules for calculation
     * @request GET:/api/calculations/rules
     * @secure
     */
    calculationsRulesList: (params: RequestParams = {}) =>
      this.request<GetRulesResponse, any>({
        path: `/api/calculations/rules`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calculation
     * @name CalculationsList
     * @summary Finds all the calculations
     * @request GET:/api/calculations
     * @secure
     */
    calculationsList: (params: RequestParams = {}) =>
      this.request<FindCalculationsResponse, any>({
        path: `/api/calculations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calculation
     * @name CalculationsCreate
     * @summary Create a new sample mahjong hand
     * @request POST:/api/calculations
     * @secure
     */
    calculationsCreate: (data: CreateCalculationRequest, params: RequestParams = {}) =>
      this.request<CreateCalculationResponse, any>({
        path: `/api/calculations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calculation
     * @name CalculationsUpdate
     * @summary Update a sample mahjong hand
     * @request PUT:/api/calculations
     * @secure
     */
    calculationsUpdate: (data: UpdateCalculationRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/calculations`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calculation
     * @name CalculationsDetail
     * @summary Gets the mahjong hand calculation by id
     * @request GET:/api/calculations/{id}
     * @secure
     */
    calculationsDetail: (id: number, params: RequestParams = {}) =>
      this.request<Calculation, any>({
        path: `/api/calculations/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calculation
     * @name CalculationsDelete
     * @request DELETE:/api/calculations/{id}
     * @secure
     */
    calculationsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/calculations/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calculation
     * @name CalculationsCalculateCreate
     * @summary Calculate a hand
     * @request POST:/api/calculations/calculate
     * @secure
     */
    calculationsCalculateCreate: (data: CreateCalculationRequest, params: RequestParams = {}) =>
      this.request<Calculation, any>({
        path: `/api/calculations/calculate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Community
     * @name CommunityFindList
     * @summary Find all the players
     * @request GET:/api/community/find
     * @secure
     */
    communityFindList: (params: RequestParams = {}) =>
      this.request<FindPlayerSummariesResponse, any>({
        path: `/api/community/find`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Community
     * @name CommunityGamesList
     * @summary Find best game results
     * @request GET:/api/community/games
     * @secure
     */
    communityGamesList: (params: RequestParams = {}) =>
      this.request<FindGameResultsResponse, any>({
        path: `/api/community/games`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Community
     * @name CommunityPlayerPartialUpdate
     * @summary Update a player information
     * @request PATCH:/api/community/player/{id}
     * @secure
     */
    communityPlayerPartialUpdate: (id: string, data: UpdatePlayerByUserIdRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/community/player/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Community
     * @name CommunityPlayerDetail
     * @summary Get a player
     * @request GET:/api/community/player/{id}
     * @secure
     */
    communityPlayerDetail: (id: number, params: RequestParams = {}) =>
      this.request<GetPlayerSummaryResponse, any>({
        path: `/api/community/player/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Home
     * @name HomeStatisticsList
     * @summary Gets the current statistics
     * @request GET:/api/home/statistics
     * @secure
     */
    homeStatisticsList: (params: RequestParams = {}) =>
      this.request<GetStatisticsResponse, any>({
        path: `/api/home/statistics`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchCreateCreate
     * @summary Creates a match to allow others to join
     * @request POST:/api/match/create
     * @secure
     */
    matchCreateCreate: (data: CreateMatchRequest, params: RequestParams = {}) =>
      this.request<CreateMatchResponse, any>({
        path: `/api/match/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchContinueCreate
     * @summary Continues a match
     * @request POST:/api/match/continue
     * @secure
     */
    matchContinueCreate: (data: ContinueMatchRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/continue`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchDelete
     * @summary Deletes a match by id
     * @request DELETE:/api/match/{id}
     * @secure
     */
    matchDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name GetMatch
     * @summary Get a match by id
     * @request GET:/api/match/{id}
     * @secure
     */
    getMatch: (id: number, query?: { connectionId?: string }, params: RequestParams = {}) =>
      this.request<GetMatchResponse, any>({
        path: `/api/match/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchInActiveDelete
     * @summary Deletes inActive matches that has lasted for more than 2 hrs
     * @request DELETE:/api/match/inActive
     * @secure
     */
    matchInActiveDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/inActive`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchFindDetail
     * @summary Finds all the active matches, this includes matches that are awaiting players as well as matches that has started
     * @request GET:/api/match/find/{playerId}
     * @secure
     */
    matchFindDetail: (playerId: string, query?: { playerId?: number }, params: RequestParams = {}) =>
      this.request<FindMatchesResponse, any>({
        path: `/api/match/find/${playerId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchStartCreate
     * @summary Start a match, automatically uses Bots for missing players
     * @request POST:/api/match/start
     * @secure
     */
    matchStartCreate: (data: StartMatchRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/start`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchPlayCreate
     * @summary Creates and starts a match with bots
     * @request POST:/api/match/play
     * @secure
     */
    matchPlayCreate: (data: PlayMatchRequest, params: RequestParams = {}) =>
      this.request<CreateMatchResponse, any>({
        path: `/api/match/play`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchJoinCreate
     * @summary Join a match
     * @request POST:/api/match/join
     * @secure
     */
    matchJoinCreate: (data: JoinMatchRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/join`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchCaptureCreate
     * @summary Try to capture a discard tile and meld with concealed tiles
     * @request POST:/api/match/capture
     * @secure
     */
    matchCaptureCreate: (data: CaptureRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/capture`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchDiscardCreate
     * @summary Discard a tile from concealed
     * @request POST:/api/match/discard
     * @secure
     */
    matchDiscardCreate: (data: DiscardRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/discard`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchDrawCreate
     * @summary Draw a tile from the wall into your concealed hand
     * @request POST:/api/match/draw
     * @secure
     */
    matchDrawCreate: (data: DrawTileRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/draw`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchSelfPickCreate
     * @summary Win from concealed tiles and the last drawn tile
     * @request POST:/api/match/self-pick
     * @secure
     */
    matchSelfPickCreate: (data: TurnRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/self-pick`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchConcealedKongCreate
     * @summary Creates a concealed kong for the player on their turn based on a Pung and a matching concealed tile
     * @request POST:/api/match/concealed-kong
     * @secure
     */
    matchConcealedKongCreate: (data: TurnRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/concealed-kong`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchHiddenKongCreate
     * @summary Creates a hidden kong for the player on their turn based on 4 tiles with the same face
     * @request POST:/api/match/hidden-kong
     * @secure
     */
    matchHiddenKongCreate: (data: TurnRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/hidden-kong`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchDetail
     * @summary Get all the frames for a specific game in a match
     * @request GET:/api/match/{matchId}/{gameId}
     * @secure
     */
    matchDetail: (matchId: number, gameId: number, params: RequestParams = {}) =>
      this.request<FindGameFramesResponse, any>({
        path: `/api/match/${matchId}/${gameId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchResultsDetail
     * @summary Get all the game results for a specific match
     * @request GET:/api/match/{matchId}/results
     * @secure
     */
    matchResultsDetail: (matchId: number, params: RequestParams = {}) =>
      this.request<FindGameResultsResponse, any>({
        path: `/api/match/${matchId}/results`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Match
     * @name MatchGenerateResultsCreate
     * @summary Regenerate results from frames
     * @request POST:/api/match/generate/results
     * @secure
     */
    matchGenerateResultsCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/match/generate/results`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MatchTemplate
     * @name TemplateDetail
     * @summary Get match of the day
     * @request GET:/api/template/{year}/{month}/{day}
     * @secure
     */
    templateDetail: (year: number, month: number, day: number, params: RequestParams = {}) =>
      this.request<GetMatchTemplateResponse, any>({
        path: `/api/template/${year}/${month}/${day}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MatchTemplate
     * @name TemplatePlayCreate
     * @summary Creates and starts a match based on a template
     * @request POST:/api/template/play
     * @secure
     */
    templatePlayCreate: (data: PlayMatchTemplateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/template/play`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MatchTemplate
     * @name TemplateDrawCreate
     * @summary Draw a tile from the wall into the active player's concealed hand
     * @request POST:/api/template/draw
     * @secure
     */
    templateDrawCreate: (data: DrawTileRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/template/draw`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileStatisticsList
     * @summary Gets the statistics for the player
     * @request GET:/api/profile/statistics
     * @secure
     */
    profileStatisticsList: (params: RequestParams = {}) =>
      this.request<GetPlayerStatisticsResponse, any>({
        path: `/api/profile/statistics`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileList
     * @summary Gets the profile information for the player
     * @request GET:/api/profile
     * @secure
     */
    profileList: (params: RequestParams = {}) =>
      this.request<GetProfileResponse, any>({
        path: `/api/profile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileSignOutCreate
     * @request POST:/api/profile/sign-out
     * @secure
     */
    profileSignOutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/profile/sign-out`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
}
