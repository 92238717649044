import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { CreateMatchRequest } from "../../api/mahjong-api";
import {
  Card,
  Col,
  MainPanel,
  Panel,
} from "../../components/common/Page.styled";
import { ActionButton as Button } from "../../components/common/Buttons.styled";
import { Input } from "../../components/common/Form.styled";
import { FormattedMessage } from "react-intl";
import { ViewLabel as Label, Select } from "./Create.styled";

export type Props = {
  data?: CreateMatchRequest;
  createMatch?: (req: CreateMatchRequest) => void;
};

const Create = ({
  data = {
    turnTime: 10,
    name: "",
    rounds: 1,
    openSpots: 3,
    minPoints: 1,
    replayDealerWins: false,
  },
  createMatch = () => {},
}: Props) => {
  const { register, handleSubmit } = useForm<CreateMatchRequest>({
    defaultValues: { ...data },
  });
  const onSubmit: SubmitHandler<CreateMatchRequest> = (data) => {
    createMatch(data);
  };
  return (
    <Panel>
      <MainPanel>
        <Card>
          <Col>
            <Label>
              <FormattedMessage id="Name" />
            </Label>
            <Input size={10} defaultValue="New Game!" {...register("name")} />
          </Col>
          <Col>
            <Label>
              <FormattedMessage id="Rounds" />
            </Label>
            <Select {...register("rounds")}>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </Select>
          </Col>
          <Col>
            <Label>
              <FormattedMessage id="OpenSpots" />
            </Label>
            <Select {...register("openSpots")}>
              <option>3</option>
              <option>2</option>
              <option>1</option>
            </Select>
          </Col>
        </Card>
        <Card>
          <Col>
            <Label>
              <FormattedMessage id="MinPoints" />
            </Label>
            <Select {...register("minPoints")}>
              <option>5</option>
              <option>3</option>
              <option>1</option>
            </Select>
          </Col>
          <Col>
            <Label>
              <FormattedMessage id="ReplayDealerWins" />
            </Label>
            <Select {...register("replayDealerWins")}>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Select>
          </Col>
          <Col>
            <Button onClick={handleSubmit(onSubmit)}>
              <FormattedMessage id="Go" />
            </Button>
          </Col>
        </Card>
      </MainPanel>
    </Panel>
  );
};

export default Create;
