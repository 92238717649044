import React, { PropsWithChildren } from "react";
import { Html as R3FHtml} from "@react-three/drei";
import ThemeProvider from "../ConnectedThemeProvider";
import { HtmlProps } from "@react-three/drei/web/Html";
import IntlProvider from "../../../i18n/ConnectedIntlProvider";
import { store } from "../../../app/store";
import { Provider } from "react-redux";

const Html = ({ children, ...rest}: PropsWithChildren<HtmlProps>) => (
    <R3FHtml {...rest}>
      <Provider store={store}>
        <ThemeProvider>
          <IntlProvider>
            {children}
          </IntlProvider>
        </ThemeProvider>
      </Provider>
    </R3FHtml>
);
  
  export default Html;