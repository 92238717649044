import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border-bottom: solid;
  border-color: ${({ theme }) => theme.primary};
  margin-right: 0.5rem;
  padding-bottom: 0.2rem;
  margin-top: 0.2rem;
`;
