import React from "react";

import { GetTilesResponse, Meld, Tile, Wind } from "../../../api/mahjong-api";
import { Section } from "./constants";
import MeldedSection from "./sections/Melded";
import ConcealedSection from "./sections/Concealed";
import OutOnSection from "./sections/OutOn";
import FlowersSection from "./sections/Flowers";
import SeatWindSection from "./sections/SeatWind";
import RoundWindSection from "./sections/RoundWind";
import { Container, Row } from "./Hand.styled";
import { nextWind } from "../../../components/game/constants";

export type TileSelected = (s: Section, id: number) => void;

export type SectionProps = {
  onClick: (id: number) => void;
  onTrayClick?: (id: Section) => void;
  isActive?: boolean;
};

type Props = {
  flowers?: Tile[];
  melded?: Meld[];
  concealed?: Tile[];
  outOn?: Tile;
  onMeldClick?: (id: string) => void;
  onTileClick?: TileSelected;
  showEmptyTray?: boolean;
  seatWind?: Wind;
  roundWind?: Wind;
  tiles?: GetTilesResponse;
  onTrayClick?: (id: Section) => void;
  setRoundWind?: (wind: Wind) => void;
  setSeatWind?: (wind: Wind) => void;
  setFlowers?: (flowers: Tile[]) => void;
  activeSection?: string;
};

const Hand = ({
  seatWind = Wind.East,
  roundWind = Wind.East,
  activeSection = Section.Concealed,
  melded = [],
  flowers = [],
  concealed = [],
  outOn = undefined,
  tiles,
  onMeldClick = () => {},
  onTrayClick = () => {},
  onTileClick = () => {},
  setRoundWind = () => {},
  setSeatWind = () => {},
  setFlowers = () => {},
}: Props) => (
  <Container>
    <Row>
      <RoundWindSection
        wind={roundWind}
        onClick={() => setRoundWind(nextWind.get(roundWind) || Wind.East)}
      />
      <SeatWindSection
        wind={seatWind}
        onClick={() => setSeatWind(nextWind.get(seatWind) || Wind.East)}
      />
      <FlowersSection
        tiles={flowers}
        flowers={tiles?.flowers || []}
        onClick={(id) => {
          const found = flowers.find((f) => f.id === id);
          if (found) {
            const remaining = flowers.filter((f) => f.id !== id);
            setFlowers(remaining);
          } else {
            const add = tiles?.flowers.find((f) => f.id === id);
            if (add) {
              const added = flowers.concat([add]);
              setFlowers(added);
            }
          }
        }}
      />
    </Row>
    <Row>
      <MeldedSection
        isActive={activeSection === Section.Melded}
        melds={melded}
        onClick={onMeldClick}
        onTrayClick={onTrayClick}
      />
    </Row>
    <Row>
      <ConcealedSection
        isActive={activeSection === Section.Concealed}
        tiles={concealed}
        onClick={(i) => onTileClick(Section.Concealed, i)}
        onTrayClick={onTrayClick}
      />
      <OutOnSection
        isActive={activeSection === Section.OutOn}
        tile={outOn}
        onClick={(i) => onTileClick(Section.OutOn, i)}
        onTrayClick={onTrayClick}
      />
    </Row>
  </Container>
);

export default Hand;
