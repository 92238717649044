import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { selectLocale } from "../features/auth/selector";
import en from "./en.json";
import cn from "./cn.json";
type Props = {};
const defaultLocale = "en";
const Wrapper: React.FC<Props> = ({ children }) => {
  const locale = useSelector(selectLocale);
  let messages = en;
  if (locale !== defaultLocale) {
    messages = cn;
  }

  return (
    <IntlProvider
      messages={messages}
      defaultLocale={defaultLocale}
      locale={locale}
    >
      {children}
    </IntlProvider>
  );
};

export default Wrapper;
