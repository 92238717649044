import React, { useEffect, useState } from "react";

import {
  CreateCalculationRequest,
  Calculation,
  GetTilesResponse,
  Meld,
  MeldKind,
  Tile,
  Wind,
} from "../../../api/mahjong-api";
import Hand from "./Hand";
import { TileBoard, Selection } from "./input/TileBoard";
import { Button } from "../../../components/common/Buttons.styled";
import { Section } from "./constants";
import { Card, Row } from "../../../components/common/Page.styled";
import { createChow, createKong, createPung } from "../melding";
import { PointsBreakdown } from "../../../components/game/PointsBreakdown";
import { InputPanel } from "./Calculator.styled";

export type Props = {
  data?: Calculation;
  tiles: GetTilesResponse;
  title?: string;
  callToAction?: (req: CreateCalculationRequest) => void;
};

const Calculator = ({ tiles, data, callToAction = () => {} }: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(
    data?.result === null || data?.result === undefined
  );
  const [concealed, setConcealed] = useState<Tile[]>(
    data?.hand.concealed || []
  );
  const [flowers, setFlowers] = useState<Tile[]>(data?.hand.flowers || []);
  const [melded, setMelded] = useState<Meld[]>(data?.hand.melded || []);
  const [outOn, setOutOn] = useState<Tile | undefined>(data?.outOn);
  const [seatWind, setSeatWind] = useState<Wind>(data?.seatWind || Wind.East);
  const [roundWind, setRoundWind] = useState<Wind>(
    data?.roundWind || Wind.East
  );
  const [section, setSection] = useState<Section>(Section.Concealed);

  useEffect(() => {
    setConcealed(data?.hand?.concealed || []);
    setFlowers(data?.hand?.flowers || []);
    setMelded(data?.hand?.melded || []);
    setOutOn(data?.outOn);
    setSeatWind(data?.seatWind || Wind.East);
    setRoundWind(data?.roundWind || Wind.East);
    setIsEdit(data?.result === null || data?.result === undefined);
  }, [data]);

  const onTileBoardClicked = ({ tile, kind }: Selection) => {
    switch (section) {
      case Section.Concealed:
        setConcealed(concealed.concat(tile));
        break;
      case Section.Flowers:
        setFlowers(flowers.concat(tile));
        break;
      case Section.Melded:
        if (kind === MeldKind.Pung) {
          const pong = createPung(tile);
          setMelded(melded.concat(pong));
        } else if (kind === MeldKind.Kong) {
          const kong = createKong(tile);
          setMelded(melded.concat(kong));
        } else if (kind === MeldKind.Chow) {
          const chow = createChow(tile, tiles);
          setMelded(melded.concat(chow));
        }
        break;
      case Section.OutOn:
        setOutOn(tile);
        break;
    }
  };
  const onTrayClick = (s: Section) => {
    setSection(s);
  };
  const onSaveClick = () => {
    if (outOn) {
      callToAction({
        outOn,
        flowers,
        concealed,
        melded,
        seatWind,
        roundWind,
      });
    }
  };
  const onRemoveTileClick = (s: Section, index: number) => {
    switch (s) {
      case Section.Concealed:
        const remaining = concealed.filter((t, i) => i !== index);
        setConcealed(remaining);
        break;
      case Section.OutOn:
        setOutOn(undefined);
        break;
    }
  };

  const onRemoveMeldClick = (meld: string) => {
    switch (section) {
      case Section.Melded:
        const remaining = melded.filter((m) => m.code !== meld);
        setMelded(remaining);
        break;
    }
  };

  return (
    <Row>
      <Hand
        {...{ concealed, flowers, melded, outOn }}
        seatWind={seatWind}
        roundWind={roundWind}
        activeSection={section}
        setRoundWind={setRoundWind}
        setSeatWind={setSeatWind}
        setFlowers={setFlowers}
        onTileClick={onRemoveTileClick}
        onMeldClick={onRemoveMeldClick}
        onTrayClick={onTrayClick}
        tiles={tiles}
      />
      <InputPanel>
        {isEdit ? (
          <TileBoard
            tiles={tiles}
            onClick={onTileBoardClicked}
            onCalculate={onSaveClick}
            section={section}
          />
        ) : (
          <Card>
            <PointsBreakdown result={data?.result} />
            <Button onClick={() => setIsEdit(true)}>edit</Button>
          </Card>
        )}
      </InputPanel>
    </Row>
  );
};

export default Calculator;
