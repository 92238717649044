import React from "react";
import { CaptureRequest } from "../../../api/mahjong-api";
import { Wrapper, Button } from "./Options.styled";
import { FormattedMessage } from "react-intl";
import { Meld } from "../../../components/game/Meld";

export type Props = {
  options: CaptureRequest[];
  capture?: (request: CaptureRequest) => void;
};

const CaptureOptions = ({ options, capture = () => {} }: Props) => {
  return (
    <Wrapper>
      {options.map((opt, i) => (
        <Button key={`d-${opt.kind}${i}`} onClick={() => capture(opt)}>
          <Meld codes={opt.codes} ratio={0.75} />
          <FormattedMessage id={opt.kind || "Action" }/>
        </Button>
      ))}
    </Wrapper>
  );
};
export default CaptureOptions;
