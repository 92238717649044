import styled from "styled-components";
import { Button as BaseButton } from "../../../components/common/Buttons.styled";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const Card = styled.div`
  border-radius: 4px;
  font-weight: bolder;
  color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  padding: 0rem;
`;

export const Button = styled(BaseButton)`
  text-transform: uppercase;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  margin: 0.25rem;
`;
