import { RoundedBox } from "@react-three/drei";
import React from "react";
import { Color } from "three";

const TableTop = () => {
  return (
    <group receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, -8, -2]}>
      <RoundedBox radius={0.25} smoothness={10} args={[34, 14, 1]}>
        <meshStandardMaterial attachArray="material" color={"#36814b"} />
        <meshStandardMaterial
          attachArray="material"
          color={new Color(0x3f301d)}
        />
      </RoundedBox>
    </group>
  );
};

export default TableTop;
