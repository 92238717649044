import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import {
  GetPlayerStatisticsResponse,
  GetProfileResponse,
} from "../../api/mahjong-api";
import { TitleText } from "../home/Home.styled";
import { ActionButton as Button } from "../../components/common/Buttons.styled";
import { ColorPicker, Data, Email, Section } from "./Profile.styled";
import { Column, Label, Row } from "../../components/common/Info.styled";
import Language from "../../components/nav/Language";
import { Helmet } from "react-helmet-async";
import { CirclePicker } from "react-color";
import Avatar from "../game/Avatar";
import MotdButton from "../../components/common/buttons/MotdButton";
import TurnTime from "./TurnTime";
import DiscardStyleSelector, { DiscardStyle } from "./DiscardStyle";
import {
  Page,
  Backdrop,
  FixedPanel as Panel,
} from "../../components/common/Page.styled";

const colors = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#7fd8d4",
];

export type Props = {
  locale?: string;
  turnTime?: number;
  profile?: GetProfileResponse;
  statistics?: GetPlayerStatisticsResponse;
  discardStyle?: DiscardStyle;
  playVsBots?: () => void;
  logout?: () => void;
  selectLocale?: (l: string) => void;
  updateTurnTime?: (t: number) => void;
  updateColor?: (c: string) => void;
  updateDiscardStyle?: (d: DiscardStyle) => void;
};

const Profile = ({
  locale = "en",
  turnTime = 10,
  profile,
  statistics,
  discardStyle = DiscardStyle.Gesture,
  logout,
  playVsBots = () => {},
  selectLocale = () => {},
  updateTurnTime = () => {},
  updateColor = () => {},
  updateDiscardStyle = () => {},
}: Props) => {
  const gameWinPct =
    (statistics?.gamesPlayed
      ? (statistics?.gamesWon ?? 0) / statistics?.gamesPlayed
      : 0) * 100;
  const gameWinPctRnd = Math.round((gameWinPct + Number.EPSILON) * 100) / 100;

  const matchWinPct =
    (statistics?.matchesPlayed
      ? (statistics?.matchesWon ?? 0) / statistics?.matchesPlayed
      : 0) * 100;

  const matchWinPctRnd = Math.round((matchWinPct + Number.EPSILON) * 100) / 100;
  return (
    <Page>
      <Helmet>
        <title>Mahjong Spot - Profile</title>
        <meta name="description" content="Your Mahjong Profile" />
      </Helmet>
      <Backdrop>
        <Panel>
          <TitleText>
            <FormattedMessage id="Profile" />
          </TitleText>
          <Email>{profile?.email}</Email>
          <Data>
            {profile?.firstName} {profile?.lastName} ({profile?.locale})
          </Data>
          <Avatar image={profile?.imagePath} />
          <Row>
            <Column>
              <Label>
                <FormattedMessage id="Games" />
              </Label>
              <Data>{statistics?.gamesPlayed}</Data>
            </Column>
            <Column>
              <Label>
                <FormattedMessage id="Wins" />
              </Label>
              <Data>{statistics?.gamesWon}</Data>
            </Column>
            <Column>
              <Label>
                <FormattedMessage id="WinPercentage" />
              </Label>
              <Data>{gameWinPctRnd}</Data>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label>
                <FormattedMessage id="Matches" />
              </Label>
              <Data>{statistics?.matchesPlayed}</Data>
            </Column>
            <Column>
              <Label>
                <FormattedMessage id="Wins" />
              </Label>
              <Data>{statistics?.matchesWon}</Data>
            </Column>
            <Column>
              <Label>
                <FormattedMessage id="WinPercentage" />
              </Label>
              <Data>{matchWinPctRnd}</Data>
            </Column>
          </Row>
        </Panel>
        <Panel>
          <div>
            <Button onClick={() => playVsBots()}>
              <FormattedMessage id="PlayVsBots" />
            </Button>
          </div>
          <div>
            <NavLink to="/motd">
              <MotdButton />
            </NavLink>
          </div>

          <div>
            <NavLink to="/match">
              <Button>
                <FormattedMessage id="EnterLobby" />
              </Button>
            </NavLink>
          </div>
        </Panel>
      </Backdrop>
      <Backdrop>
        <Panel>
          <FormattedMessage id="Language" />
          <Section>
            <Language locale={locale} selectLocale={selectLocale} />
          </Section>
        </Panel>
        <Panel>
          <ColorPicker>
            <FormattedMessage id="Color" />
            <Section>
              <CirclePicker
                colors={colors}
                onChangeComplete={(color) => updateColor(color.hex)}
              />
            </Section>
          </ColorPicker>
        </Panel>
        <Panel>
          <FormattedMessage id="TurnTime" />
          <Section>
            <TurnTime turnTime={turnTime} selectTurnTime={updateTurnTime} />
          </Section>
        </Panel>
        <Panel>
          <FormattedMessage id="DiscardStyle" />
          <Section>
            <DiscardStyleSelector
              style={discardStyle}
              updateDiscardStyle={updateDiscardStyle}
            />
          </Section>
        </Panel>
      </Backdrop>
      <Backdrop>
        <Button onClick={logout}>
          <FormattedMessage id="Logout" />
        </Button>
      </Backdrop>
    </Page>
  );
};

export default Profile;
