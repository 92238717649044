import React from "react";
import {
  Discard as IDiscard,
  Tile,
  Seat,
  Player,
} from "../../../api/mahjong-api";
import Discards from "./hand/Discards";
import OpponentStatus from "./OpponentStatus";
import Ruler from "./hand/Ruler";
import Flowers from "./hand/Flowers";
import Wall from "./hand/Wall";
import { getLastDiscard, getParts } from "../frame";
import Melded from "./hand/Melded";
import Concealed from "./hand/Concealed";
import Discard from "./hand/Discard";
import Drawn from "./hand/Drawn";
import { Props as ActionProps } from "../Action";

export type SeatProps = JSX.IntrinsicElements["mesh"] & {
  turnTime?: number;
  action?: ActionProps;
  seat: Seat;
  outOn?: Tile;
  isDrawRequest?: boolean;
  isDiscardRequest?: boolean;
  ratio?: number;
  allDiscards?: IDiscard[];
  isOpen?: boolean;
  player?: Player;
  wallCount?: number;
  points?: number;
  captureRotation?: number;
};

const OpponentSeat = ({
  turnTime = 10,
  action,
  seat,
  ratio = 1.3,
  rotation,
  position,
  allDiscards = [],
  isDrawRequest = false,
  isDiscardRequest = false,
  points = 0,
  isOpen = false,
  player,
  wallCount,
  captureRotation,
}: SeatProps) => {
  const { wind, flowers, concealed, melded, drawn } = getParts(seat);

  const discards = allDiscards.filter(
    (d) => d.discardPlayerId === seat?.clientId
  );
  const lastDiscard = getLastDiscard(discards);

  const concealedCount = concealed.length;
  const meldedCount = melded.reduce((a, b) => a + b.tiles.length, 0);
  const totalCount = concealedCount + meldedCount;
  const half = Math.ceil(totalCount / 2);
  const shownDiscards = discards.filter(
    (d) =>
      !(isDiscardRequest && d.tile?.id === lastDiscard?.tile?.id) &&
      !d.claimedBy
  );
  const start = -(half + 2);
  return (
    <group rotation={rotation} position={position}>
      <Wall count={wallCount} />
      <Discards discards={shownDiscards} ratio={ratio} />
      <Ruler />
      <OpponentStatus
        turnTime={turnTime}
        action={action}
        name={player?.name || ""}
        image={player?.imageUrl || ""}
        wind={wind}
        points={points}
        captureRotation={captureRotation}
      />

      <Flowers flowers={flowers} ratio={ratio} />
      <Melded melded={melded} x={start} ratio={ratio} />
      <Concealed concealed={concealed} x={start + meldedCount} ratio={ratio} />
      {isDrawRequest && drawn && (
        <Drawn
          x={start + totalCount + 5.5}
          code={drawn.code}
          key={`drawn${drawn.id}`}
          scale={ratio}
        />
      )}
      <Discard
        isDiscardRequest={isDiscardRequest}
        lastDiscard={lastDiscard?.tile}
        ratio={ratio}
        key={`discard${lastDiscard?.tile?.id}`}
        x={start + totalCount + 5.5}
      />
    </group>
  );
};
export default OpponentSeat;
