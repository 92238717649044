import React from "react";
import { Calculation } from "../../../api/mahjong-api";
import ListHand from "./Hand";
import { Button } from "../../../components/common/Buttons.styled";
import { Delete } from "@styled-icons/material-sharp";
import { Tile } from "../../../components/game/Tile";
import { windCodes } from "../../../components/game/constants";
import { CellProps, useTable } from "react-table";
import { FormattedMessage } from "react-intl";
import { Container, Table, Td, THead, Tr, TBody, Th } from "./List.styled";

export type Props = {
  examples?: Calculation[];
  deleteSample?: (id: number) => void;
  editSample?: (id: number) => void;
};

export const List = ({
  examples: requests = [],
  deleteSample = () => {},
  editSample = () => {},
}: Props) => {
  const data = React.useMemo(() => requests, [requests]);

  const columns = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="Id"/>,
        id: "id",
        Cell: (r: CellProps<Calculation>) => r.row.original.id,
      },
      {
        Header: <FormattedMessage id="Seat"/>,
        id: "seat",
        Cell: (r: CellProps<Calculation>) => (
          <Tile
            ratio={0.3}
            code={windCodes.get(r.row.original.seatWind) || "🀀"}
          />
        ),
      },
      {
        Header: <FormattedMessage id="Hand"/>,
        id: "hand",
        Cell: (r: CellProps<Calculation>) => (
          <ListHand
            {...r.row.original.hand}
            outOn={r.row.original.outOn}
            ratio={0.5}
          />
        ),
      },
      {
        Header: <FormattedMessage id="Score"/>,
        id: "score",
        Cell: (r: CellProps<Calculation>) => (
          <>{r.row.original.result?.totalPoints}</>
        ),
      },
      {
        Header: "",
        id: "action",
        Cell: (r: CellProps<Calculation>) => (
          <>
            <Button onClick={() => editSample(r.row.original.id)}>
              <FormattedMessage id="View" />
            </Button>
            <Delete size={25} onClick={() => deleteSample(r.row.original.id)} />
          </>
        ),
      },
    ],
    [deleteSample, editSample]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <Container>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              ))}
            </Tr>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </TBody>
      </Table>
    </Container>
  );
};
export default List;
