import styled from "styled-components";

export const Data = styled.div`
  font-size: 0.7rem;
  padding: 0.3rem;
`;

export const Email = styled.div`
  padding-top: 1rem;
  font-size: 0.5rem;
  font-family: ${({ theme }) => theme.text};
`;

export const ColorPicker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const Section = styled.div`
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
