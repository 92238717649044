import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, Row } from "./GameOver.styled";

const Draw = () => {
  return (
    <Row>
      <Card>
        <FormattedMessage id="DrawGame" />
      </Card>
    </Row>
  );
};

export default Draw;
