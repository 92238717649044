import styled from "styled-components";

export const Container = styled.div<{ active?: boolean; single?: boolean }>`
  border: solid;
  border-radius: 4px;
  border-color: ${({ active, theme }) => (active ? "white" : theme.primary)};
  flex-direction: column;
  display: flex;
  min-height: 4rem;
  margin: 0.2rem;
  flex-grow: ${({ single }) => (single ? 0 : 1)};
`;

export const Name = styled.div<{ active?: boolean; single?: boolean }>`
  background-color: ${({ active, theme }) =>
    active ? "white" : theme.primary};
  color: black;
  font-size: 0.8rem;
  padding-left: ${({ single }) => (single ? "0.2rem" : "1rem")};
  padding-right: ${({ single }) => (single ? "0.2rem" : "1rem")};
`;

export const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.3rem;
`;
