import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import { usePlayMatchMutation } from "../match/service";
import { logout, updateTurnTime } from "../auth/reducer";
import { updateLocale, updateColor, updateDiscardStyle } from "../auth/reducer";
import Profile from "./Profile";
import {
  resetProfile,
  useProfileQuery,
  useSignOutMutation,
  useStatisticsQuery,
} from "../profile/service";
import { resetClientId } from "../auth/reducer";
import { useAppDispatch } from "../../app/hooks";
import {
  selectDiscardStyle,
  selectLocale,
  selectTurnTime,
} from "../auth/selector";
import { useSelector } from "react-redux";

const Container = () => {
  const navigate = useNavigate();
  const {
    data: profile,
    isLoading: profileLoading,
    isError,
  } = useProfileQuery();
  const { data: statistics, isLoading: statsLoading } = useStatisticsQuery();
  const [playMatch] = usePlayMatchMutation();
  const [signOut] = useSignOutMutation();
  const dispatch = useAppDispatch();
  const turnTime = useSelector(selectTurnTime);
  const discardStyle = useSelector(selectDiscardStyle);

  const handlePlay = async () => {
    const response = await playMatch({
      playerId: profile?.playerId,
      turnTime: turnTime,
    }).unwrap();
    navigate(`/game/${response.id}`);
  };

  useEffect(() => {
    if (isError) {
      signOut();
      dispatch(logout());
      dispatch(resetProfile());
      navigate("/");
    }
  }, [isError, signOut, navigate, dispatch]);

  const onLogout = () => {
    signOut();
    dispatch(resetClientId());
    dispatch(logout());
    dispatch(resetProfile());
    navigate("/");
  };

  const locale = useSelector(selectLocale);
  if (profileLoading || statsLoading) {
    return <Loader />;
  } else {
    return (
      <Profile
        locale={locale}
        turnTime={turnTime}
        discardStyle={discardStyle}
        selectLocale={(l) => dispatch(updateLocale(l))}
        updateColor={(c) => dispatch(updateColor(c))}
        updateTurnTime={(c) => dispatch(updateTurnTime(c))}
        updateDiscardStyle={(d) => dispatch(updateDiscardStyle(d))}
        logout={onLogout}
        statistics={statistics}
        profile={profile}
        playVsBots={handlePlay}
      />
    );
  }
};

export default Container;
