import React from "react";
import { Helmet } from "react-helmet-async";
import Loader from "../../components/common/Loader";
import { Module } from "../../components/common/Page.styled";
import Calculator from "../scoring/create/Calculator";
import { useCalculateMutation, useGetAllTilesQuery } from "../scoring/service";

const Container = () => {
  const { isLoading: isTileLoading, data: tiles } = useGetAllTilesQuery();
  const [calculate, { data }] = useCalculateMutation();
  if (isTileLoading || !tiles) {
    return <Loader />;
  }
  return (
    <Module>
      <Helmet>
        <title>Mahjong Spot - Calculator</title>
        <meta name="description" content="Use our calculator to score your hands" />
      </Helmet>

      <Calculator
        tiles={tiles}
        title="Calculate"
        data={data}
        callToAction={(ccr) => calculate(ccr)}
      />
    </Module>
  );
};

export default Container;
