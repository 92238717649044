import React from "react";
import { GameFrame, GameResult } from "../../../api/mahjong-api";
import OpponentSeat from "./OpponentSeat";
import PlayerSeat from "./PlayerSeat";
import { PlayerMap } from "../Game";
import { Props as ActionProps } from "../Action";
import {
  isActivePlayer,
  isDiscardReq,
  isDrawReq,
  getActionValue,
  getCaptureOptions,
  getLastDiscard,
  getTurnOptions,
  getSeats,
  isCaptureReq,
  isStartReq,
} from "../frame";
import Options, { OptionsActionProps } from "../options/Options";

export type Props = OptionsActionProps & {
  clientId: string;
  turnTime?: number;
  playerMap?: PlayerMap;
  matchResults?: GameResult[];
  frame?: GameFrame;
  discard?: (tileId: number) => void;
};

const Seats = ({
  turnTime = 10,
  frame,
  clientId,
  matchResults = [],
  playerMap = {},
  draw = () => {},
  capture = () => {},
  selfKong = () => {},
  selfPick = () => {},
  discard = () => {},
}: Props) => {
  if (!frame) {
    return null;
  }

  const [seat, right, opposite, left] = getSeats(frame, clientId);

  if (!seat || !right || !left || !opposite) {
    return null;
  }

  const allDiscards = frame?.discards || [];

  const allRemaining = frame?.wallCount || 0;
  const wallLength = 24;
  const walls = Math.floor(allRemaining / wallLength);
  const remainder = allRemaining % wallLength;

  const turnOptions = getTurnOptions(clientId, frame);
  const captureOptions = getCaptureOptions(clientId, frame);
  const action: ActionProps = {
    actionType: frame?.requestType,
    actionValue: getActionValue(frame),
    lastDiscard: getLastDiscard(frame?.discards || []),
    clientId: frame?.requestClientId,
  };
  const isPlayerActive = isActivePlayer(frame, clientId);

  const showTimer =
    !isStartReq(frame) &&
    (isDrawReq(frame, clientId) ||
      isCaptureReq(frame, clientId) ||
      turnOptions.length > 0 ||
      captureOptions.length > 1);
  return (
    <group>
      {!frame?.isComplete && (
        <Options
          key={`option${frame.sequence}`}
          turnOptions={turnOptions}
          captureOptions={captureOptions}
          isStart={isStartReq(frame)}
          draw={draw}
          capture={capture}
          selfKong={selfKong}
          selfPick={selfPick}
        />
      )}

      {/* Opposite, pushed back and turned 180 on Y */}
      <OpponentSeat
        turnTime={turnTime}
        key={`${frame?.gameId}-opposite`}
        action={action.clientId === opposite?.clientId ? action : undefined}
        seat={opposite}
        player={playerMap[opposite?.clientId || ""]}
        points={matchResults
          .filter((mr) => mr.clientId === opposite?.clientId)
          .reduce((accumulator, obj) => {
            return accumulator + (obj.points || 0);
          }, 0)}
        position={[0, 1, -15]}
        rotation={[0, Math.PI, 0]}
        isDrawRequest={isDrawReq(frame, opposite?.clientId)}
        allDiscards={allDiscards}
        isDiscardRequest={isDiscardReq(frame, opposite?.clientId)}
        wallCount={walls >= 2 ? wallLength : walls === 1 ? remainder : 0}
      />

      {/* Left, pushed back and turned -90 on Y */}
      <OpponentSeat
        turnTime={turnTime}
        key={`${frame?.gameId}-left`}
        action={action.clientId === left?.clientId ? action : undefined}
        seat={left}
        player={playerMap[left?.clientId || ""]}
        points={matchResults
          .filter((mr) => mr.clientId === left?.clientId)
          .reduce((accumulator, obj) => {
            return accumulator + (obj.points || 0);
          }, 0)}
        position={[-16, 1, 0]}
        rotation={[0, -Math.PI / 2, 0]}
        isDrawRequest={isDrawReq(frame, left?.clientId)}
        allDiscards={allDiscards}
        isDiscardRequest={isDiscardReq(frame, left?.clientId)}
        wallCount={walls >= 1 ? wallLength : remainder}
        captureRotation={Math.PI / 2}
      />

      {/* Right, pushed back and turned -90 on Y */}
      <OpponentSeat
        turnTime={turnTime}
        key={`${frame?.gameId}-right`}
        action={action.clientId === right?.clientId ? action : undefined}
        seat={right}
        player={playerMap[right?.clientId || ""]}
        points={matchResults
          .filter((mr) => mr.clientId === right?.clientId)
          .reduce((accumulator, obj) => {
            return accumulator + (obj.points || 0);
          }, 0)}
        isDrawRequest={isDrawReq(frame, right?.clientId)}
        position={[16, 1, 0]}
        rotation={[0, Math.PI / 2, 0]}
        allDiscards={allDiscards}
        isDiscardRequest={isDiscardReq(frame, right?.clientId)}
        wallCount={walls === 3 ? wallLength : walls === 2 ? remainder : 0}
        captureRotation={-Math.PI / 2}
      />

      {/* Client First person view hand */}
      <PlayerSeat
        turnTime={turnTime}
        sequence={frame.sequence}
        key={`${frame?.gameId}-player`}
        action={action}
        seat={seat}
        player={playerMap[clientId]}
        points={matchResults
          .filter((mr) => mr.clientId === clientId)
          .reduce((accumulator, obj) => {
            return accumulator + (obj.points || 0);
          }, 0)}
        ratio={1.3}
        isDrawRequest={isDrawReq(frame, clientId)}
        position={[0, 1.1, 16.5]}
        rotation={[0, 0, 0]}
        allDiscards={allDiscards}
        onConcealedClick={(tileId) => isPlayerActive && discard(tileId)}
        isDiscardRequest={isDiscardReq(frame, clientId)}
        isActive={isPlayerActive}
        wallCount={walls === 3 ? remainder : 0}
        showTimer={showTimer}
      />
    </group>
  );
};
export default Seats;
