import React from "react";
import { Helmet } from "react-helmet-async";
import { FormattedDate, FormattedMessage } from "react-intl";
import { GameDayResult, GameResult, Wind } from "../../api/mahjong-api";
import { ActionButton as Button } from "../../components/common/Buttons.styled";
import { Label } from "../../components/common/Info.styled";
import {
  Col,
  Description,
  Header,
  Module,
  Row,
} from "../../components/common/Page.styled";
import GameResults from "../community/GameResults";
import { Section, SectionName } from "../match/Lobby.styled";
import GameDayResults from "./GameDayResults";

export type Props = {
  date: Date;
  results?: GameResult[];
  prevResults?: GameDayResult[];
  seat?: Wind;
  round?: Wind;
  play?: () => void;
};

const MatchOfTheDay = ({
  seat = Wind.East,
  round = Wind.East,
  date,
  results = [],
  prevResults = [],
  play = () => {},
}: Props) => (
  <Module>
    <Helmet>
      <title>Mahjong Spot - Match Of The Day</title>
      <meta name="description" content="Play our Match Of The Day" />
    </Helmet>
    <Header>
      <FormattedMessage id="MatchOfTheDay" />
    </Header>
    <Row>
      <Col>
        <Label>
          <FormattedMessage id="Date" />
        </Label>
        <div>
          <FormattedDate
            value={date}
            year="numeric"
            month="long"
            day="2-digit"
          />
        </div>
      </Col>
      <Col>
        <Label>
          <FormattedMessage id="Round" />
        </Label>
        <FormattedMessage id={round} />
      </Col>
      <Col>
        <Label>
          <FormattedMessage id="Seat" />
        </Label>
        <FormattedMessage id={seat} />
      </Col>
    </Row>
    <Description>
      <FormattedMessage id="MatchOfTheDayDescription" />
    </Description>
    <div>
      <Button onClick={() => play()}>
        <FormattedMessage id="Play" />
      </Button>
    </div>
    <Section>
      <SectionName>
        <FormattedMessage id="DailyLeaderboard" />
      </SectionName>
      <GameResults gameResults={results} />
    </Section>
    <Section>
      <SectionName>
        <FormattedMessage id="PreviousWinners" />
      </SectionName>
      <GameDayResults gameResults={prevResults} />
    </Section>
  </Module>
);

export default MatchOfTheDay;
