import React from "react";
import { theme } from "../../theme";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { selectColor } from "../../features/auth/selector";

const ConnectedThemeProvider: React.FC<{}> = ({ children }) => {
    const color = useSelector(selectColor);
    let customTheme = theme;
    if (color) {
      customTheme = {...theme, primary: color};
    }

    return (
      <ThemeProvider theme={customTheme}>
        {children}
      </ThemeProvider>
    );
  };
  
  export default ConnectedThemeProvider;