import React from "react";
import "react-sliding-pane/dist/react-sliding-pane.css";
import List from "./List";
import { Module } from "../../components/common/Page.styled";
import { Props as ListProps } from "./List";
import { NavigateFunction } from "react-router-dom";
import { FindGameResultsResponse, PlayerSummary } from "../../api/mahjong-api";
import { Main, Section, SectionName } from "../match/Lobby.styled";
import { FormattedMessage } from "react-intl";
import { Pane } from "../../components/common/Sliding.styled";
import View from "./View";
import GameResults from "./GameResults";
import { Helmet } from "react-helmet-async";

type Props = ListProps & {
  player?: PlayerSummary;
  pathname?: string;
  gameResults?: FindGameResultsResponse;
  navigate?: NavigateFunction;
};
const Community = ({
  players = [],
  player,
  gameResults,
  pathname = "",
  navigate = () => {},
  viewPlayer = (id: number) => {},
}: Props) => {
  return (
    <Module>
      <Helmet>
        <title>Mahjong Spot - Community</title>
        <meta name="description" content="Check out our leaderboards" />
      </Helmet>

      <Pane
        isOpen={pathname.includes("community/view")}
        onRequestClose={() => {
          navigate("/community");
        }}
      >
        <View player={player} />
      </Pane>
      <Main>
        <Section>
          <SectionName>
            <FormattedMessage id="WinnerLeaderboard" />
          </SectionName>
          <List players={players} viewPlayer={viewPlayer} />
        </Section>
        <Section>
          <SectionName>
            <FormattedMessage id="SingleHandLeaderboard" />
          </SectionName>
          <GameResults
            gameResults={gameResults?.results || []}
            viewPlayer={viewPlayer}
          />
        </Section>
      </Main>
    </Module>
  );
};

export default Community;
