import styled from "styled-components";
import { Menu } from "@styled-icons/heroicons-outline";
import background from "./background.png";

export const PageContainer = styled.div`
  position: relative;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
`;

export const AppMenu = styled(Menu)`
  &:hover {
    color: black;
  }
`;

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 100vw;
  height: 100vh;
`;
