import React, { useRef } from "react";
import * as THREE from "three";

import { RoundedBox } from "@react-three/drei";
import { useTheme } from "styled-components";
export type Props = {
  isOpen?: boolean;
  scale?: number;
};

function Ruler({ scale }: Props) {
  const theme = useTheme();
  const ref = useRef<THREE.Mesh>(null!);
  const front = new THREE.Vector3(0, -0.8, -1.5);
  return (
    <RoundedBox
      radius={0.1}
      smoothness={10}
      position={front}
      ref={ref}
      args={[16, 0.3, 0.8]}
      scale={scale}
    >
      <meshPhysicalMaterial
        flatShading
        attachArray="material"
        color={"white"}
        clearcoat={1}
        metalness={0.5}
        reflectivity={1}
        clearcoatRoughness={0}
      />
      <meshPhysicalMaterial
        flatShading
        attachArray="material"
        color={theme.primary}
        clearcoat={1}
        metalness={0.5}
        reflectivity={1}
        clearcoatRoughness={0}
      />
    </RoundedBox>
  );
}

export default Ruler;
