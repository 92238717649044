import React from "react";
import { FormattedMessage } from "react-intl";
import { PlayerSummary } from "../../api/mahjong-api";
import {
  Panel,
  Col,
  MainPanel,
  Card,
} from "../../components/common/Page.styled";
import Avatar from "../game/Avatar";
import { ViewLabel as Label } from "../match/Create.styled";

type Props = {
  player?: PlayerSummary;
};
const View = ({ player }: Props) => (
  <Panel>
    <MainPanel>
      <Card>
        <Col>
          <Label>
            <FormattedMessage id="Name" />
          </Label>
          {player?.name}
        </Col>
        <Col>
          <Label>
            <FormattedMessage id="Icon" />
          </Label>
          <Avatar image={player?.imageUrl || ""} />
        </Col>
        <Col>
          <Label>
            <FormattedMessage id="GamesPlayed" />
          </Label>
          {player?.gamesPlayed}
        </Col>
        <Col>
          <Label>
            <FormattedMessage id="GamesWon" />
          </Label>
          {player?.gamesWon}
        </Col>
      </Card>
    </MainPanel>
  </Panel>
);

export default View;
