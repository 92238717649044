import React from "react";
import { FormattedMessage } from "react-intl";
import { NavigateFunction } from "react-router-dom";
import Avatar from "../../features/game/Avatar";
import {
  NavBar,
  NavItem,
  TopBar,
  TitleText,
  TitlePlayer,
  SocialButton,
} from "./TopNav.styled";

type Props = {
  clientId?: string;
  email?: string;
  pathname?: string;
  imageUrl?: string;
  navigate?: NavigateFunction;
};

const googleAuthUrl = window._env_.GOOGLE_AUTH_URL;

export const TopNav = ({
  clientId,
  pathname = "",
  email = "",
  imageUrl = "",
  navigate = () => {},
}: Props) => (
  <TopBar>
    <TitleText>
      <NavItem onClick={() => navigate("/")}>
        <FormattedMessage id="MahjongSpot" />
      </NavItem>
    </TitleText>
    <NavBar>
      <NavItem
        active={pathname.includes("match")}
        onClick={() => navigate("/match")}
      >
        <FormattedMessage id="Lobby" />
      </NavItem>
      <NavItem
        active={pathname.includes("scoring")}
        onClick={() => navigate("/scoring")}
      >
        <FormattedMessage id="Rules" />
      </NavItem>
      <NavItem
        active={pathname.includes("calculator")}
        onClick={() => navigate("/calculator")}
      >
        <FormattedMessage id="Calculator" />
      </NavItem>
      <NavItem
        active={pathname.includes("community")}
        onClick={() => navigate("/community")}
      >
        <FormattedMessage id="Community" />
      </NavItem>
    </NavBar>
    <TitlePlayer>
      {!email && (
        <form method="POST" action={`${googleAuthUrl}&clientId=${clientId}`}>
          <SocialButton
            type="submit"
            name="provider"
            value="Google"
            title={`Login using Google`}
          />
        </form>
      )}
      {imageUrl && <div onClick={() => navigate("/profile")} ><Avatar ratio={0.4} image={imageUrl}/> </div> }
    </TitlePlayer>
  </TopBar>
);

export default TopNav;
