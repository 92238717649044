import React from "react";
import Html from "../../components/common/r3f/Html";
import { useThree } from "@react-three/fiber";
import { FormattedMessage } from "react-intl";
import { Vector3 } from "three";
import { TitleText } from "./Home.styled";
import { animated } from "@react-spring/three";
import { useSpring } from "@react-spring/core";

const Title = () => {
  const { camera } = useThree();
  camera.lookAt(new Vector3(0, -7, 0));
  const { posY } = useSpring({
    from: { posY: 12 },
    to: { posY: 4.5 },
  });
  return (
    <animated.group position-y={posY}>
      <Html center>
        <TitleText>
          <FormattedMessage id="MahjongSpot" />
        </TitleText>
      </Html>
    </animated.group>
  );
};

export default Title;
