import styled from "styled-components";
import GoogleImg from "../login/icon_mobile_google_login.png";

export const Button = styled.button<{ active?: boolean }>`
  padding: 4px 16px;
  color: #00291f;
  font-family: ${({ theme }) => theme.heading};
  background-color: ${({ theme, active }) =>
    active ? "white" : theme.primary};
  letter-spacing: 1.25px;
  border-radius: 4px;
  border: none;
  &:hover {
    background-color: #dee4ed;
    box-shadow: 0 3px #999;
  }
  &:active {
    background-color: #dee4ed;
    box-shadow: 0 3px #666;
    transform: translateY(2px);
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 16px;
  color: #00291f;
  letter-spacing: 1.25px;
  border-radius: 4px;
  border: none;
`;

export const ActionButton = styled.button`
  margin: 0.5rem;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.heading};
  font-size: 1.2rem;
  padding: 8px 8px;
  width: 220px;
  color: #00291f;
  background-color: ${({ theme }) => theme.primary};
  letter-spacing: 1.25px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 3px #999;
  &:hover {
    background-color: #dee4ed;
    transform: translateY(-2px);
  }
  &:active {
    background-color: #dee4ed;
    box-shadow: 0 1px #666;
    transform: translateY(2px);
  }
`;

export const SocialButton = styled.button`
  margin-top: 0.5rem;
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  border: none;
  background-image: url(${GoogleImg});
  background-size: cover;
`;
