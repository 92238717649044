import React from "react";
import { Row, Item } from "../common/Page.styled";

type Props = {
  locale?: string;
  selectLocale?: (l: string) => void;
};

export const Language = ({ locale, selectLocale = () => {} }: Props) => (
  <Row>
    <Item selected={locale === "en"} onClick={() => selectLocale("en")}>
      EN
    </Item>
    <Item selected={locale === "cn"} onClick={() => selectLocale("cn")}>
      中文
    </Item>
  </Row>
);

export default Language;
