import React, { useEffect } from "react";
import Scoring from "./features/scoring";
import Match from "./features/match";
import { Route, Routes } from "react-router";
import NoMatch from "./components/common/NoMatch";
import Home from "./features/home";
import Game from "./features/game";
import About from "./features/about/About";
import Calculator from "./features/calculator";
import { ComponentWrapper, PageContainer } from "./App.styled";
import { Frame } from "./features/home/Home.styled";
import Profile from "./features/profile";
import Community from "./features/community";
import ReactGA from "react-ga";
import MatchOfTheDay from "./features/motd";
import TopNav from "./components/nav";
import { Helmet } from "react-helmet-async";
const TRACKING_ID = "G-YR6KTN1YXX";

ReactGA.initialize(TRACKING_ID);

declare global {
  interface Window {
    _env_: any;
  }
}

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <PageContainer>
      <Frame>
        <Game />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/game/*" element={
              <Helmet>
                <title>Mahjong Spot - Play Mahjong</title>
                <meta name="description" content="Play Mahjong Now" />
              </Helmet>
          } />
          <Route
            path="/*"
            element={
              <ComponentWrapper>
                <TopNav />
                <Routes>
                  <Route path="/scoring/*" element={<Scoring />}></Route>
                  <Route path="/match/*" element={<Match />}></Route>
                  <Route path="/about" element={<About />}></Route>
                  <Route path="/profile" element={<Profile />}></Route>
                  <Route path="/community/*" element={<Community />}></Route>
                  <Route path="/calculator" element={<Calculator />}></Route>
                  <Route path="/motd/*" element={<MatchOfTheDay />}></Route>
                  <Route element={<NoMatch />} />
                </Routes>
              </ComponentWrapper>
            }
          />
        </Routes>
      </Frame>
    </PageContainer>
  );
}

export default App;
