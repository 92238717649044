import React from "react";
import { FormattedMessage } from "react-intl";
import { PanelContent } from "./Page.styled";

const Loader = () => (
  <PanelContent>
    <FormattedMessage id="Loading" />
  </PanelContent>
);
export default Loader;
