import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  body {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.dark};
    color: ${({ theme }) => theme.light};
    text-rendering: optimizeLegibility;
    font-family: ${({ theme }) => theme.text};
  }

  h1 {
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
  }

  img {
    border-radius: 5px;
    height: auto;
    width: 10rem;
  }

  div {
    text-align: center;
  }

  small {
    display: block;
  }

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }

  :root {
  /* Colors: */
  --primary-color: #1f68fa;
  --light-text-color: #f5f6f7;
  --light-placeholder-color: #ced4de;
  --dark-text-color: #2d3747;

  /* Shadows: */
  --shadow-dilute: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  --shadow-conc: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);

  /* Misc: */
  --transition: all 0.2s ease-in-out;
}
`;
