import React from "react";

import { Tile as ITile } from "../../api/mahjong-api";
import { Tile } from "./Tile";

import { Container } from "./MeldButton.styled";

type Props = {
  onClick?: () => void;
  tiles?: ITile[];
  ratio?: number;
  className?: string;
};

export const MeldButton = ({
  onClick = () => {},
  tiles = [],
  ratio = 1,
  className = "",
}: Props) => (
  <Container className={className} onClick={onClick} ratio={ratio}>
    {tiles &&
      tiles.map((t, i) => (
        <Tile offset={i} code={t.code} ratio={ratio} key={`${t.id}${i}`} />
      ))}
  </Container>
);
