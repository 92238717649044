import { CaptureRequest, TurnRequest } from "../../../api/mahjong-api";
import CaptureOptions from "./CaptureOptions";
import TurnOptions from "./TurnOptions";
import { FormattedMessage } from "react-intl";

import Html from "../../../components/common/r3f/Html";
import { animated, useSpring } from "@react-spring/three";
import { useEffect, useState } from "react";
import { config } from "@react-spring/web";
import { Wrapper, Start } from "./Options.styled";

export type OptionsActionProps = {
  draw?: () => void;
  capture?: (request: CaptureRequest) => void;
  selfKong?: (request: TurnRequest) => void;
  selfPick?: (request: TurnRequest) => void;
};

type Props = OptionsActionProps & {
  isStart?: boolean;
  turnOptions?: TurnRequest[];
  captureOptions?: CaptureRequest[];
};

export const Options = ({
  isStart = false,
  turnOptions = [],
  captureOptions = [],
  draw = () => {},
  capture = () => {},
  selfKong = () => {},
  selfPick = () => {},
}: Props) => {
  const [{ scale, y }, api] = useSpring(() => ({
    scale: 2.5,
    y: 0,
    config: config.slow,
  }));
  const [enabled, setEnabled] = useState(true);
  const handleCapture = (req: CaptureRequest) => {
    setEnabled(false);
    capture(req);
  };

  const handleKong = (req: TurnRequest) => {
    setEnabled(false);
    selfKong(req);
  };

  const handleWin = (req: TurnRequest) => {
    setEnabled(false);
    selfPick(req);
  };

  const handleStart = () => {
    setEnabled(false);
    draw();
  };

  useEffect(() => {
    if (!enabled) {
      api.start({ scale: 1, y: 50 });
    }
  }, [enabled, api]);

  return (
    <animated.group scale={scale} position-y={y}>
      <Html transform center>
        {isStart && turnOptions.length > 0 && (
          <Wrapper>
            <Start onClick={() => handleStart()}>
              <FormattedMessage id="StartMatch" />
            </Start>
          </Wrapper>
        )}
        {captureOptions.length > 1 && (
          <CaptureOptions options={captureOptions} capture={handleCapture} />
        )}
        {!isStart && turnOptions.length > 0 && (
          <TurnOptions
            options={turnOptions}
            kong={handleKong}
            win={handleWin}
          />
        )}
      </Html>
    </animated.group>
  );
};

export default Options;
