import React from "react";
import { Footer, Page } from "../../components/common/Page.styled";
import List, { Props as ListProps } from "./list/List";
import Rules from "./rules/List";
import { ActionButton as Button } from "../../components/common/Buttons.styled";
import { FormattedMessage } from "react-intl";
import Calculator, { Props as CreateProps } from "./create/Calculator";
import {
  GetRulesResponse,
  UpdateCalculationRequest,
} from "../../api/mahjong-api";
import { Pane } from "../../components/common/Sliding.styled";
import { NavigateFunction, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet-async";

type Props = CreateProps &
  ListProps & {
    rules?: GetRulesResponse;
    navigate?: NavigateFunction;
    update?: (req: UpdateCalculationRequest) => void;
  };

const Scoring = ({
  tiles,
  rules,
  navigate = () => {},
  callToAction: create,
  update,
  deleteSample,
  editSample,
  data,
  examples,
}: Props) => (
  <Page>
    <Helmet>
      <title>Mahjong Spot - Scoring</title>
      <meta name="description" content="Learn the rules to score a hand" />
    </Helmet>
    <Routes>
      <Route
        path="/examples/edit/*"
        element={
          <Pane
            isOpen={true}
            title={<FormattedMessage id="Update" />}
            onRequestClose={() => {
              navigate("/scoring/examples");
            }}
          >
            <Calculator tiles={tiles} data={data} callToAction={update} />
          </Pane>
        }
      />
      <Route
        path="/examples/create"
        element={
          <Pane
            isOpen={true}
            title={<FormattedMessage id="Create" />}
            onRequestClose={() => {
              navigate("/scoring/examples");
            }}
          >
            <Calculator tiles={tiles} callToAction={create} />
          </Pane>
        }
      />
    </Routes>
    <Routes>
      <Route
        path="/examples/*"
        element={
          <>
            <List
              examples={examples}
              deleteSample={deleteSample}
              editSample={editSample}
            />
            <Footer>
              <Button onClick={() => navigate("/scoring")}>
                <FormattedMessage id="Rules" />
              </Button>

              <Button onClick={() => navigate("examples/create")}>
                <FormattedMessage id="NewCalculation" />
              </Button>
            </Footer>
          </>
        }
      />
      <Route path="/" element={<Rules rules={rules} tiles={tiles} />} />
    </Routes>
  </Page>
);

export default Scoring;
