import React from "react";
import { TileButton } from "../../../../components/game/TileButton";

import { Tile } from "../../../../api/mahjong-api";

type TileListProps = {
  tiles: Tile[];
  onClick: (tile: Tile) => void;
};

const InputRow = (props: TileListProps) => (
  <>
    {props.tiles.map((t, i) => (
      <TileButton
        key={`${t.id}-${i}`}
        code={t.code}
        onClick={() => props.onClick(t)}
        ratio={0.5}
      />
    ))}
  </>
);

export default InputRow;
