import React from "react";
import { Match } from "../../api/mahjong-api";
import { FormattedMessage } from "react-intl";
import { DetailsText, Wrapper } from "./Details.styled";

export type Props = {
  match?: Match;
};

export const MatchDetails = ({ match }: Props) => {
    const rounds = match?.settings?.totalRounds || 0;
  return (
    <Wrapper>
        <DetailsText>{match?.name}</DetailsText>
        <DetailsText>{match?.status}</DetailsText>
        <DetailsText>
        {rounds} {" "}
        {rounds  > 1 ? <FormattedMessage id="Rounds"/> : <FormattedMessage id="Round"/> }
        </DetailsText>
    </Wrapper>
  );
};

export default MatchDetails;
