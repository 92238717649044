import { RoundedBox } from "@react-three/drei";
import { Color } from "three";

const TableTop = () => {
  const brown = new Color(0x362419);
  const w = 38;
  const d = 1.2;
  const dd = 1.2;
  const h = 0.2;
  const p = 18.5;
  const r = 0.3;

  return (
    <group
      receiveShadow
      rotation={[-Math.PI / 2, 0, 0]}
      position={[0, -0.7, 0]}
    >
      <RoundedBox radius={0} smoothness={10} args={[37, 37, 1.5]}>
        <meshStandardMaterial attachArray="material" color={"#36814b"} />
        <meshStandardMaterial attachArray="material" color={"grey"} />
      </RoundedBox>
      <RoundedBox
        radius={r}
        smoothness={10}
        args={[dd, w, d]}
        position={[p, 0, h]}
      >
        <meshStandardMaterial attachArray="material" color={brown} />
        <meshStandardMaterial attachArray="material" color={brown} />
      </RoundedBox>
      <RoundedBox
        radius={r}
        smoothness={10}
        args={[dd, w, d]}
        position={[-p, 0, h]}
      >
        <meshStandardMaterial attachArray="material" color={brown} />
        <meshStandardMaterial attachArray="material" color={brown} />
      </RoundedBox>
      <RoundedBox
        radius={r}
        smoothness={10}
        args={[dd, w, d]}
        rotation={[0, 0, Math.PI / 2]}
        position={[0, p, h]}
      >
        <meshStandardMaterial attachArray="material" color={brown} />
        <meshStandardMaterial attachArray="material" color={brown} />
      </RoundedBox>
      <RoundedBox
        radius={r}
        smoothness={10}
        args={[dd, w, d]}
        rotation={[0, 0, Math.PI / 2]}
        position={[0, -p, h]}
      >
        <meshStandardMaterial attachArray="material" color={brown} />
        <meshStandardMaterial attachArray="material" color={brown} />
      </RoundedBox>
    </group>
  );
};

export default TableTop;
