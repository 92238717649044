import styled from "styled-components";
import { Tile } from "./Tile";

export const TileButton = styled(Tile)<{ disabled?: boolean }>`
  &:hover {
    background-color: #dee4ed;
    box-shadow: 0 ${(props) => `${(props.ratio || 1) * 9}`}px #999;
  }
  &:active {
    background-color: #dee4ed;
    box-shadow: 0 ${(props) => `${(props.ratio || 1) * 5}`}px #666;
    transform: translateY(${(props) => `${(props.ratio || 1) * 4}`}px);
  }
  background-color: ${({ disabled }) => (disabled ? "grey" : "none")};
`;

export const Test = styled(Tile)`
  background-color: #dee4ed;
  box-shadow: 10px -10px #999;
  &:active {
    background-color: #dee4ed;
    box-shadow: 0 ${(props) => `${(props.ratio || 1) * 5}`}px #666;
    transform: translateY(${(props) => `${(props.ratio || 1) * 4}`}px);
  }
`;

TileButton.defaultProps = {
  ratio: 1,
};
