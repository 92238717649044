import React from "react";
import { Row, Item } from "../../components/common/Page.styled";

type Props = {
  turnTime?: number;
  selectTurnTime?: (l: number) => void;
};

export const TurnTime = ({ turnTime, selectTurnTime = () => {} }: Props) => (
  <Row>
    <Item selected={turnTime === 10} onClick={() => selectTurnTime(10)}>
      10
    </Item>
    <Item selected={turnTime === 20} onClick={() => selectTurnTime(20)}>
      20
    </Item>
    <Item selected={turnTime === 30} onClick={() => selectTurnTime(30)}>
      30
    </Item>
  </Row>
);

export default TurnTime;
