import styled from "styled-components";

export const Section = styled.div`
  border-radius: 0.4rem;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.primary};
  margin: 0.5rem;
`;

export const ActionSection = styled(Section)`
  width: 17rem;
`

export const SectionName = styled.div`
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: ${({ theme }) => theme.primary};
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  color: black;
  display: flex;
`;

export const SectionBody = styled.div`
  color: white;
  text-transform: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Main = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-wrap: wrap-reverse;
  flex-grow: 2;
`;

export const MatchList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 3;
`;

export const ActionList = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ActionDesc = styled.div`
  width: 230px;
  font-size: 0.75rem;
  padding: 0.5rem;
`;

export const Info = styled.div`
  font-size: 0.75rem;
`;
