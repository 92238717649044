import React from "react";
import * as THREE from "three";

import Html from "../../../components/common/r3f/Html";
import { FormattedMessage } from "react-intl";
import { TimerFrame, Urgent } from "./PlayerStatus.styled";
import { Timer as Icon } from "@styled-icons/boxicons-regular";
import CountUp from "react-countup";

export type Props = {
  turnTime?: number;
  message?: string;
};

function Timer({ message, turnTime = 10 }: Props) {
  const back = new THREE.Vector3(0, 4, 0);
  return (
    <Html transform center position={back}>
      <TimerFrame>
        <Urgent>
          <Icon size={40} />
          <CountUp start={turnTime} end={0} duration={turnTime} />
        </Urgent>
        {message && <FormattedMessage id={message} />}
      </TimerFrame>
    </Html>
  );
}

export default Timer;
