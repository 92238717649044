import React, { useEffect, useState } from "react";
import * as THREE from "three";

import { useLoader } from "@react-three/fiber";
import { tilePaths } from "./lookup";
import { animated, SpringValue, useSpring } from "@react-spring/three";
import BaseTile from "./BaseTile";
export type Props = {
  code: string;
  x: number;
  scale?: number;
  isActive?: boolean;
  tapped?: boolean;
  shouldReset?: boolean;
  startTap?: () => void;
  onClick?: () => void;
  rotateX?: number | SpringValue<number>;
  rotateY?: number | SpringValue<number>;
  positionZ?: number | SpringValue<number>;
};

function TapTile({
  x: start = 0,
  isActive = false,
  tapped = false,
  shouldReset = true,
  startTap = () => {},
  code,
  scale = 1,
  onClick = () => {},
  rotateX = 0,
  rotateY = 0,
  positionZ = 0,
}: Props) {
  const [{ x, y, z }, api] = useSpring(() => ({ x: 0, y: 0, z: positionZ }));

  const texture = useLoader(THREE.TextureLoader, tilePaths[code]);
  const [selected, setSelected] = useState(false);
  texture.minFilter = THREE.LinearFilter;
  useEffect(() => {
    if ((!isActive && shouldReset) || !tapped) {
      setSelected(false);
      api.start({ x: 0, y: 0, z: 0 });
    }
  }, [isActive, api, shouldReset, tapped]);

  const handleTap = () => {
    if (!isActive) {
      setSelected(false);
      api.start({ x: 0, y: 0, z: 0 });
      return;
    }

    if (!selected) {
      setSelected(true);
      startTap();
      api.start({
        x: 0,
        y: 3,
        z: 0,
      });
    } else {
      setSelected(false);
      api.start({
        x: 10 - start,
        y: 0,
        z: -1.5,
        onRest: () => onClick(),
      });
    }
  };
  return (
    <animated.group
      onClick={() => handleTap()}
      rotation-x={rotateX}
      rotation-y={rotateY}
      position-x={x}
      position-z={z}
      position-y={y}
    >
      <BaseTile x={start} isActive={isActive} code={code} scale={scale} />
    </animated.group>
  );
}

export default TapTile;
