import styled from "styled-components";

export const Image = styled.img<{
  ratio?: number;
}>`
  width: ${(props) => `${6 * (props.ratio || 1)}rem`};
  height: ${(props) => `${6 * (props.ratio || 1)}rem`};
  object-fit: fill;
  user-select: none;
`;
