import React from "react";

import { Meld, Tile } from "../../../api/mahjong-api";
import { Col, Row } from "../../../components/common/Page.styled";
import { Meld as M } from "../../../components/game/Meld";
import { Tile as T } from "../../../components/game/Tile";
import { Drawn, OutOn } from "./Hand.styled";

type Props = {
  flowers?: Tile[];
  melded?: Meld[];
  concealed?: Tile[];
  outOn?: Tile;
  drawn?: Tile;
  ratio?: number;
};

const Hand = ({
  melded = [],
  flowers = [],
  concealed = [],
  outOn,
  drawn,
  ratio = 0.7,
}: Props) => (
  <Col>
    <Row>
      {flowers.map((t, i) => (
        <T key={`${t.id}${i}`} code={t.code} ratio={0.5 * ratio} />
      ))}
    </Row>
    <Row>
      {melded.map((t, i) => (
        <M key={`${i}`} codes={t.tiles.map((t) => t.code)} ratio={ratio} />
      ))}

      {[...concealed]
        .sort((a, b) => a.id - b.id)
        .map((t, i) => (
          <T key={`${t.id}${i}`} code={t.code} ratio={ratio} />
        ))}
      {outOn && (
        <OutOn>
          <T code={outOn.code} ratio={ratio} />
        </OutOn>
      )}
      {drawn && (
        <Drawn>
          <T code={drawn.code} ratio={ratio} />
        </Drawn>
      )}
    </Row>
  </Col>
);

export default Hand;
