import styled from "styled-components";

export const Player = styled.div`
  font-size: 1rem;
  padding-bottom: 0.5rem;
`;

export const Spot = styled.div`
  border: solid;
  border-color: ${({ theme }) => theme.primary};
  border-radius: 4px;
  border-width: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  width: 8rem;
`;

export const Players = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 0.5rem;
`;

export const Select = styled.select`
  display: flex;
  border-radius: 4px;
  font-size: 1.5rem;
  padding: 10px 15px;
  width: 100%;
`;

export const Label = styled.label`
  display: flex;
  font-size: 1.7rem;
`;

export const Table = styled.table`
  width: 80%;
`;

export const ViewLabel = styled.div`
  background-color: ${({ theme }) => theme.primary};
  color: black;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  width: 16rem;
  white-space: nowrap;
  padding: 0.2rem;
`;
