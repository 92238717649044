import React from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage } from "react-intl";
import { Container } from "../../components/common/List.styled";
import { Header, Module } from "../../components/common/Page.styled";

const About = () => (
  <Module>
    <Helmet>
      <title>Mahjong Spot - About</title>
      <meta name="description" content="About Mahjong Spot" />
    </Helmet>

    <Header>
      <FormattedMessage id="About" />
    </Header>
    <Container></Container>
  </Module>
);

export default About;
