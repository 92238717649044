import styled from "styled-components";

export const Label = styled.div`
  background-color: ${({ theme }) => theme.primary};
  color: black;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  min-width: 5rem;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0.2rem;
`;

export const Frame = styled.div`
  background-color: transparent;
  border-style: solid;
  border-color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border-width: 0.4rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.7rem;
  align-items: center;
  padding-bottom: 1rem;
`;

export const Column = styled.div`
  flex-direction: column;
  flex-grow: 1;
  font-size: 2.5rem;
  text-transform: uppercase;
`;
