import React from "react";
import { FormattedMessage } from "react-intl";
import { GameFrame, ResultKind } from "../../../api/mahjong-api";
import { PointsBreakdown } from "../../../components/game/PointsBreakdown";
import Hand from "../../scoring/list/Hand";
import Avatar from "../Avatar";
import { getLastDiscard, MatchResult } from "../frame";
import { PlayerMap } from "../Game";
import {
  Card,
  CardRow,
  Column,
  Row,
  Score,
  WinnerRow,
} from "./GameOver.styled";

export type Props = {
  frame?: GameFrame;
  currentScore?: MatchResult;
  playerMap: PlayerMap;
};

const getScore = (result: MatchResult, clientId: string) => {
  const hasResult = Object.keys(result).includes(clientId);
  return hasResult ? result[clientId] : 0;
};

const CaptureWin = ({ frame, playerMap, currentScore = {} }: Props) => {
  const seats = frame?.seats || {};
  const winningSeats = Object.values(seats).filter(
    (s) => s.result?.kind === ResultKind.Win
  );
  const discard = getLastDiscard(frame?.discards);
  const losingSeat = Object.values(seats).find(
    (s) => s.clientId === discard?.discardPlayerId
  );
  const loserScore = currentScore[losingSeat?.clientId || ""] || 0;
  const totalLost = winningSeats.reduce(
    (s, d) => (d.result?.totalPoints || 0) + s,
    0
  );
  return (
    <Column>
      {winningSeats.map(({ result, hand, outOn, clientId }) => (
        <CardRow key={`${clientId}-r`}>
          <Card>
            <Row>
              {result && <PointsBreakdown result={result} />}
            </Row>
          </Card>
          <Card>
            <Score>
              <FormattedMessage id="WinnerTotalPoints" values={{ points: result?.totalPoints }} />
            </Score>
            <Hand
              flowers={hand?.flowers}
              concealed={hand?.concealed}
              melded={hand?.melded}
              outOn={outOn}
              ratio={0.3}
            />
            <WinnerRow>
              <FormattedMessage id="WinningHand" />
              <Avatar ratio={0.5} image={playerMap[clientId]?.imageUrl || ""} />
              {getScore(currentScore, clientId)} + {result?.totalPoints} ={" "}
              {getScore(currentScore, clientId) + (result?.totalPoints || 0)}
            </WinnerRow>
          </Card>
        </CardRow>
      ))}
      <Row>
        <FormattedMessage id="LosingPlayer" />
        <Avatar
          ratio={0.5}
          image={playerMap[losingSeat?.clientId || ""]?.imageUrl || ""}
        />
        {loserScore} - {totalLost} = {loserScore - totalLost}
      </Row>
    </Column>
  );
};

export default CaptureWin;
