import Html from "../../../components/common/r3f/Html";
import { FormattedMessage } from "react-intl";
import { CaptureKind, CaptureRequest } from "../../../api/mahjong-api";
import { Meld } from "../../../components/game/Meld";
import { Props as ActionProps } from "../Action";
import { useSpring, animated } from "@react-spring/three";
import { CaptureText } from "./Capture.styled";
import useSound from "use-sound";
import chowFx from "../../../sound/chow.mp3";
import pungFx from "../../../sound/pung.mp3";
import kongFx from "../../../sound/kong.mp3";

type Props = {
  action?: ActionProps;
  rotation?: number;
};

const Capture = ({ action, rotation = Math.PI }: Props) => {
  const captureRequest = action?.actionValue as unknown as CaptureRequest;

  const { posZ } = useSpring({
    from: { posZ: 1 },
    to: { posZ: -7 },
  });

  const { scale } = useSpring({
    from: { scale: 1 },
    to: { scale: 2 },
  });
  const [chow] = useSound(chowFx);
  const [pung] = useSound(pungFx);
  const [kong] = useSound(kongFx);

  if (!captureRequest) {
    return null;
  }

  if (captureRequest.kind === CaptureKind.Chow) {
    chow();
  } else if (captureRequest.kind === CaptureKind.Pung) {
    pung();
  } else if (captureRequest.kind === CaptureKind.Kong) {
    kong();
  }

  return (
    <animated.group position-z={posZ} scale={scale}>
      <Html transform center rotation={[0, rotation, 0]}>
        {action?.actionType?.includes("Capture") &&
          captureRequest?.meldIds?.length > 0 && (
            <CaptureText>
              <FormattedMessage
                id={action?.actionType}
                values={action?.actionValue}
              />
              <Meld ratio={0.75} codes={captureRequest.codes} />
            </CaptureText>
          )}
      </Html>
    </animated.group>
  );
};

export default Capture;
