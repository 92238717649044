import React from "react";
import GestureTile from "../../../../components/three/GestureTile";

export type Props = {
  count?: number;
};

const Wall = ({ count = 0 }: Props) => {
  const half = Math.floor(count / 2);
  const remainder = count % 2;
  const frontCount = half;
  const backCount = half + remainder;
  return (
    <group position={[-1.5, -1, -12]} rotation={[Math.PI / 2, 0, Math.PI / 6]}>
      <group position={[0, -1, 0]}>
        {[...Array(frontCount)].map((e, i) => (
          <GestureTile x={i * 0.8} key={`wall${i}`} code={"🀆"} scale={0.6} />
        ))}
      </group>
      <group>
        {[...Array(backCount)].map((e, i) => (
          <GestureTile x={i * 0.8} key={`wall${i}`} code={"🀆"} scale={0.6} />
        ))}
      </group>
    </group>
  );
};
export default Wall;
