import React from "react";
import { Meld } from "../../../../api/mahjong-api";
import MeldSet from "./Meld";

type Props = JSX.IntrinsicElements["mesh"] & {
  melded: Meld[];
  x?: number;
  ratio?: number;
};

const Melded = ({ melded, ratio = 1, x = 0 }: Props) => {
  let pos = x;
  return (
    <>
      {melded?.map((m, i) => {
        const result = (
          <MeldSet key={`m${m.code}${i}`} meld={m} ratio={ratio} x={pos} />
        );
        pos += m.tiles.length;
        return result;
      })}
    </>
  );
};

export default Melded;
