import React from "react";
import { Wind } from "../../../../api/mahjong-api";
import { windCodes } from "../../../../components/game/constants";
import { TileButton } from "../../../../components/game/TileButton";
import { SectionProps } from "../Hand";
import { Tray } from "./Tray";

type WindProps = SectionProps & {
  wind: Wind;
};

const SeatWind = ({ wind, isActive, onClick }: WindProps) => (
  <Tray name="TraySeat" isActive={isActive} single={true}>
    {wind && (
      <TileButton
        ratio={0.4}
        code={windCodes.get(wind) || ""}
        onClick={() => onClick(0)}
      />
    )}
  </Tray>
);

export default SeatWind;
