import styled from "styled-components";
import { animated } from "@react-spring/web";

export const MatchStatus = styled.div`
  background-color: transparent;
  border-style: solid;
  border-color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;
export const Column = styled.div`
  flex-direction: column;
  flex-grow: 1;
  font-size: 2.5rem;
  text-transform: uppercase;
`;

export const Label = styled.div`
  background-color: ${({ theme }) => theme.primary};
  color: black;
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const Results = styled.tbody`
  font-size: 0.7rem;
`;

export const TdPlayer = styled.td`
  height: 3rem;
  width: 10rem;
`;

export const Overlay = styled(animated.div)`
  position: absolute;
  z-index: 10000;
`;

export const Title = styled.div`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.title};
  font-size: 2rem;
  padding-bottom: 0.5rem;
  color: ${({ theme }) => theme.primary};
`;
