import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { selectClientId, selectConnectionId } from "../auth/selector";
import Game, { PlayerMap } from "./Game";
import { selectGameFrame } from "./selector";
import {
  useCaptureTileMutation,
  useDiscardTileMutation,
  useSelfKongMutation,
  useSelfPickMutation,
} from "./service";
import { useDrawMutation } from "../motd/service";
import {
  useContinueMatchMutation,
  useGetMatchQuery,
  useGetMatchGameResultsQuery,
} from "../match/service";
import { useAppDispatch } from "../../app/hooks";
import { nextFrame } from "./reducer";
import Display from "./display/Display";
import { Wrapper } from "./Game.styled";

const Container = () => {
  const frame = useSelector(selectGameFrame);
  const clientId = useSelector(selectClientId);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const timerRef = useRef<NodeJS.Timeout>();

  const refresh = useCallback(() => {
    dispatch(nextFrame());
    return setTimeout(() => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = refresh();
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = refresh();
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [refresh]);

  const routeMatch = useMatch("/game/:matchId");
  const connectionId = useSelector(selectConnectionId);
  const matchId = +(routeMatch?.params.matchId || "");
  const { data: matchResponse } = useGetMatchQuery(
    { id: matchId, connectionId },
    { skip: !matchId, refetchOnMountOrArgChange: true }
  );
  const { data: matchResults, refetch } = useGetMatchGameResultsQuery(matchId);
  const [discardTile] = useDiscardTileMutation();
  const [capture] = useCaptureTileMutation();
  const [selfKong] = useSelfKongMutation();
  const [selfPick] = useSelfPickMutation();
  const [continueMatch] = useContinueMatchMutation();
  const [draw] = useDrawMutation();

  const navigate = useNavigate();
  const visible = pathname.includes("game");

  const init: PlayerMap = {};
  const playerMap = matchResponse?.match?.clients?.reduce((s, c) => {
    if (c.player) {
      s[c.clientId] = c.player;
    }
    return s;
  }, init);

  useEffect(() => {
    if (frame?.isComplete && !frame?.matchComplete) {
      refetch();
    }
  }, [frame?.isComplete, frame?.matchComplete, refetch]);

  const shouldShowFrame = frame?.matchId === matchId;

  const [showHistory, setShowHistory] = useState(false);
  return (
    <Wrapper visible={visible}>
      <Game
        turnTime={matchResponse?.match?.settings?.turnTime}
        frame={shouldShowFrame && !frame?.matchComplete ? frame : undefined}
        playerMap={playerMap}
        matchResults={matchResults?.results || []}
        clientId={clientId}
        discard={(tileId) => discardTile({ tileId, matchId })}
        draw={() => draw({ matchId })}
        capture={(pr) => capture(pr)}
        selfKong={(r) => selfKong(r)}
        selfPick={(r) => selfPick(r)}
        continueMatch={() => continueMatch({ matchId })}
        goToLobby={() => navigate("/match")}
        visible={visible}
      />

      {visible && shouldShowFrame && frame?.isComplete && (
        <Display
          key={`dsp${frame?.gameId}-${frame?.matchComplete}-${frame?.isComplete}`}
          shouldShowHistory={showHistory}
          matchResults={matchResults?.results || []}
          frame={frame}
          clientId={clientId}
          playerMap={playerMap}
          showHistory={() => setShowHistory(true)}
          hideHistory={() => setShowHistory(false)}
          continueMatch={() => continueMatch({ matchId })}
          goToLobby={() => navigate("/match")}
          goToNewMatch={() => navigate("/match/create")}
          goToMotd={() => navigate("/motd")}
        />
      )}
    </Wrapper>
  );
};

export default Container;
