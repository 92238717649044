import c1 from "./c1.svg";
import c2 from "./c2.svg";
import c3 from "./c3.svg";
import c4 from "./c4.svg";
import c5 from "./c5.svg";
import c6 from "./c6.svg";
import c7 from "./c7.svg";
import c8 from "./c8.svg";
import c9 from "./c9.svg";

import d1 from "./d1.svg";
import d2 from "./d2.svg";
import d3 from "./d3.svg";
import d4 from "./d4.svg";
import d5 from "./d5.svg";
import d6 from "./d6.svg";
import d7 from "./d7.svg";
import d8 from "./d8.svg";
import d9 from "./d9.svg";

import b1 from "./b1.svg";
import b2 from "./b2.svg";
import b3 from "./b3.svg";
import b4 from "./b4.svg";
import b5 from "./b5.svg";
import b6 from "./b6.svg";
import b7 from "./b7.svg";
import b8 from "./b8.svg";
import b9 from "./b9.svg";

import f1 from "./f1.svg";
import f2 from "./f2.svg";
import f3 from "./f3.svg";
import f4 from "./f4.svg";
import s1 from "./s1.svg";
import s2 from "./s2.svg";
import s3 from "./s3.svg";
import s4 from "./s4.svg";

import e from "./e.svg";
import s from "./s.svg";
import w from "./w.svg";
import n from "./n.svg";
import rd from "./rd.svg";
import gd from "./gd.svg";
import wd from "./wd.svg";

const winds = {
  "🀀": e,
  "🀁": s,
  "🀂": w,
  "🀃": n,
};
const dragons = {
  "🀄": rd,
  "🀅": gd,
  "🀆": wd,
};
const characters = {
  "🀇": c1,
  "🀈": c2,
  "🀉": c3,
  "🀊": c4,
  "🀋": c5,
  "🀌": c6,
  "🀍": c7,
  "🀎": c8,
  "🀏": c9,
};
const bamboos = {
  "🀐": b1,
  "🀑": b2,
  "🀒": b3,
  "🀓": b4,
  "🀔": b5,
  "🀕": b6,
  "🀖": b7,
  "🀗": b8,
  "🀘": b9,
};
const dots = {
  "🀙": d1,
  "🀚": d2,
  "🀛": d3,
  "🀜": d4,
  "🀝": d5,
  "🀞": d6,
  "🀟": d7,
  "🀠": d8,
  "🀡": d9,
};
const flowers = {
  "🀢": f1,
  "🀣": f2,
  "🀤": f3,
  "🀥": f4,
};
const seasons = {
  "🀦": s1,
  "🀧": s2,
  "🀨": s3,
  "🀩": s4,
};

export const tilePaths: Record<string, string> = {
  ...winds,
  ...dragons,
  ...characters,
  ...bamboos,
  ...dots,
  ...flowers,
  ...seasons,
};
