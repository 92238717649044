import styled from "styled-components";

export const PlayerName = styled.div`
  font-family: ${({ theme }) => theme.text};
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  margin: 0.2rem;
  font-weight: 100;
`;