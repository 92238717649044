import React from "react";
import { Match } from "../../api/mahjong-api";
import {
  Container,
  Table,
  Tr,
  Td,
  THead,
} from "../../components/common/List.styled";
import { useTable, CellProps } from "react-table";
import { FormattedMessage } from "react-intl";
import Players from "./Players";
import { ArrowRightCircle } from "@styled-icons/bootstrap";
import { Info } from "./Lobby.styled";
import Details from "./Details";

export type Props = {
  matches: Match[];
  viewMatch?: (id: number) => void;
  deleteMatch?: (id: number) => void;
};

export const List = ({
  matches,
  viewMatch = () => {},
  deleteMatch = () => {},
}: Props) => {
  const data = React.useMemo(() => matches, [matches]);
  const columns = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="Id"/>,
        id: "id",
        Cell: (r: CellProps<Match>) => <Info>{r.row.original.id || ""}</Info>,
      },
      {
        Header: <FormattedMessage id="Details" />,
        id: "name",
        Cell: (r: CellProps<Match>) => <Details match={r.row.original}/>,
      },
      {
        Header: <FormattedMessage id="Players" />,
        id: "players",
        Cell: (r: CellProps<Match>) => (
          <Players players={r.row.original?.clients || []} />
        ),
      },
      {
        Header: "...",
        id: "action",
        Cell: (r: CellProps<Match>) => <ArrowRightCircle size={20} />,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <Container>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </THead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                onClick={() => viewMatch(row.original.id || 0)}
              >
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};
export default List;
