import React from "react";
import { Meld, MeldKind } from "../../../../api/mahjong-api";
import Tile from "../../../../components/three/GestureTile";

type Props = JSX.IntrinsicElements["mesh"] & {
  meld: Meld;
  x?: number;
  ratio?: number;
};

const MeldSet = ({ meld, ratio = 1, x = 0 }: Props) => {
  let pos = x;

  if (meld.meldKind === MeldKind.Chow && meld.meldWith) {
    const sorted = meld.tiles.filter((t) => t.id !== meld.meldWith?.id);
    sorted.splice(1, 0, meld.meldWith);
    return (
      <>
        {sorted.map((c, j) => (
          <Tile
            x={pos++ * 1.55}
            code={c.code}
            key={`${c.id}`}
            scale={ratio}
            positionZ={-10}
            rotateX={-Math.PI / 2}
          />
        ))}
      </>
    );
  } else {
    return (
      <>
        {meld.tiles.map((c, j) => (
          <Tile
            x={pos++ * 1.55}
            code={c.code}
            key={`${c.id}`}
            scale={ratio}
            positionZ={-10}
            rotateX={-Math.PI / 2}
          />
        ))}
      </>
    );
  }
};

export default MeldSet;
