import React from "react";
import { PageDescription as Description } from "../../../components/common/Page.styled";
import { Td, RulesPanel as Panel } from "./List.styled";

const Melds = () => (
  <Panel>
    <div>
      Melds
      <Description>
        Tiles can be melded together into sets which can form the basis of a
        standard winning hand with 5 melds and 1 eye.
      </Description>
    </div>
    <table>
      <tbody>
        <tr>
          <Td>Chow</Td>
          <Td>Three consecative tiles of the same suit</Td>
        </tr>
        <tr>
          <Td>Eye</Td>
          <Td>Two of the same tile</Td>
        </tr>
        <tr>
          <Td>Pong</Td>
          <Td>Three of the same tile</Td>
        </tr>
        <tr>
          <Td>Kong</Td>
          <Td>Four of the same tile</Td>
        </tr>
      </tbody>
    </table>
  </Panel>
);

export default Melds;
