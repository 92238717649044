import React from "react";
import Html from "../../components/common/r3f/Html";
import { FormattedMessage } from "react-intl";
import { Discard } from "../../api/mahjong-api";
import { Tile } from "../../components/game/Tile";
import { ActionFrame, ActionText, ActionTile } from "./Action.styled";
import { animated, useSpring } from "@react-spring/three";

export type Props = {
  clientId?: string;
  actionType?: string;
  actionValue?: Record<string, string>;
  lastDiscard?: Discard;
  rotation?: number;
};

const Action = ({
  actionType,
  actionValue = {},
  lastDiscard,
  rotation = Math.PI,
}: Props) => {
  const { posZ } = useSpring({
    from: { posZ: 1 },
    to: { posZ: -2 },
  });
  if (actionType?.includes("Capture")) {
    return null;
  }

  return (
    <animated.group position-z={posZ}>
      <Html transform center rotation={[0, rotation, 0]}>
        <ActionFrame>
          <ActionText>
            <FormattedMessage id={actionType} values={actionValue} />
          </ActionText>
          {actionType?.includes("Discard") && lastDiscard?.tile?.code && (
            <ActionTile>
              <Tile ratio={1.3} code={lastDiscard.tile.code} />
            </ActionTile>
          )}
        </ActionFrame>
      </Html>
    </animated.group>
  );
};

export default Action;
