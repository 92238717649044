import styled from "styled-components";
import { Button as BaseButton } from "../../../components/common/Buttons.styled";

export const Frame = styled.div`
  width: 100vw;
  min-height: 55vw;
  font-weight: bolder;
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.7rem;
  align-items: center;
`;

export const WinnerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1rem;
  align-items: center;
  margin-bottom: 0.3rem;
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.7rem;
  align-items: top;
`;

export const ScoreCard = styled.div`
  border-radius: 5px;
  font-weight: bolder;
  color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  margin-right: 1rem;
`;

export const Card = styled.div`
  border-radius: 4px;
  font-weight: bolder;
  color: ${({ theme }) => theme.primary};
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Button = styled(BaseButton)`
  text-transform: uppercase;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
`;

export const Score = styled.div`
  padding: 0.25rem;
  font-size: 1rem;
`;

export const Total = styled.div`
  margin-top: 0.5rem;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.25);
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
