import React from "react";
import { FormattedMessage } from "react-intl";

import {
    ActionButton as Button,
  } from "../Buttons.styled";
import { Motd, Small } from "./MotdButton.styled";

type Props = {
  onClick?: () => void;
}

const MotdButton = ({onClick = () => {}}: Props) => (
  <Button onClick={onClick}>
    <Motd>
      <FormattedMessage id="Match" />
      <Small>
        <FormattedMessage id="OfThe" />
      </Small>
      <FormattedMessage id="Day" />
    </Motd>
  </Button>
);

export default MotdButton;