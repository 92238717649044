import React from "react";
import * as THREE from "three";

import Html from "../../../components/common/r3f/Html";
import { Wind } from "../../../api/mahjong-api";
import { Frame } from "./PlayerStatus.styled";
import { FormattedMessage } from "react-intl";

export type Props = {
  name?: string;
  image?: string;
  points?: number;
  wind: Wind;
  remainingCount?: number;
  isActive?: boolean;
};

function PlayerStatus({ points = 0, wind = Wind.East, name = "Guest" }: Props) {
  const back = new THREE.Vector3(0, -2, 0);
  return (
    <Html
      center
      transform
      position={back}
      scale={1.5}
      rotation-x={-Math.PI / 2}
    >
      <Frame>
        <div>
          {name || "Guest"} : {wind} <FormattedMessage id="Seat" />
        </div>
        <div>
          <FormattedMessage id="Points" /> : {points}
        </div>
      </Frame>
    </Html>
  );
}

export default PlayerStatus;
