import React from "react";
import Avatar from "../game/Avatar";
import { MatchClient } from "../../api/mahjong-api";
import { Container } from "./Players.styled";

export type Props = {
  players?: MatchClient[];
};

export const Players = ({ players = [] }: Props) => {
  const openSlots = 4 - players.length;
  return (
    <Container>
      {players?.map((p) => (
        <Avatar
          ratio={0.5}
          key={`${p?.clientId}-${p?.player?.id}`}
          image={p?.player?.imageUrl || ""}
        />
      ))}
      {Array(openSlots)
        .fill(openSlots)
        .map((os, i) => (
          <div key={`open${i}`}>OPEN</div>
        ))}
    </Container>
  );
};

export default Players;
