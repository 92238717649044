import styled from "styled-components";

export const DetailsText = styled.div`
  font-family: ${({ theme }) => theme.text};
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  margin: 0.2rem;
  font-weight: 100;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
`