import styled from "styled-components";

export const Table = styled.table`
  margin-top: 5px;
  width: 100%;
`;

export const Tr = styled.tr``;
export const Th = styled.th`
  font-family: ${({ theme }) => theme.text};
`;
export const Td = styled.td`
  font-family: ${({ theme }) => theme.text};
  text-transform: none;
  font-weight: 100;
  padding: 0.2rem;
`;

export const TdNoWrap = styled.td`
  font-family: ${({ theme }) => theme.text};
  text-transform: none;
  font-weight: 100;
  white-space: nowrap;
  padding: 0.2rem;
`;

export const THead = styled.thead`
  background-color: rgba(255, 255, 255, 0.5);
  height: 2rem;
  color: #282828;
`;

export const RulesPanel = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  margin: 0.2rem;
  padding-top: 2rem;
`;

export const TBody = styled.tbody``;

export const RuleRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;

export const RuleBox = styled.div`
  display: flex;
  flex-direction: column;
  border-color: ${({ theme }) => theme.primary};
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 2px;
  margin: 1rem;
  width: 23rem;
  text-transform: none;
`;

export const RuleName = styled.div`
  padding-left: 1rem;
  flex-grow: 1;
  color: black;
  display: flex;
`;

export const RuleTitle = styled.div`
  background-color: ${({ theme }) => theme.primary};
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  color: black;
  display: flex;
`;

export const RulePoints = styled.div`
  display: flex;
  flex-grow: 1;
  padding-right: 1rem;
  justify-content: end;
`;

export const RuleDescription = styled.div`
  display: flex;
  font-size: 0.8rem;
  padding: 0.2rem;
  font-family: ${({ theme }) => theme.text};
`;

export const Examples = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
`;

export const ExampleItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.2rem;
`;

export const Points = styled.div`
  width: 5rem;
`;
