import React from "react";
import { MeldButton } from "../../../../components/game/MeldButton";
import { Tray } from "./Tray";
import { Meld } from "../../../../api/mahjong-api";
import { Section } from "../constants";

type Props = {
  melds: Meld[];
  onClick: (id: string) => void;
  onTrayClick: (id: Section) => void;
  isActive: boolean;
};

const Melded = (props: Props) => (
  <Tray
    name={Section.Melded}
    onClick={() => props.onTrayClick(Section.Melded)}
    isActive={props.isActive}
  >
    {props.melds.map((m, i) => (
      <MeldButton
        ratio={0.4}
        key={`${i}${m.code}`}
        tiles={m.tiles}
        onClick={() => props.onClick(m.code)}
      />
    ))}
  </Tray>
);

export default Melded;
