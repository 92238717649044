import styled from "styled-components";

export const OutOn = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border-bottom: solid;
  border-color: red;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  padding-bottom: 0.2rem;
`;

export const Drawn = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border-bottom: solid;
  border-color: green;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  padding-bottom: 0.2rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
