import {
  CaptureRequest,
  Discard,
  GameFrame,
  GameResult,
  Meld,
  Seat,
  Tile,
  TurnRequest,
  Wind,
} from "../../api/mahjong-api";
import { nextWind } from "../../components/game/constants";

const DrawRequestType =
  "Server.Services.Interfaces.Contracts.Games.DrawTileRequest";
const DiscardRequestType =
  "Server.Services.Interfaces.Contracts.Games.DiscardRequest";
const CaptureRequestType =
  "Server.Services.Interfaces.Contracts.Games.CaptureRequest";
const StartRequestType =
  "Server.Services.Interfaces.Contracts.Matches.StartMatchRequest";

export const isDrawReq = (frame?: GameFrame, clientId?: string) =>
  frame?.requestType === DrawRequestType && frame?.requestClientId === clientId;

export const isCaptureReq = (frame?: GameFrame, clientId?: string) =>
  frame?.requestType === CaptureRequestType &&
  frame?.requestClientId === clientId;

export const isStartReq = (frame?: GameFrame) =>
  frame?.requestType === StartRequestType;
export const isActivePlayer = (
  frame?: GameFrame,
  clientId: string = ""
): boolean =>
  frame?.activeClientId === clientId &&
  !frame?.requestType.includes("Discard") &&
  !frame?.requestType.includes("StartMatch");

export const isDiscardReq = (frame?: GameFrame, clientId?: string) =>
  frame?.requestType === DiscardRequestType &&
  frame?.activeClientId === clientId;

export const getCaptureOptions = (
  clientId: string,
  frame?: GameFrame
): CaptureRequest[] => {
  const isDiscard = frame?.requestType === DiscardRequestType;
  let captureOptions: CaptureRequest[] = [];
  if (frame?.discards?.length && isDiscard) {
    const allOptions = frame?.captureOptions || {};
    if (Object.keys(allOptions).includes(clientId)) {
      captureOptions = allOptions[clientId];
    }
  }
  return captureOptions;
};

export const getTurnOptions = (
  clientId: string,
  frame?: GameFrame
): TurnRequest[] => {
  const seats = frame?.seats || {};
  const turnOptions = seats[clientId].options;
  return turnOptions;
};

export const getActiveClientSeat = (frame?: GameFrame): Seat | undefined => {
  const seats = frame?.seats || {};
  return seats && frame?.activeClientId
    ? seats[frame.activeClientId]
    : undefined;
};

export type MatchResult = {
  [key: string]: number;
};

export const getMatchResult = (
  matchResults: GameResult[],
  gameId?: number
): MatchResult => {
  const init: MatchResult = {};

  const summary = matchResults
    .filter((mr) => mr.gameId !== gameId)
    .reduce((s, c) => {
      const cId: string = c.clientId || "";
      if (cId in s) {
        s[cId] += c.points || 0;
      } else {
        s[cId] = c.points || 0;
      }
      return s;
    }, init);

  return summary;
};

export const getLastDiscard = (discards: Discard[] = []) =>
  discards.length ? discards[discards.length - 1] : undefined;

export const getActionValue = (frame?: GameFrame) =>
  JSON.parse(frame?.requestValue || "");

export const getSeats = (frame: GameFrame, clientId: string) => {
  const seats = frame?.seats || {};
  const seat = seats[clientId];

  const others = Object.keys(seats)
    .filter((s) => s !== clientId)
    .map((o) => seats[o]);
  const rightWind = nextWind.get(seat.wind);
  const right = others.find((o) => o.wind === rightWind);
  const oppositeWind = nextWind.get(rightWind || Wind.East);
  const opposite = others.find((o) => o.wind === oppositeWind);
  const leftWind = nextWind.get(oppositeWind || Wind.East);
  const left = others.find((o) => o.wind === leftWind);
  return [seat, right, opposite, left];
};

export const getParts = (
  seat: Seat
): {
  wind: Wind;
  flowers: Tile[];
  concealed: Tile[];
  melded: Meld[];
  drawn?: Tile;
} => ({
  wind: seat.wind,
  flowers: seat.hand.flowers,
  concealed: seat.hand.concealed,
  melded: seat.hand.melded,
  drawn: seat.hand.drawn,
});
