import styled from "styled-components";

import {
  getImageX,
  getImageY,
  getImageWidth,
  getImageHeight,
  getBackgroundWidth,
  getBackgroundHeight,
} from "./constants";

import bgImage from "./default.svg";

export const getTileImage = (code: string, ratio: number) => ({
  backgroundImage: `url(${bgImage})`,
  backgroundPosition: `-${getImageX(code, ratio)}px -${getImageY(
    code,
    ratio
  )}px`,
  width: `${getImageWidth(ratio)}px`,
  height: `${getImageHeight(ratio)}px`,
  backgroundSize: `${getBackgroundWidth(ratio)}px, ${getBackgroundHeight(
    ratio
  )}px`,
});

export const Tile = styled.div<{
  code: string;
  ratio?: number;
  offset?: number;
}>`
  background-image: url(${bgImage});
  background-position-x: ${(props) =>
    `-${getImageX(props.code, props.ratio)}px`};
  background-position-y: ${(props) =>
    `-${getImageY(props.code, props.ratio)}px`};
  width: ${(props) => `${getImageWidth(props.ratio)}px`};
  height: ${(props) => `${getImageHeight(props.ratio)}px`};
  background-size: ${(props) =>
    `${getBackgroundWidth(props.ratio)}px, ${getBackgroundHeight(
      props.ratio
    )}px`};
  padding: 0px;
  display: inline-block;
  margin-left: ${(props) => `${props.offset ? -20 * (props.ratio || 1) : 0}px`};
  background-color: #ffffff;
  border: "1px solid rgba(40, 167, 69, 0.5";
  border-radius: ${(props) => `${(props.ratio || 1) * 0.5}`}rem;
`;

Tile.defaultProps = {
  onClick: () => {},
  ratio: 1,
};
