import { Wind } from "../../api/mahjong-api";

const winds = {
  "🀀": { x: 6, y: 3 },
  "🀁": { x: 5, y: 3 },
  "🀂": { x: 7, y: 3 },
  "🀃": { x: 4, y: 3 },
};
const dragons = {
  "🀄": { x: 2, y: 4 },
  "🀅": { x: 1, y: 4 },
  "🀆": { x: 0, y: 4 },
};
const characters = {
  "🀇": { x: 0, y: 0 },
  "🀈": { x: 1, y: 0 },
  "🀉": { x: 2, y: 0 },
  "🀊": { x: 3, y: 0 },
  "🀋": { x: 4, y: 0 },
  "🀌": { x: 5, y: 0 },
  "🀍": { x: 6, y: 0 },
  "🀎": { x: 7, y: 0 },
  "🀏": { x: 8, y: 0 },
};
const bamboos = {
  "🀐": { x: 0, y: 1 },
  "🀑": { x: 1, y: 1 },
  "🀒": { x: 2, y: 1 },
  "🀓": { x: 3, y: 1 },
  "🀔": { x: 4, y: 1 },
  "🀕": { x: 5, y: 1 },
  "🀖": { x: 6, y: 1 },
  "🀗": { x: 7, y: 1 },
  "🀘": { x: 8, y: 1 },
};
const dots = {
  "🀙": { x: 0, y: 2 },
  "🀚": { x: 1, y: 2 },
  "🀛": { x: 2, y: 2 },
  "🀜": { x: 3, y: 2 },
  "🀝": { x: 4, y: 2 },
  "🀞": { x: 5, y: 2 },
  "🀟": { x: 6, y: 2 },
  "🀠": { x: 7, y: 2 },
  "🀡": { x: 8, y: 2 },
};
const flowers = {
  "🀢": { x: 0, y: 3 },
  "🀣": { x: 1, y: 3 },
  "🀤": { x: 2, y: 3 },
  "🀥": { x: 3, y: 3 },
};
const seasons = {
  "🀦": { x: 3, y: 4 },
  "🀧": { x: 4, y: 4 },
  "🀨": { x: 5, y: 4 },
  "🀩": { x: 6, y: 4 },
};

export const tileCodePositions: { [code: string]: { x: number; y: number } } = {
  ...winds,
  ...dragons,
  ...characters,
  ...bamboos,
  ...dots,
  ...flowers,
  ...seasons,
};

export const tileWidth = 67;
export const tileHeight = 87;

const imageWidth = 768;
const imageHeight = 580;
const yAdjustment = 117;

const getPos = (code: string) => tileCodePositions[code];

export const getImageX = (code: string, ratio: number = 1) =>
  getPos(code).x * ratio * (2 + tileWidth) + ratio;

export const getImageY = (code: string, ratio: number = 1) =>
  (getPos(code).y * (2 + tileHeight) + yAdjustment) * ratio;

export const getImageWidth = (ratio: number = 1) => tileWidth * ratio;

export const getImageHeight = (ratio: number = 1) => tileHeight * ratio;

export const getBackgroundWidth = (ratio: number = 1) => imageWidth * ratio;

export const getBackgroundHeight = (ratio: number = 1) => imageHeight * ratio;

export const windCodes = new Map([
  [Wind.East, "🀀"],
  [Wind.South, "🀁"],
  [Wind.West, "🀂"],
  [Wind.North, "🀃"],
]);

export const nextWind = new Map([
  [Wind.East, Wind.South],
  [Wind.South, Wind.West],
  [Wind.West, Wind.North],
  [Wind.North, Wind.East],
]);
