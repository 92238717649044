import React from "react";
import { FormattedMessage } from "react-intl";
import { GetStatisticsResponse } from "../../api/mahjong-api";
import { Info } from "./Home.styled";

type Props = {
  statistics?: GetStatisticsResponse;
}

const Statistics = ({ statistics }: Props) => (
  <>
    <Info>
      <FormattedMessage
        id="TotalGamesPlayed"
        values={{ count: statistics?.games }}
        />
    </Info>
    <Info>
      <FormattedMessage
        id="TotalActivePlayers"
        values={{ count: statistics?.players }}
        />
    </Info>
  </>
);

export default Statistics;