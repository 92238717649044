import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import { selectPlayerId, selectTurnTime } from "../auth/selector";
import MatchOfTheDay from "./MatchOfTheDay";
import { useGetMatchTemplateQuery, usePlayTemplateMutation } from "./service";

const Container = () => {
  const routeMatch = useMatch("/motd/:year/:month/:day");
  const routeYear = routeMatch?.params.year;
  const routeMonth = routeMatch?.params.month;
  const routeDay = routeMatch?.params.day;

  const date =
    routeYear && routeMonth && routeDay
      ? new Date(
          parseInt(routeYear),
          parseInt(routeMonth) - 1,
          parseInt(routeDay),
          0,
          0,
          0,
          0
        )
      : new Date();
  const navigate = useNavigate();

  const playerId = useSelector(selectPlayerId);
  const turnTime = useSelector(selectTurnTime);
  const motdRequest = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };

  const { data, isFetching } = useGetMatchTemplateQuery(motdRequest, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const [playMatch, playStatus] = usePlayTemplateMutation();

  useEffect(() => {
    if (playStatus.isSuccess) {
      navigate(`/game/${playStatus.data.id}`);
    }
  }, [playStatus.isSuccess, navigate, playStatus?.data?.id]);

  if (isFetching) {
    return <Loader />;
  }
  return (
    <MatchOfTheDay
      seat={data?.template?.seat}
      round={data?.template?.round}
      date={date}
      play={() =>
        playMatch({
          playerId: playerId,
          matchTemplateId: data?.id,
          turnTime: turnTime,
        })
      }
      results={data?.results || []}
      prevResults={data?.previousResults || []}
    />
  );
};

export default Container;
