import React, { useState } from "react";
import * as THREE from "three";

import { RoundedBox } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { tilePaths } from "./lookup";
import { useTheme } from "styled-components";
export type Props = {
  code: string;
  x: number;
  scale?: number;
  isActive?: boolean;
};

function BaseTile({ x: start = 0, isActive = false, code, scale = 1 }: Props) {
  const [hovered, hover] = useState(false);
  const texture = useLoader(THREE.TextureLoader, tilePaths[code]);
  const front = new THREE.Vector3(start, 0, -(scale * 0.275));
  const back = new THREE.Vector3(start, 0, -(scale * 0.45));
  const img = new THREE.Vector3(start + 0.025, 0, scale * 0.001);
  texture.minFilter = THREE.LinearFilter;

  const theme = useTheme();
  return (
    <>
      <RoundedBox
        radius={0.1}
        smoothness={10}
        position={front}
        args={[1.2, 1.6, 0.4]}
        scale={scale}
        onPointerOver={(event) => hover(true)}
        onPointerOut={(event) => hover(false)}
      >
        <meshPhysicalMaterial
          attachArray="material"
          color={hovered && isActive ? theme.primary : "white"}
        />
        <meshPhysicalMaterial
          attachArray="material"
          color={hovered && isActive ? theme.primary : "white"}
        />
      </RoundedBox>
      <RoundedBox
        radius={0.1}
        smoothness={10}
        position={back}
        args={[1.2, 1.6, 0.3]}
        scale={scale}
        onPointerOver={(event) => hover(true)}
        onPointerOut={(event) => hover(false)}
      >
        <meshPhysicalMaterial
          flatShading
          attachArray="material"
          color={theme.primary}
          clearcoat={1}
          metalness={0.5}
          reflectivity={1}
          clearcoatRoughness={0}
        />
        <meshPhysicalMaterial
          flatShading
          attachArray="material"
          color={theme.primary}
          clearcoat={1}
          metalness={0.5}
          reflectivity={1}
          clearcoatRoughness={0}
        />
      </RoundedBox>
      <mesh position={img} scale={scale}>
        <planeBufferGeometry attach="geometry" args={[1, 1.4]} />
        <meshStandardMaterial
          attach="material"
          side={THREE.DoubleSide}
          map={texture}
          toneMapped={false}
          transparent={true}
        />
      </mesh>
    </>
  );
}

export default BaseTile;
