import styled from "styled-components";

export const Board = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 0;
`;

export const SideBoard = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 0;
  padding-bottom: 1rem;
`;

export const Row = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 0;
`;

export const Pad = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 120px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
  color: #00291f;
  letter-spacing: 1.25px;
  border-radius: 4px;
  border: none;
`;
