import React, { useEffect, useState } from "react";
import Discards from "./hand/Discards";
import Ruler from "./hand/Ruler";
import Flowers from "./hand/Flowers";
import PlayerStatus from "./PlayerStatus";
import Wall from "./hand/Wall";
import { getLastDiscard, getParts } from "../frame";
import Melded from "./hand/Melded";
import Concealed from "./hand/Concealed";
import Discard from "./hand/Discard";
import { SeatProps as BaseSeatProps } from "./OpponentSeat";
import Timer from "./Timer";
import Drawn from "./hand/Drawn";

export type SeatProps = BaseSeatProps &
  JSX.IntrinsicElements["mesh"] & {
    turnTime?: number;
    isActive?: boolean;
    showTimer?: boolean;
    sequence?: number;
    onConcealedClick?: (tileId: number) => void;
  };

const PlayerSeat = ({
  turnTime = 10,
  sequence = 1,
  seat,
  ratio = 1,
  rotation,
  position,
  allDiscards = [],
  isDrawRequest = false,
  isDiscardRequest = false,
  isOpen = false,
  player,
  wallCount,
  isActive = false,
  showTimer = false,
  points = 0,
  onConcealedClick,
}: SeatProps) => {
  const { wind, flowers, concealed, melded, drawn } = getParts(seat);

  const discards = allDiscards.filter(
    (d) => d.discardPlayerId === seat?.clientId
  );
  const lastDiscard = getLastDiscard(discards);
  const meldedCount = melded.reduce((a, b) => a + b.tiles.length, 0);
  const totalCount = concealed.length + meldedCount;
  const half = Math.ceil(totalCount / 2);
  const shownDiscards = discards.filter(
    (d) =>
      !(isDiscardRequest && d.tile?.id === lastDiscard?.tile?.id) &&
      !d.claimedBy
  );
  const start = -(half + 2.5);

  const [dragId, setDragId] = useState(-1);

  useEffect(() => setDragId(-1), [isActive]);
  return (
    <group rotation={rotation} position={position}>
      <Wall count={wallCount} />
      <Discards discards={shownDiscards} />
      <Ruler />
      {showTimer && (
        <Timer
          turnTime={turnTime}
          key={`timer${sequence}`}
          message={isActive ? "DragToDiscard" : ""}
        />
      )}
      <PlayerStatus
        image={player?.imageUrl || ""}
        name={player?.name || ""}
        wind={wind}
        points={points}
        isActive={isActive}
      />
      <Flowers flowers={flowers} ratio={1.1} zPos={-3} />
      <Melded melded={melded} x={start} ratio={ratio} />
      <Concealed
        dragId={dragId}
        setDragId={setDragId}
        isActive={isActive}
        concealed={concealed}
        x={start + meldedCount}
        ratio={ratio}
        onConcealedClick={onConcealedClick}
      />
      {isDrawRequest && drawn && (
        <Drawn
          dragId={dragId}
          setDragId={setDragId}
          isActive={isActive}
          x={start + totalCount + 4.5 * ratio}
          code={drawn.code}
          key={`drawn${drawn.id}`}
          scale={ratio}
          onClick={() => onConcealedClick && onConcealedClick(drawn.id)}
        />
      )}
      <Discard
        isDiscardRequest={isDiscardRequest}
        lastDiscard={lastDiscard?.tile}
        key={`discard${lastDiscard?.tile?.id}`}
        ratio={ratio}
        x={start + totalCount + 5}
      />
    </group>
  );
};
export default PlayerSeat;
