import React from "react";
import { RoundedBox } from "@react-three/drei";
import { Color } from "three";
const brown = new Color(0x362419);
const width = 180;
const BackWall = () => {
  return (
    <>
      <RoundedBox
        radius={0.5}
        smoothness={10}
        args={[width, 2, 2]}
        position={[0, -19, -50]}
      >
        <meshStandardMaterial attachArray="material" color={brown} />
        <meshStandardMaterial attachArray="material" color={brown} />
      </RoundedBox>
      <RoundedBox
        radius={0.5}
        smoothness={10}
        args={[width, 2, 2]}
        position={[0, -15, -50]}
      >
        <meshStandardMaterial attachArray="material" color={brown} />
        <meshStandardMaterial attachArray="material" color={brown} />
      </RoundedBox>
      <RoundedBox
        radius={0.5}
        smoothness={10}
        args={[width, 2, 2]}
        position={[0, -11, -50]}
      >
        <meshStandardMaterial attachArray="material" color={brown} />
        <meshStandardMaterial attachArray="material" color={brown} />
      </RoundedBox>
    </>
  );
};

export default BackWall;
