import styled from "styled-components";

export const Right = styled.div`
  transform: rotate(-90deg);
`;

export const Left = styled.div`
  transform: rotate(90deg);
`;

export const Opposite = styled.div`
  transform: rotate(180deg);
`;

export const Frame = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 0rem;
  font-size: 0.9rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 2rem;
  user-select: none;
`;

export const TimerFrame = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  border-radius: 3rem;
  padding: 0.5rem;
  min-width: 4rem;
  white-space: nowrap;
  user-select: none;
`;

export const Urgent = styled.div`
  color: ${({ theme }) => theme.primary};
  margin-right: 1rem;
`;

export const Column = styled.div`
  flex-direction: column;
  flex-grow: 1;
  font-size: 2.5rem;
  text-transform: uppercase;
`;
