import React from "react";
import { GameResult } from "../../api/mahjong-api";
import {
  Container,
  Table,
  Tr,
  Td,
  THead,
} from "../../components/common/List.styled";
import { useTable, CellProps } from "react-table";
import { FormattedMessage } from "react-intl";
import Avatar from "../game/Avatar";

type Props = {
  gameResults?: GameResult[];
  viewPlayer?: (id: number) => void;
};

export const GameResults = ({
  gameResults = [],
  viewPlayer = () => {},
}: Props) => {
  const data = React.useMemo(() => gameResults, [gameResults]);
  const columns = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="Player" />,
        id: "player",
        Cell: (r: CellProps<GameResult>) => (
          <Avatar ratio={0.5} image={r.row.original.player?.imageUrl || ""} />
        ),
      },
      {
        id: "name",
        Cell: (r: CellProps<GameResult>) => r.row.original.player?.name || "",
      },
      {
        Header: <FormattedMessage id="Match" />,
        id: "match",
        Cell: (r: CellProps<GameResult>) => r.row.original.matchId || "",
      },
      {
        Header: <FormattedMessage id="Points" />,
        id: "points",
        Cell: (r: CellProps<GameResult>) => r.row.original.points || "",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <Container>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </THead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                onClick={() => viewPlayer(row.original?.player?.id || 0)}
              >
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};
export default GameResults;
