import styled from "styled-components";

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  height: 100%;
`;
export const Table = styled.table`
  margin-top: 5px;
  width: 100%;
`;

export const Tr = styled.tr``;
export const Th = styled.th`
  padding: 0.5rem;
`;
export const Td = styled.td`
  height: 3rem;
`;
export const THead = styled.thead`
  background-color: rgba(255, 255, 255, 0.5);
  height: 2rem;
  color: #282828;
`;

export const TBody = styled.tbody``;
