import React from "react";
import "react-sliding-pane/dist/react-sliding-pane.css";
import List from "./List";
import Create from "./Create";
import View from "./View";
import { ActionButton as Button } from "../../components/common/Buttons.styled";
import { Description, Module } from "../../components/common/Page.styled";
import { FormattedMessage } from "react-intl";
import { Pane } from "../../components/common/Sliding.styled";
import { CreateMatchRequest } from "../../api/mahjong-api";
import { Props as ViewProps } from "./View";
import { Props as CreateProps } from "./Create";
import { Props as ListProps } from "./List";
import { PlayerMap } from "../game/Game";
import {
  ActionList,
  Main,
  MatchList,
  Section,
  ActionSection,
  SectionBody,
  SectionName,
} from "./Lobby.styled";
import { NavigateFunction, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import MotdButton from "../../components/common/buttons/MotdButton";

type Props = ViewProps &
  CreateProps &
  ListProps & {
    isOpen?: boolean;
    isView?: boolean;
    matchPlayers?: PlayerMap;
    navigate?: NavigateFunction;
    playVsBots?: () => void;
  };
const Lobby = ({
  isOpen = false,
  isView = false,
  canJoin = false,
  canPlay = false,
  canStart = false,
  matches = [],
  match = { id: 0, createdAt: "" },
  matchPlayers = {},
  navigate = () => {},
  createMatch = (req: CreateMatchRequest) => {},
  deleteMatch = (id: number) => {},
  viewMatch = (id: number) => {},
  joinMatch = () => {},
  startMatch = () => {},
  playMatch = () => {},
  playVsBots = () => {},
}: Props) => {
  return (
    <Module>
      <Helmet>
        <title>Mahjong Spot - Lobby</title>
        <meta name="description" content="Play against the bots or your friends" />
      </Helmet>

      <Pane
        isOpen={isOpen}
        title={
          <FormattedMessage
            id={isView ? "MatchId" : "NewMatch"}
            values={{ id: match.id }}
          />
        }
        onRequestClose={() => {
          navigate("/match/");
        }}
      >
        {isView ? (
          <View
            joinMatch={joinMatch}
            startMatch={startMatch}
            playMatch={playMatch}
            playerMap={matchPlayers}
            canJoin={canJoin}
            canPlay={canPlay}
            canStart={canStart}
            match={match}
          />
        ) : (
          <Create createMatch={createMatch} />
        )}
      </Pane>
      <Main>
        <ActionList>
          <ActionSection>
            <SectionName>
              <FormattedMessage id="SinglePlayer" />
            </SectionName>
            <SectionBody>
              <Description>
                <FormattedMessage id="SinglePlayerDetails" />
              </Description>
              <Button onClick={() => playVsBots()}>
                <FormattedMessage id="PlayVsBots" />
              </Button>
            </SectionBody>
          </ActionSection>
          <ActionSection>
            <SectionName>
              <FormattedMessage id="MatchOfTheDay" />
            </SectionName>
            <SectionBody>
              <Description>
                <FormattedMessage id="MatchOfTheDayDescription" />
              </Description>
              <NavLink to="/motd">
                <MotdButton />
              </NavLink>
            </SectionBody>
          </ActionSection>
          <ActionSection>
            <SectionName>
              <FormattedMessage id="MultiPlayer" />
            </SectionName>
            <SectionBody>
              <Description>
                <FormattedMessage id="MultiPlayerDetails" />
              </Description>
              <Button onClick={() => navigate("create")}>
                <FormattedMessage id="NewMatch" />
              </Button>
            </SectionBody>
          </ActionSection>
        </ActionList>
        <MatchList>
          <Section>
            <SectionName>
              <FormattedMessage id="Matches" />
            </SectionName>
            <List
              matches={matches}
              deleteMatch={deleteMatch}
              viewMatch={viewMatch}
            />
          </Section>
        </MatchList>
      </Main>
    </Module>
  );
};

export default Lobby;
