import styled from "styled-components";

export const Frame = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #e3f2fd;
`;

export const Module = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.heading};
  background-color: rgba(0, 0, 0, 0.4);
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Backdrop = styled.div`
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
  border-radius: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #282828;
`;

export const Header = styled.div`
  font-family: ${({ theme }) => theme.title};
  color: ${({ theme }) => theme.primary};
  font-size: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.25rem;
  width: 100%;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.title};
  color: ${({ theme }) => theme.primary};
  font-size: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

export const FixedPanel = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  margin: 0.2rem;
`;

export const MainPanel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  margin: 0.2rem;
`;

export const PanelContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const PanelFooter = styled.div`
  padding-top: 1rem;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Item = styled.div<{ selected?: boolean }>`
  margin-top: 0.3rem;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  padding: 0.2rem;
  margin-left: 0.1rem;
  margin-right: 0.1ren;
  min-width: 4rem;
  cursor: pointer;
  color: ${(props) =>
    props.selected ? props.theme.primary : props.theme.light};
`;

export const WideItem = styled.div<{ selected?: boolean }>`
  margin-top: 0.3rem;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 0.1rem;
  margin-right: 0.1ren;
  min-width: 4rem;
  cursor: pointer;
  color: ${(props) =>
    props.selected ? props.theme.primary : props.theme.light};
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.text};
  text-transform: none;
  font-weight: 100;
  padding: 1rem;
`;

export const PageDescription = styled(Description)`
  text-align: left;
  line-height: 1.4;
`;
