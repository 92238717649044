import React from "react";
import * as THREE from "three";
import { Tile } from "../../../../api/mahjong-api";
import GestureTile from "../../../../components/three/GestureTile";

type Props = JSX.IntrinsicElements["mesh"] & {
  flowers: Tile[];
  ratio?: number;
  zPos?: number;
};

const Flowers = ({ flowers = [], ratio = 1, zPos = -2.5 }: Props) => {
  let count = flowers.length || 0;
  const half = Math.floor(count / 2);
  return (
    <>
      <group position={new THREE.Vector3(-count - 14, -0.35, zPos)}>
        {flowers?.map((c, i) => (
          <GestureTile
            x={(count++ - half) * 1.2 * ratio}
            code={c?.code || ""}
            key={`${c}${i}`}
            scale={ratio}
            rotateX={-Math.PI / 2}
          />
        ))}
      </group>
    </>
  );
};
export default Flowers;
