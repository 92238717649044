import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { calculationApi } from "../features/scoring/service";
import { endpoints, matchApi } from "../features/match/service";
import { homeApi } from "../features/home/service";
import { profileApi } from "../features/profile/service";
import { communityApi } from "../features/community/service";
import { templateApi } from "../features/motd/service";
import gameReducer, { updateGameFrame } from "../features/game/reducer";
import { updateConnectionId } from "../features/auth/reducer";
import clientReducer from "../features/auth/reducer";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  AnyAction,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
  SignalAction,
  SignalDispatch,
  signalMiddleware,
  withCallbacks,
} from "redux-signalr";
import { GameFrame } from "../api/mahjong-api";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type Action<ReturnValue = void> = SignalAction<
  ReturnValue,
  RootState,
  AnyAction
>;

export type Dispatch<Action extends AnyAction = AnyAction> = SignalDispatch<
  RootState,
  Action
>;

const apiUrl = window._env_.API_URL;
const connection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl(`${apiUrl}/gameHub`, {
    transport: HttpTransportType.WebSockets,
  })
  .withAutomaticReconnect()
  .build();

const callbacks = withCallbacks<Dispatch, RootState>()
  .add("ReceiveMessage", (user: string, msg: string) => (dispatch) => {
    const frame: GameFrame = JSON.parse(msg);
    dispatch(updateGameFrame(frame));
  })
  .add("UpdateConnectionId", (connectionId: string) => (dispatch) => {
    dispatch(updateConnectionId(connectionId));
  })
  .add("UpdateLobby", () => (dispatch) => {
    const { refetch } = dispatch(endpoints.findMatches.initiate(undefined));
    refetch();
  });

export const signal = signalMiddleware({
  callbacks,
  connection,
  shouldConnectionStartImmediately: false,
});

connection
  .start()
  .then(() =>
    console.log(`Connection started with id ${connection.connectionId}`)
  )
  .catch((err) => console.error(err.toString()));

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    matchApi.reducerPath,
    calculationApi.reducerPath,
    homeApi.reducerPath,
    communityApi.reducerPath,
    templateApi.reducerPath,
    "game",
  ],
};

const gamePersistConfig = {
  key: "game",
  storage: storage,
  blacklist: ["previousFrames", "bufferFrames"],
};

const rootReducer = combineReducers({
  [homeApi.reducerPath]: homeApi.reducer,
  [calculationApi.reducerPath]: calculationApi.reducer,
  [matchApi.reducerPath]: matchApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [communityApi.reducerPath]: communityApi.reducer,
  [templateApi.reducerPath]: templateApi.reducer,
  game: persistReducer(gamePersistConfig, gameReducer),
  client: clientReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(homeApi.middleware)
      .concat(calculationApi.middleware)
      .concat(matchApi.middleware)
      .concat(profileApi.middleware)
      .concat(communityApi.middleware)
      .concat(templateApi.middleware)
      .concat(signal),
});

setupListeners(store.dispatch);
