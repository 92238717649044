import React from "react";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useMatch, useNavigate } from "react-router-dom";
import Scoring from "./Scoring";
import {
  useCreateCalculationMutation,
  useDeleteCalculationMutation,
  useGetAllCalculationsQuery,
  useGetAllRulesQuery,
  useGetAllTilesQuery,
  useGetCalculationByIdQuery,
  useUpdateCalculationMutation,
} from "./service";
import Loader from "../../components/common/Loader";
import {
  CreateCalculationRequest,
  UpdateCalculationRequest,
} from "../../api/mahjong-api";

const Container = () => {
  const navigate = useNavigate();
  const match = useMatch("/scoring/examples/edit/:calculationId");
  const editId = +(match?.params.calculationId || "");
  const { isLoading: isFindLoading, data: findResponse } =
    useGetAllCalculationsQuery();
  const [deleteSample, deleteStatus] = useDeleteCalculationMutation();
  const [updateSample, updateStatus] = useUpdateCalculationMutation();
  const [createSample, createStatus] = useCreateCalculationMutation();
  const { isLoading: isTileLoading, data: tiles } = useGetAllTilesQuery();
  const { isLoading: isRulesLoading, data: rulesResponse } =
    useGetAllRulesQuery();
  const { isLoading: isGetLoading, data: getResponse } =
    useGetCalculationByIdQuery(editId, { skip: !editId });

  const handleCreate = async (payload: CreateCalculationRequest) => {
    const response = await createSample(payload).unwrap();
    navigate(`/scoring/examples/edit/${response.id}`);
  };

  const handleUpdate = async (payload: UpdateCalculationRequest) => {
    await updateSample(payload);
    navigate("/scoring/examples");
  };
  if (
    createStatus.isLoading ||
    updateStatus.isLoading ||
    deleteStatus.isLoading ||
    isFindLoading ||
    isTileLoading ||
    isRulesLoading ||
    isGetLoading
  ) {
    return <Loader />;
  }

  const requests = findResponse?.calculations || [];

  return tiles ? (
    <Scoring
      navigate={navigate}
      tiles={tiles}
      rules={rulesResponse}
      data={getResponse}
      callToAction={(data) => handleCreate({ ...data })}
      update={(data) => handleUpdate({ ...data, id: +editId })}
      deleteSample={(id) => deleteSample(id)}
      editSample={(id: number) => navigate(`examples/edit/${id}`)}
      examples={requests}
    />
  ) : null;
};

export default Container;
