import styled from "styled-components";
import GoogleImg from "../login/icon_mobile_google_login.png";

export const NavBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 0.25rem;
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const TitleText = styled.div`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.title};
  font-size: 1.3rem;
  color: ${({ theme }) => theme.primary};;
  padding: 0.75rem;
`;

export const TitlePlayer = styled.div`
  color: ${({ theme }) => theme.primary};;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.5rem;
`;

export const SocialButton = styled.button`
  width: 2.2rem;
  height: 2.2rem;
  background-color: transparent;
  border: none;
  background-image: url(${GoogleImg});
  background-size: cover;
`;

export const NavItem = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 0.25rem;
  color: ${({ theme }) => theme.primary};;
  cursor: pointer;
  margin: 0.2rem;
  border-bottom: ${({ active, theme }) =>
    active ? `1px solid ${theme.primary}` : "1px solid transparent"};
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.primary};;
  }
`;

export const Email = styled.div`
  text-overflow: ellipsis;
  font-size: 0.5rem;
  cursor: pointer;
  text-decoration: underline;
`;
