import React, { useMemo } from "react";
import { Table, Tr, Td, THead } from "../../../components/common/List.styled";
import { useTable, CellProps } from "react-table";
import { Frame } from "./GameOver.styled";
import { GameResult } from "../../../api/mahjong-api";
import { FormattedMessage } from "react-intl";
import { Label, Results, TdPlayer } from "./Display.styled";
import { Button } from "./GameOver.styled";
import { Row } from "../../../components/common/Page.styled";
import Avatar from "../Avatar";
import { PlayerMap } from "../Game";

type Props = {
  playerMap: PlayerMap;
  matchResults?: GameResult[];
  hideHistory?: () => void;
};

type HistoryRow = {
  clientId: string;
  results: GameResult[];
};

const MatchHistory = ({
  playerMap = {},
  matchResults = [],
  hideHistory = () => {},
}: Props) => {
  const init: { [key: string]: GameResult[] } = {};
  const summary = matchResults.reduce((s, c) => {
    const cId: string = c.clientId || "";
    if (cId in s) {
      s[cId].push(c);
    } else {
      s[cId] = [c];
    }
    return s;
  }, init);
  const history: HistoryRow[] = Object.keys(summary).map((s) => {
    return { clientId: s, results: summary[s] };
  });

  const data = useMemo(() => history, [history]);
  const numGameCols = matchResults.length / 4;
  const cols = useMemo(
    () => [
      {
        Header: <FormattedMessage id="Player" />,
        id: "player",
        Cell: (r: CellProps<HistoryRow>) => (
          <Avatar
            ratio={0.5}
            image={playerMap[r.row.original.clientId]?.imageUrl || ""}
          />
        ),
      },
    ],
    [playerMap]
  );
  [...Array(numGameCols)].map((_, i) =>
    cols.push({
      Header: <><FormattedMessage id="Game" /> {(i + 1).toString()}</>,
      id: `game${i}`,
      Cell: (r: CellProps<HistoryRow>) => (
        <>{r.row.original.results[i].points?.toString() || ""}</>
      ),
    })
  );
  cols.push({
    Header: <FormattedMessage id="Score" />,
    id: "score",
    Cell: (r: CellProps<HistoryRow>) => (
      <>
        {r.row.original.results
          .reduce(
            (accumulator, currentValue) =>
              accumulator + (currentValue?.points || 0),
            0
          )
          .toString()}
      </>
    ),
  });
  const columns = React.useMemo(() => cols, [cols]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <Frame>
      <Label><FormattedMessage id="MatchResults" /></Label>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </THead>
        <Results {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return i === 0 ? (
                    <TdPlayer {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TdPlayer>
                  ) : (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </Results>
      </Table>
      <Row>
        <Button onClick={() => hideHistory()}>
        <FormattedMessage id="Close" />
        </Button>
      </Row>
    </Frame>
  );
};

export default MatchHistory;
