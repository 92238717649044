import React from "react";
import { Tile } from "../../../../api/mahjong-api";
import { TileButton } from "../../../../components/game/TileButton";
import { SectionProps } from "../Hand";
import { Section } from "../constants";
import { Tray } from "./Tray";

type TileProps = SectionProps & {
  tile?: Tile;
};

const OutOn = ({
  tile,
  onClick = () => {},
  onTrayClick = () => {},
  isActive,
}: TileProps) => {
  if (!tile) {
    return (
      <Tray
        single={true}
        name="TrayOutOn"
        onClick={() => onTrayClick(Section.OutOn)}
        isActive={isActive}
      />
    );
  }
  return (
    <Tray
      single={true}
      name="TrayOutOn"
      onClick={() => onTrayClick(Section.OutOn)}
      isActive={isActive}
    >
      <TileButton
        ratio={0.4}
        code={tile.code}
        onClick={() => onClick(tile.id)}
      />
    </Tray>
  );
};

export default OutOn;
