import styled from "styled-components";
import { Image } from "../../components/common/Image.styled";

export const Frame = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.heading};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  flex-wrap: wrap;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const TitleText = styled.div`
  text-transform: uppercase;
  width: 15rem;
  font-family: ${({ theme }) => theme.title};
  font-size: 2.6rem;
  color: ${({ theme }) => theme.primary};
`;

export const Info = styled.div`
  font-size: 0.75rem;
`;

export const Scene = styled.div`
  height: 200px;
  canvas {
    touch-action: none;
  }
`;

export const SupportedImage = styled(Image)`
  margin: 0.5rem;
`;
