import styled from "styled-components";

export const ViewPort = styled.div<{ visible?: boolean }>`
  background-color: transparent;
  width: 100vw;
  height: 50vw;
  margin-left: 0px;
  margin-right: 0px;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-direction: column;
  z-index: 90;
  user-select: none;
  canvas {
    touch-action: none;
  }
`;

export const TitleText = styled.div`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.title};
  font-size: 3rem;
  color: black;
`;

export const LoadingText = styled.div`
  white-space: nowrap;
`;

export const Wrapper = styled.div<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
