import React from "react";

import { useSpring, useChain, useSpringRef } from "@react-spring/three";
import GestureTile from "../../../../components/three/GestureTile";
import { useSelector } from "react-redux";
import { selectDiscardStyle } from "../../../auth/selector";
import TapTile from "../../../../components/three/TapTile";
import { DiscardStyle } from "../../../profile/DiscardStyle";
export type Props = {
  isActive?: boolean;
  dragId?: number;
  setDragId?: (id: number) => void;
  code: string;
  x: number;
  scale?: number;
  onClick?: () => void;
};

const Tile = ({
  isActive = false,
  x = 0,
  code,
  scale = 1,
  onClick,
  dragId = -1,
  setDragId = () => {},
}: Props) => {
  const springRef = useSpringRef();
  const transitionRef = useSpringRef();

  const { positionZ } = useSpring({
    from: { positionZ: -5 },
    to: { positionZ: 0 },
    ref: springRef,
  });
  const { rotateX } = useSpring({
    from: { rotateX: Math.PI / 2 },
    to: { rotateX: 0 },
    ref: transitionRef,
  });

  const discardStyle = useSelector(selectDiscardStyle);
  useChain([springRef, transitionRef]);

  if (discardStyle === DiscardStyle.Tap) {
    return (
      <TapTile
        tapped={dragId === 0}
        startTap={() => setDragId(0)}
        isActive={isActive}
        rotateX={rotateX}
        positionZ={positionZ}
        x={x}
        code={code}
        scale={scale}
        onClick={onClick}
      />
    );
  } else {
    return (
      <GestureTile
        allowDrag={dragId === -1 || dragId === 0}
        startDrag={() => setDragId(0)}
        endDrag={() => setDragId(-1)}
        isActive={isActive}
        rotateX={rotateX}
        positionZ={positionZ}
        x={x}
        code={code}
        scale={scale}
        onClick={onClick}
      />
    );
  }
};

export default Tile;
