import React from "react";
import { Tile } from "../../../../api/mahjong-api";
import { TileButton } from "../../../../components/game/TileButton";
import { SectionProps } from "../Hand";
import { Section } from "../constants";
import { Tray } from "./Tray";

type TilesProp = SectionProps & {
  tiles: Tile[];
  flowers: Tile[];
};

const Flowers = ({ onClick, isActive, tiles, flowers }: TilesProp) => (
  <Tray name={Section.Flowers} isActive={isActive}>
    {flowers.map((t, i) => (
      <TileButton
        ratio={0.4}
        key={t.id}
        code={t.code}
        disabled={!tiles.map((d) => d.code).includes(t.code)}
        onClick={() => onClick(t.id)}
      />
    ))}
  </Tray>
);

export default Flowers;
