import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 50px;
  display: flex;
  border-style: solid;
  border-color: ${({ theme }) => theme.primary};
  border-radius: 3rem;
  border-width: 0.5rem;
  justify-content: space-evenly;
  cursor: pointer;
  user-select: none;
`;

export const Start = styled.div`
  padding: 1rem;
  font-size: 1.5rem;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  &:active {
    font-size: 1.75rem;
  }
`;

export const Button = styled.button`
  font-family: ${({ theme }) => theme.heading};
  text-transform: uppercase;
  font-size: 1.8rem;
  white-space: nowrap;
  padding: 1rem;
  color: ${({ theme }) => theme.primary};
  background-color: rgba(0, 0, 0, 0);
  letter-spacing: 1.25px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:hover {
    color: white;
  }
  &:active {
    transform: translateY(0.4rem);
  }
`;
