import React, { useEffect } from "react";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import Lobby from "./Lobby";
import {
  useCreateMatchMutation,
  useDeleteMatchMutation,
  useFindMatchesQuery,
  useGetMatchQuery,
  useJoinMatchMutation,
  usePlayMatchMutation,
  useStartMatchMutation,
} from "./service";
import Loader from "../../components/common/Loader";
import { useSelector } from "react-redux";
import {
  selectClientId,
  selectConnectionId,
  selectPlayerId,
  selectTurnTime,
} from "../auth/selector";
import { CreateMatchRequest, Match, MatchStatus } from "../../api/mahjong-api";
import { PlayerMap } from "../game/Game";

const Container = () => {
  const clientId = useSelector(selectClientId);
  const connectionId = useSelector(selectConnectionId);
  const playerId = useSelector(selectPlayerId);
  const turnTime = useSelector(selectTurnTime);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isCreate = pathname.includes("create");
  const isView = pathname.includes("view");

  const isOpen = isCreate || isView;
  const [playMatch, playStatus] = usePlayMatchMutation();
  const [createMatch, createStatus] = useCreateMatchMutation();
  const { isLoading: isFindLoading, data } = useFindMatchesQuery(playerId, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const [deleteMatch] = useDeleteMatchMutation();
  const matches = data?.matches || [];
  const routeMatch = useMatch("/match/view/:matchId");
  const matchId = +(routeMatch?.params.matchId || "");

  const [joinMatch] = useJoinMatchMutation();
  const [startMatch] = useStartMatchMutation();

  useEffect(() => {
    if (playStatus.isSuccess) {
      navigate(`/game/${playStatus.data.id}`);
    }
  }, [playStatus.isSuccess, navigate, playStatus?.data?.id]);

  const { data: getResponse, isLoading: isGetLoading } = useGetMatchQuery(
    {
      id: matchId,
      connectionId,
    },
    { skip: !matchId, refetchOnMountOrArgChange: true }
  );

  const init: PlayerMap = {};
  const playerMap = getResponse?.match?.clients?.reduce((s, c) => {
    if (c.player) {
      s[c.clientId] = c.player;
    }
    return s;
  }, init);
  let match: Match = {
    id: 0,
    createdAt: "",
    status: MatchStatus.Waiting,
    createdBy: "",
  };

  if (getResponse?.match) {
    match = getResponse.match;
  }
  if (createStatus.isLoading || isFindLoading || isGetLoading) {
    return <Loader />;
  }

  const handleCreateMatch = async (payload: CreateMatchRequest) => {
    const response = await createMatch({ ...payload, playerId }).unwrap();
    navigate(`/match/view/${response.id}`);
  };

  const handleJoinMatch = async () => {
    await joinMatch({ matchId, playerId });
    navigate(`/game/${matchId}`);
  };
  const handleStartMatch = async () => {
    await startMatch({ matchId });
    navigate(`/game/${matchId}`);
  };

  const canStart =
    match?.createdBy === clientId && match.status === MatchStatus.Waiting;

  const canJoin =
    match?.createdBy !== clientId && match?.status === MatchStatus.Waiting;

  const canPlay =
    match?.status === MatchStatus.Started &&
    match?.clients?.map((c) => c.clientId).includes(clientId);

  return (
    <Lobby
      match={match}
      matchPlayers={playerMap}
      canJoin={canJoin}
      canPlay={canPlay}
      canStart={canStart}
      isOpen={isOpen}
      isView={isView}
      matches={matches}
      navigate={navigate}
      createMatch={handleCreateMatch}
      deleteMatch={deleteMatch}
      viewMatch={(id) => navigate(`view/${id}`)}
      playMatch={() => navigate(`/game/${matchId}`)}
      playVsBots={() => playMatch({ playerId: playerId, turnTime: turnTime })}
      joinMatch={() => handleJoinMatch()}
      startMatch={() => handleStartMatch()}
    />
  );
};

export default Container;
