import React from "react";
import { FormattedMessage } from "react-intl";
import { ActionButton as Button } from "../../../components/common/Buttons.styled";
import Avatar from "../Avatar";
import { PlayerMap } from "../Game";
import { Card, Row } from "./MatchOver.styled";
import { Frame } from "./GameOver.styled";
import { MatchResult } from "../frame";
import { Title } from "./Display.styled";
import MotdButton from "../../../components/common/buttons/MotdButton";

export type Props = {
  playerMap: PlayerMap;
  result: MatchResult;
  showHistory?: () => void;
  goToLobby?: () => void;
  goToMotd?: () => void;
};

const MatchOver = ({
  result,
  playerMap = {},
  showHistory = () => {},
  goToLobby,
  goToMotd = () => {},
}: Props) => {
  return (
    <Frame>
      <Title>
        <FormattedMessage id="MatchOver" />
      </Title>
      <Row>
        {Object.keys(result).map((k) => (
          <Card key={k}>
            <Avatar ratio={0.75} image={playerMap[k]?.imageUrl || ""} />
            {result[k]}
          </Card>
        ))}
      </Row>
      <Row>
        <Card>
          <Button onClick={showHistory}>
            <FormattedMessage id="MatchResults" />
          </Button>
          <Button onClick={goToLobby}>
            <FormattedMessage id="GoToLobby" />
          </Button>
          <MotdButton onClick={goToMotd}/>
        </Card>
      </Row>
    </Frame>
  );
};

export default MatchOver;
