import styled from "styled-components";

export const Frame = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  border-radius: 1rem;
  flex-direction: row;
  user-select: none;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.title};
  color: black;
  font-size: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ChangeView = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 50px;
  display: flex;
  border-style: solid;
  border-color: ${({ theme }) => theme.primary};
  border-radius: 3rem;
  border-width: 0.5rem;
  justify-content: space-evenly;
  user-select: none;
`;
