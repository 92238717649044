import styled from "styled-components";

export const Container = styled.div`
  color: white;
  overflow-y: scroll;
  height: 100%;
`;
export const Table = styled.table`
  margin-top: 5px;
  width: 100%;
  border-collapse: collapse;
`;

export const Tr = styled.tr`
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;
export const Td = styled.td`
  height: 3rem;
`;
export const THead = styled.thead`
  height: 2rem;
  font-size: 0.75rem;
  border-bottom: 1px solid white;
`;
