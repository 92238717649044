import React from "react";
import {
  GetRulesResponse,
  GetTilesResponse,
  Rule,
} from "../../../api/mahjong-api";
import { FormattedMessage } from "react-intl";
import {
  ExampleItem,
  Examples,
  RuleBox,
  RuleDescription,
  RuleName,
  RulePoints,
  RuleRow,
  RuleTitle,
} from "./List.styled";
import Hand from "../list/Hand";
import { Container } from "../../../components/common/List.styled";
import {
  Row,
  Col,
  Header,
  Backdrop,
  Panel,
  PageDescription as Description,
} from "../../../components/common/Page.styled";
import { RulesPanel } from "./List.styled";
import Tiles from "./Tiles";
import Melds from "./Melds";
import { NavLink } from "react-router-dom";
import { ActionButton as Button } from "../../../components/common/Buttons.styled";

export type Props = {
  tiles?: GetTilesResponse;
  rules?: GetRulesResponse;
};

export type RuleProps = {
  r: Rule;
};

const RuleDisplay = ({ r }: RuleProps) => (
  <RuleBox key={`${r.pattern}`}>
    <RuleTitle>
      <RuleName>
        <FormattedMessage id={`Rule_${r.pattern}_Name`} />
      </RuleName>
      <RulePoints>
        <FormattedMessage id={`Rule_${r.pattern}_Points`} />
      </RulePoints>
    </RuleTitle>
    <Row>
      <RuleDescription>
        <FormattedMessage id={`Rule_${r.pattern}_Description`} />
      </RuleDescription>
      <Examples>
        {r.examples?.map((ec) => (
          <ExampleItem key={`${r.pattern}-${ec.id}`}>
            <Hand
              ratio={0.25}
              key={`${r.pattern}-${ec.id}`}
              concealed={ec.hand.concealed}
              flowers={ec.hand.flowers}
              melded={ec.hand.melded}
              outOn={ec.outOn}
            />
          </ExampleItem>
        ))}
      </Examples>
    </Row>
  </RuleBox>
);

export const List = ({ rules, tiles }: Props) => {
  const meldRules = rules?.meldRules || [];
  const generalRules = rules?.generalRules || [];
  const specialRules = rules?.specialRules || [];
  return (
    <Container>
      <Backdrop>
        <Header>
          <FormattedMessage id="Rules" />
        </Header>
        <RuleRow>
          <Tiles tiles={tiles} />
          <Melds />
        </RuleRow>
      </Backdrop>
      <Backdrop>
        <RuleRow>
          <Panel>
            Scoring
            <Description>
              A winning hand must be either based on 5 melds and an eye or
              adhere to a special hand pattern.
              <br />
              Points are calculated by matching the winning hand to all the
              applicable rules and summed together.
            </Description>
          </Panel>
          <Panel>
            <Description>Try out scoring with our calculator.</Description>
            <NavLink to="/calculator">
              <Button>
                <FormattedMessage id="Calculator" />
              </Button>
            </NavLink>
          </Panel>
        </RuleRow>
        <RuleRow>
          <Col>
            <RulesPanel>
              Meld Rules
              <Description>
                These are rules based on one or multiple melds which follow a
                pattern. The melds can be concealed or revealed from capturing a
                discarded tile.
              </Description>
            </RulesPanel>
            {meldRules.map((r) => (
              <RuleDisplay key={`rd1${r.pattern}`} r={r} />
            ))}
          </Col>
          <Col>
            <RulesPanel>
              General Rules
              <Description>
                These apply to both meld based or special hands.
              </Description>
            </RulesPanel>
            {generalRules.map((r) => (
              <RuleDisplay key={`rd1${r.pattern}`} r={r} />
            ))}
          </Col>

          <Col>
            <RulesPanel>
              Special Rules
              <Description>
                These are special hands not based on standard melds that must be
                all concealed.
              </Description>
            </RulesPanel>
            {specialRules.map((r) => (
              <RuleDisplay key={`rd2${r.pattern}`} r={r} />
            ))}
          </Col>
        </RuleRow>
      </Backdrop>
    </Container>
  );
};
export default List;
