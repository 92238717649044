import React from "react";
import { WideItem as Item, Col } from "../../components/common/Page.styled";
import { SwipeUp } from "@styled-icons/fluentui-system-filled/";
import { CursorClick } from "@styled-icons/heroicons-outline/";
import { FormattedMessage } from "react-intl";

export enum DiscardStyle {
  Gesture,
  Tap,
}

type Props = {
  style?: DiscardStyle;
  updateDiscardStyle?: (l: DiscardStyle) => void;
};

export const DiscardStyleSelector = ({
  style,
  updateDiscardStyle = () => {},
}: Props) => (
  <Col>
    <Item
      selected={style === DiscardStyle.Gesture}
      onClick={() => updateDiscardStyle(DiscardStyle.Gesture)}
    >
      <FormattedMessage id="Push" /> <SwipeUp size={30} />
    </Item>
    <Item
      selected={style === DiscardStyle.Tap}
      onClick={() => updateDiscardStyle(DiscardStyle.Tap)}
    >
      <FormattedMessage id="Tap" /> <CursorClick size={30} />
    </Item>
  </Col>
);

export default DiscardStyleSelector;
