import Html from "../../components/common/r3f/Html";
import { FormattedMessage } from "react-intl";
import { LoadingText } from "./Game.styled";

const Loader = () => {
  return (
    <Html center position={[0, -1, 0]}>
      <LoadingText>
        <FormattedMessage id="Shuffling" />
      </LoadingText>
    </Html>
  );
};

export default Loader;
