import styled from "styled-components";

import { getImageHeight } from "./constants";

export const Container = styled.div`
  padding: 0px 5px 0px 0px;
  display: inline-block;
  color: ${(props) => props.theme.primary} !important;
  height: ${(p) => `${getImageHeight(p.ratio)}px`};
  border-radius: ${(props: { ratio: number }) => `${props.ratio * 0.5}`}rem;
  &:hover {
    background-color: #dee4ed;
    box-shadow: 0 ${(props) => `${props.ratio * 9}`}px #999;
  }
  &:active {
    background-color: #dee4ed;
    box-shadow: 0 ${(props) => `${props.ratio * 5}`}px #666;
    transform: translateY(${(props) => `${props.ratio * 4}`}px);
  }
`;
