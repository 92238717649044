import styled from "styled-components";

export const ActionFrame = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  min-height: 50px;
  display: flex;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.primary};
  user-select: none;
`;

export const ActionText = styled.div`
  text-transform: uppercase;
  display: flex;
  font-size: 2rem;
  padding: 1rem;
  color: ${({ theme }) => theme.primary};
`;

export const ActionTile = styled.div`
  padding: 1rem;
`;
