import React from "react";
import { idMap } from "../../components/common/icon/Icon";
import { Image } from "../../components/common/Image.styled";
import { UserCircle } from "@styled-icons/boxicons-regular";
import { IconBack, Overlay } from "./Avatar.styled";

export type Props = {
  ratio?: number;
  image?: string;
  children?: JSX.Element | JSX.Element[];
};

const Avatar = ({ image = "", ratio = 1, children }: Props) => {
  return (
    <IconBack>
      {image && image.includes("bot") && (
        <Image draggable={false} src={idMap[image]} alt="" ratio={ratio} />
      )}
      {image && !image.includes("bot") && (
        <Image
          draggable={false}
          referrerPolicy="no-referrer"
          src={image}
          alt=""
          ratio={ratio}
        />
      )}
      {!image && <UserCircle size={95 * ratio} />}
      <Overlay>{children}</Overlay>
    </IconBack>
  );
};
export default Avatar;
