import styled from "styled-components";

import bat from "./t9batty.png";
import dog from "./t9dog1.png";
import duck from "./t9ducky.png";
import elephant from "./t9elephant.png";
import fox from "./t9foxy.png";
import frog from "./t9froggy.png";
import kitty from "./t9kitty.png";
import lion from "./t9lion.png";
import panda from "./t9panda.png";
import penguin from "./t9penguin.png";

export const idMap: { [id: string]: string } = {
  "bot-t9lion": lion,
  "bot-t9batty": bat,
  "bot-t9dog1": dog,
  "bot-t9ducky": duck,
  "bot-t9elephant": elephant,
  "bot-t9foxy": fox,
  "bot-t9froggy": frog,
  "bot-t9kitty": kitty,
  "bot-t9panda": panda,
  "bot-t9penguin": penguin,
};

export const Icon = styled.div<{
  id: string;
  ratio?: number;
}>`
  background-image: ${(props) => `url(${idMap[props.id]})`};
  padding: 0px;
  width: 6rem;
  height: 6rem;
  background-size: cover;
  display: inline-block;
  background-color: #ffffff;
  border: "1px solid rgba(40, 167, 69, 0.5";
  border-radius: ${(props) => `${(props.ratio || 1) * 0.5}`}rem;
  display: flex;
  flex-direction: column-reverse;
`;
