import React, { Suspense } from "react";
import Canvas from "../../components/common/r3f/Canvas";
import Tile from "./Tile";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import TableTop from "./TableTop";
import Title from "./Title";
import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";
import { Scene } from "./Home.styled";
const ratio = 2;
const tiles = [
  "🀀",
  "🀁",
  "🀂",
  "🀃",
  "🀄",
  "🀅",
  "🀆",
  "🀇",
  "🀏",
  "🀙",
  "🀡",
  "🀐",
  "🀘",
];
const count = tiles.length + 1;
const half = Math.floor(count / 2);

export const Display = () => {
  return (
    <Scene>
      <Canvas dpr={devicePixelRatio}>
        <ThemeProvider theme={theme}>
        <ambientLight />
        <pointLight position={[0, 10, 30]} />
        <Title/>
        <TableTop />

        <Suspense fallback={null}>
          <group position={[0, -6, -2]}>
            {[...(tiles || [])]?.map((t, i) => (
              <Tile
                x={(-half + i) * 1.2 * ratio + 2}
                code={t}
                key={`${i}`}
                scale={ratio}
              />
            ))}
          </group>
        </Suspense>
        <OrbitControls />
        <PerspectiveCamera makeDefault fov={40} position={[0, 8, 33]} />
        </ThemeProvider>
      </Canvas>
    </Scene>
  );
};

export default Display;
