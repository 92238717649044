import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  primary: "#7fd8d4",
  dark: "#000000",
  light: "#ffffff",
  text: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
  title: "Bungee Shade",
  heading: "Audiowide"
};

export { theme };
