import styled from "styled-components";

export const Frame = styled.div`
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: normal;
`;

export const Table = styled.table`
  margin-top: 0.5rem;
  min-width: 300px;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
`;

export const Tr = styled.tr``;
export const Td = styled.td``;
export const THead = styled.thead``;
