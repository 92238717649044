import React from "react";
import { FormattedMessage } from "react-intl";
import { GameFrame } from "../../../api/mahjong-api";
import { PointsBreakdown } from "../../../components/game/PointsBreakdown";
import Hand from "../../scoring/list/Hand";
import Avatar from "../Avatar";
import { MatchResult } from "../frame";
import { PlayerMap } from "../Game";
import { Card, Row, Score, ScoreCard, Total } from "./GameOver.styled";

export type Props = {
  frame?: GameFrame;
  clientId?: string;
  playerMap: PlayerMap;
  currentScore?: MatchResult;
};

const SelfPickWin = ({ frame, playerMap, currentScore = {} }: Props) => {
  const clientId = frame?.requestClientId;
  const seats = frame?.seats || {};
  const winnerScore = currentScore[clientId || ""] || 0;
  const winningSeat = frame?.seats[clientId || ""];
  const losingSeats = Object.values(seats).filter(
    (s) => s.clientId !== clientId
  );
  const player = playerMap[clientId || ""];
  const result = winningSeat?.result;
  const hand = winningSeat?.hand;
  const winningPoints = winningSeat?.result?.totalPoints || 0;
  return (
    <Row>
      <ScoreCard>
        <Row>
          <Total>
            <FormattedMessage id="WinnerTotalPoints" values={{ points: result?.totalPoints }} />
          </Total>
          <Score>
          <FormattedMessage id="WinnerNumberOfRules" values={{
                rules: result?.matchedRules?.length || 0,
              }} />
          </Score>
        </Row>
        <Row>{result && <PointsBreakdown result={result} />}</Row>
      </ScoreCard>

      <Card>
        <FormattedMessage id="SelfPickWinningHand" />
        <Row>
          <Avatar ratio={0.5} image={player?.imageUrl || ""} />
          {winnerScore}+{winningPoints}={winnerScore + winningPoints}
        </Row>
        <Hand
          flowers={hand?.flowers}
          concealed={hand?.concealed}
          melded={hand?.melded}
          outOn={hand?.drawn}
          ratio={0.3}
        />
        <FormattedMessage id="LosingPlayers" />
        <Row>
          {losingSeats.map((s) => (
            <Row key={`losing${s.clientId}`}>
              <Avatar
                ratio={0.5}
                image={playerMap[s.clientId]?.imageUrl || ""}
              />
              {currentScore[s.clientId] || 0}
              {s.result?.totalPoints}=
              {(currentScore[s.clientId] || 0) + (s.result?.totalPoints || 0)}
            </Row>
          ))}
        </Row>
      </Card>
    </Row>
  );
};

export default SelfPickWin;
