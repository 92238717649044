import {
  GetTilesResponse,
  Kind,
  Meld,
  MeldKind,
  Tile,
} from "../../api/mahjong-api";

export const createPung = (tile: Tile): Meld => ({
  tiles: [tile, tile, tile],
  code: `${tile.code}${tile.code}${tile.code}`,
  meldKind: MeldKind.Pung,
});

export const createKong = (tile: Tile): Meld => ({
  tiles: [tile, tile, tile, tile],
  code: `${tile.code}${tile.code}${tile.code}${tile.code}`,
  meldKind: MeldKind.Kong,
});

export const createChow = (tile: Tile, all: GetTilesResponse): Meld => {
  const second = getNextTile(tile, all);
  const third = getNextTile(second, all);
  return {
    tiles: [tile, second, third],
    code: `${tile.code}${second.code}${third.code}`,
    meldKind: MeldKind.Chow,
  };
};

export const getNextNumber = (i: number) => (i < 9 ? i + 1 : 1);

export const getNextTile = (tile: Tile, all: GetTilesResponse) => {
  switch (tile.kind) {
    case Kind.Bamboo: {
      return all.bamboos[getNextNumber(tile.number || 1) - 1];
    }
    case Kind.Dot: {
      return all.dots[getNextNumber(tile.number || 1) - 1];
    }
    case Kind.Character: {
      return all.characters[getNextNumber(tile.number || 1) - 1];
    }
  }
  return tile;
};
