import React from "react";
import { Container } from "./Meld.styled";

import { Tile } from "./Tile";

type Props = {
  codes?: string[];
  ratio?: number;
};

export const Meld = ({ codes = [], ratio = 1 }: Props) => (
  <Container>
    {codes.map((t, i) => (
      <Tile code={t} ratio={ratio} key={`${t}${i}`} />
    ))}
  </Container>
);
