import React, { useState } from "react";
import * as THREE from "three";
import Html from "../../components/common/r3f/Html";
import { FormattedMessage } from "react-intl";
import { Wind } from "../../api/mahjong-api";
import { Column, Label } from "./seating/OpponentStatus.styled";
import { ChangeView, Frame } from "./MatchInfo.styled";
import { ConferenceRoom } from "@styled-icons/fluentui-system-filled";
import { StreetView } from "@styled-icons/boxicons-regular";
import { useFrame } from "@react-three/fiber";
import { Vector3 } from "three";

type Props = {
  round?: Wind;
  gameId?: number;
  matchId?: number;
  remainingCount?: number;
  showHistory?: () => void;
  goToLobby?: () => void;
};

const MatchInfo = ({
  round,
  gameId,
  matchId,
  remainingCount,
  showHistory = () => {},
  goToLobby,
}: Props) => {
  let position = new THREE.Vector3(15.5, 11.5, 0);
  let rotX = 0;
  const [topDown, setTopDown] = useState(false);

  const handleViewClick = () => {
    if (topDown) {
      setTopDown(false);
      rotX = 0;
    } else {
      setTopDown(true);
      rotX = Math.PI / 2;
    }
  };

  useFrame((state) => {
    if (topDown) {
      state.camera.lookAt(new Vector3(0, 0, 0));
      state.camera.position.lerp(new Vector3(0, 30, 12), 0.1);
    } else {
      state.camera.lookAt(new Vector3(0, 0, 0));
      state.camera.position.lerp(new Vector3(0, 16, 32), 0.1);
    }
  });

  return (
    <>
      {topDown && (
        <Html
          transform
          center
          position={new Vector3(0, 3, 0)}
          rotation-x={-Math.PI / 2}
        >
          <ChangeView>
            <StreetView size={150} onClick={() => handleViewClick()} />
          </ChangeView>
        </Html>
      )}
      <Html transform center position={position} rotation-x={rotX}>
        <Frame>
          {matchId && (
            <Column
              onClick={() => {
                showHistory();
              }}
            >
              <Label>
                <FormattedMessage id="Match" />
              </Label>
              {matchId}
            </Column>
          )}
          {round && (
            <Column>
              <Label>
                <FormattedMessage id="Round" />
              </Label>
              <FormattedMessage id={round} />
            </Column>
          )}
          {(gameId || 0) > 0 && (
            <Column>
              <Label>
                <FormattedMessage id="Game" />
              </Label>
              {gameId}
            </Column>
          )}
          {remainingCount && (
            <Column>
              <Label>
                <FormattedMessage id="Wall" />
              </Label>
              {remainingCount}
            </Column>
          )}
          <Column>
            <Label>
              <FormattedMessage id="View" />
            </Label>
            <StreetView size={50} onClick={() => handleViewClick()} />
          </Column>
          <Column>
            <Label>
              <FormattedMessage id="Lobby" />
            </Label>
            <ConferenceRoom size={50} color="red" onClick={goToLobby} />
          </Column>
        </Frame>
      </Html>
    </>
  );
};

export default MatchInfo;
