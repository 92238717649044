import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Name, Tiles } from "./Tray.styled";

type TrayProps = {
  name: string;
  className?: string;
  children?: Object;
  isActive?: boolean;
  single?: boolean;
  onClick?: () => void;
  color?: string;
};

export const Tray = ({
  children,
  name,
  single = false,
  onClick = () => {},
  isActive,
}: TrayProps) => (
  <Container single={single} onClick={onClick} active={isActive}>
    <Name single={single} active={isActive}>
      <FormattedMessage id={name} />
    </Name>
    <Tiles>{children}</Tiles>
  </Container>
);
