import React from "react";
import * as THREE from "three";

import Html from "../../../components/common/r3f/Html";
import { FormattedMessage } from "react-intl";
import { Row, Column, Frame, Label, Badge } from "./OpponentStatus.styled";
import Avatar from "../Avatar";
import { Tile } from "../../../components/game/Tile";
import { windCodes } from "../../../components/game/constants";
import Action, { Props as ActionProps } from "../Action";
import Capture from "./Capture";
import { Wind } from "../../../api/mahjong-api";
import { Timer as Icon } from "@styled-icons/boxicons-regular";
import CountUp from "react-countup";

type Props = {
  action?: ActionProps;
  name?: string;
  image?: string;
  points?: number;
  wind: Wind;
  captureRotation?: number;
  turnTime?: number;
};

function OpponentStatus({
  points = 0,
  wind,
  name,
  image,
  action,
  captureRotation,
  turnTime = 10,
}: Props) {
  const pos = new THREE.Vector3(0, 3.75, 4.5);
  return (
    <group>
      <Capture
        action={action}
        rotation={captureRotation}
        key={`capture${action?.lastDiscard?.tile?.id}`}
      />
      {action && (
        <Action
          {...action}
          key={`${action.actionType}`}
          rotation={captureRotation}
        />
      )}

      <Html transform center position={pos} rotation={[0, Math.PI, 0]}>
        <Frame>
          <Avatar image={image} ratio={3}>
            <Row>{name}</Row>
            <Row>
              <Column>
                <Label>
                  <FormattedMessage id="Seat" />
                </Label>
                <Tile ratio={0.55} code={windCodes.get(wind) || "🀀"} />
              </Column>
              <Column>
                <Label>
                  <FormattedMessage id="Points" />
                </Label>
                {points}
              </Column>
              <Column>
                <Badge>
                  <Icon size={40} />
                  {(action?.actionType?.includes("Draw") ||
                    action?.actionType?.includes("Capture")) && (
                    <CountUp
                      key={`${action?.actionType}`}
                      start={turnTime}
                      end={0}
                      duration={turnTime}
                    />
                  )}
                </Badge>
              </Column>
            </Row>
          </Avatar>
        </Frame>
      </Html>
    </group>
  );
}

export default OpponentStatus;
