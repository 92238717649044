import React from "react";
import { useSpring } from "@react-spring/three";
import { Tile } from "../../../../api/mahjong-api";
import GestureTile from "../../../../components/three/GestureTile";

type Props = JSX.IntrinsicElements["mesh"] & {
  lastDiscard?: Tile;
  isDiscardRequest?: boolean;
  ratio?: number;
  x?: number;
};

const Discard = ({
  lastDiscard,
  ratio = 1,
  isDiscardRequest,
  x = 0,
}: Props) => {
  const { discardZ } = useSpring({
    from: { discardZ: -1.5 },
    to: { discardZ: -4.5 },
  });
  const { discardR } = useSpring({
    from: { discardR: 0 },
    to: { discardR: -Math.PI / 2 },
  });

  return (
    <>
      {isDiscardRequest && lastDiscard?.id && (
        <GestureTile
          shouldReset={false}
          rotateX={discardR}
          positionZ={discardZ}
          x={x}
          code={lastDiscard?.code}
          scale={ratio}
        />
      )}
    </>
  );
};
export default Discard;
