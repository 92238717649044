import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { DiscardStyle } from "../profile/DiscardStyle";
import { profileApi } from "../profile/service";

// Define a type for the slice state
interface ClientState {
  clientId: string;
  connectionId: string;
  locale: string;
  color: string;
  discardStyle: DiscardStyle;
  turnTime: number;
  imagePath?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  userId?: string;
  playerId?: number;
}

// Define the initial state using that type
const initialState: ClientState = {
  clientId: `guest-${uuidv4()}`,
  connectionId: "",
  locale: "en",
  color: "#7fd8d4",
  turnTime: 10,
  discardStyle: DiscardStyle.Gesture,
};

export const clientSlice = createSlice({
  name: "client",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateConnectionId(state, action: PayloadAction<string>) {
      state.connectionId = action.payload;
    },
    updateLocale(state, action: PayloadAction<string>) {
      state.locale = action.payload;
    },
    resetClientId(state) {
      state.clientId = `guest-${uuidv4()}`;
    },
    updateColor(state, action: PayloadAction<string>) {
      state.color = action.payload;
    },
    updateTurnTime(state, action: PayloadAction<number>) {
      state.turnTime = action.payload;
    },
    updateDiscardStyle(state, action: PayloadAction<DiscardStyle>) {
      state.discardStyle = action.payload;
    },
    logout(state) {
      state.imagePath = undefined;
      state.userId = undefined;
      state.playerId = undefined;
      state.email = undefined;
      state.firstName = undefined;
      state.lastName = undefined;
    },
  },
  extraReducers: (b) => {
    b.addMatcher(
      profileApi.endpoints.profile.matchFulfilled,
      (state, { payload }) => {
        state.imagePath = payload.imagePath;
        state.userId = payload.userId || undefined;
        state.playerId = payload.playerId || undefined;
        state.email = payload.email || undefined;
        state.firstName = payload.firstName || undefined;
        state.lastName = payload.lastName || undefined;
      }
    );
  },
});

export const {
  updateConnectionId,
  updateLocale,
  resetClientId,
  updateColor,
  updateTurnTime,
  updateDiscardStyle,
  logout,
} = clientSlice.actions;

export default clientSlice.reducer;
