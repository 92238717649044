import React from "react";

import { MeldKind } from "../../../../api/mahjong-api";
import { Button } from "../../../../components/common/Buttons.styled";
import { ButtonGroup } from "./TileBoard.styled";

type Props = {
  meldKind: MeldKind;
  activeButtonClick: (type: MeldKind) => void;
};

export const MeldTypeButtons = ({ meldKind, activeButtonClick }: Props) => (
  <ButtonGroup>
    <Button
      active={meldKind === MeldKind.Chow}
      onClick={() => activeButtonClick(MeldKind.Chow)}
    >
      {MeldKind.Chow}
    </Button>
    <Button
      active={meldKind === MeldKind.Pung}
      onClick={() => activeButtonClick(MeldKind.Pung)}
    >
      {MeldKind.Pung}
    </Button>
    <Button
      active={meldKind === MeldKind.Kong}
      onClick={() => activeButtonClick(MeldKind.Kong)}
    >
      {MeldKind.Kong}
    </Button>
  </ButtonGroup>
);
