import React from "react";
import { useSpring, useChain, useSpringRef } from "@react-spring/three";
import { Tile } from "../../../../api/mahjong-api";
import GestureTile from "../../../../components/three/GestureTile";
import { useSelector } from "react-redux";
import { selectDiscardStyle } from "../../../auth/selector";
import { DiscardStyle } from "../../../profile/DiscardStyle";
import TapTile from "../../../../components/three/TapTile";

type Props = JSX.IntrinsicElements["mesh"] & {
  dragId?: number;
  setDragId?: (id: number) => void;
  isActive?: boolean;
  x?: number;
  concealed: Tile[];
  ratio?: number;
  onConcealedClick?: (tileId: number) => void;
};

const Concealed = ({
  concealed,
  isActive = false,
  dragId = -1,
  setDragId = (id: number) => {},
  ratio = 1,
  x = 0,
  onConcealedClick = () => {},
}: Props) => {
  const posZRef = useSpringRef();
  const rotXRef = useSpringRef();
  const discardStyle = useSelector(selectDiscardStyle);

  const { positionZ } = useSpring({
    from: { positionZ: -15 },
    to: { positionZ: 0 },
    ref: posZRef,
  });

  const { rotateX } = useSpring({
    from: { rotateX: Math.PI / 2 },
    to: { rotateX: 0 },
    ref: rotXRef,
  });

  useChain([posZRef, rotXRef]);
  let pos = x;

  if (discardStyle === DiscardStyle.Tap) {
    return (
      <>
        {[...(concealed || [])]
          .sort((a, b) => a.id - b.id)
          ?.map((t, i) => (
            <TapTile
              tapped={dragId === t.id}
              startTap={() => setDragId(t.id)}
              isActive={isActive}
              x={pos++ * 1.2 * ratio + 1}
              code={t.code}
              key={`${t.id}`}
              scale={ratio}
              positionZ={positionZ}
              rotateX={rotateX}
              onClick={() => onConcealedClick(t.id)}
            />
          ))}
      </>
    );
  } else {
    return (
      <>
        {[...(concealed || [])]
          .sort((a, b) => a.id - b.id)
          ?.map((t, i) => (
            <GestureTile
              allowDrag={dragId === -1 || dragId === t.id}
              startDrag={() => setDragId(t.id)}
              endDrag={() => setDragId(-1)}
              isActive={isActive}
              x={pos++ * 1.2 * ratio + 1}
              code={t.code}
              key={`${t.id}`}
              scale={ratio}
              positionZ={positionZ}
              rotateX={rotateX}
              onClick={() => onConcealedClick(t.id)}
            />
          ))}
      </>
    );
  }
};

export default Concealed;
