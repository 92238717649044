import { RoundedBox } from "@react-three/drei";
import React from "react";
import { Color } from "three";
const chair = new Color(0x2a2a26);

const Ground = () => {
  return (
    <>
      <mesh
        receiveShadow
        position={[0, -20, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
      >
        <planeBufferGeometry attach="geometry" args={[200, 100]} />
        <meshStandardMaterial attach="material" color={new Color(0x42423b)} />
      </mesh>
      <RoundedBox
        radius={0.8}
        smoothness={10}
        args={[5, 10, 5]}
        position={[24, -10, 0]}
      >
        <meshStandardMaterial attachArray="material" color={chair} />
        <meshStandardMaterial attachArray="material" color={chair} />
      </RoundedBox>
      <RoundedBox
        radius={0.8}
        smoothness={10}
        args={[5, 10, 5]}
        position={[-24, -10, 0]}
      >
        <meshStandardMaterial attachArray="material" color={chair} />
        <meshStandardMaterial attachArray="material" color={chair} />
      </RoundedBox>
    </>
  );
};

export default Ground;
