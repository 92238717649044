import React, { Suspense } from "react";
import { GameFrame, GameResult, Player } from "../../api/mahjong-api";
import Seats from "./seating/Seats";
import { LoadingText, ViewPort } from "./Game.styled";
import Canvas from "../../components/common/r3f/Canvas";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import TableTop from "./TableTop";
import Html from "../../components/common/r3f/Html";
import MatchInfo from "./MatchInfo";
import { TitleText } from "./Game.styled";
import { tilePaths } from "../../components/three/lookup";
import Tile from "../../components/three/GestureTile";
import Loader from "./Loader";
import BackWall from "./scene/BackWall";
import Ground from "./scene/Ground";
import { OptionsActionProps } from "./options/Options";
import { FormattedMessage } from "react-intl";

export type PlayerMap = { [key: string]: Player };

type Props = OptionsActionProps & {
  frame?: GameFrame;
  clientId: string;
  playerMap?: PlayerMap;
  matchResults?: GameResult[];
  enableControls?: boolean;
  turnTime?: number;
  discard?: (tileId: number) => void;
  continueMatch?: () => void;
  goToLobby?: () => void;
  visible?: boolean;
};

const Game = ({
  frame,
  clientId,
  turnTime = 10,
  playerMap = {},
  matchResults = [],
  enableControls = false,
  draw,
  discard,
  capture,
  selfKong,
  selfPick,
  goToLobby,
  visible = false,
}: Props) => {
  return (
    <ViewPort visible={visible}>
      <Canvas dpr={devicePixelRatio}>
        {enableControls && <OrbitControls />}
        <pointLight position={[0, 10, 30]} intensity={2} />
        <Html transform center position={[-15.5, 11.5, 0]}>
          <TitleText>
            <FormattedMessage id="MahjongSpot" />
          </TitleText>
        </Html>
        <TableTop />
        <BackWall />
        <Ground />
        {!frame && (
          <Html center>
            <LoadingText>
              <FormattedMessage id="PleaseWaitForMatchStart" />
            </LoadingText>
          </Html>
        )}

        <MatchInfo
          matchId={frame?.matchId}
          gameId={frame?.gameId}
          round={frame?.wind}
          remainingCount={frame?.wallCount}
          goToLobby={goToLobby}
        />

        <Suspense fallback={<Loader />}>
          {visible && (
            <Seats
              turnTime={turnTime}
              frame={frame}
              clientId={clientId}
              playerMap={playerMap}
              matchResults={matchResults}
              draw={draw}
              discard={discard}
              capture={capture}
              selfKong={selfKong}
              selfPick={selfPick}
            />
          )}
          <group position={[-5, -50, -50]}>
            {Object.keys(tilePaths).map((c, i) => (
              <Tile key={`load-${c}`} code={c} x={i} />
            ))}
          </group>
        </Suspense>

        <PerspectiveCamera makeDefault fov={45} position={[0, 16, 32]} />
      </Canvas>
    </ViewPort>
  );
};

export default Game;
