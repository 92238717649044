import SlidingPane from "react-sliding-pane";
import styled from "styled-components";

export const Pane = styled(SlidingPane)`
  background-color: #282828;

  & .slide-pane__header {
    background: #282828;
  }

  & .slide-pane__content {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  & .slide-pane__title {
    text-transform: uppercase;
    font-size: 2rem;
    padding-left: 5rem;
    color: ${({ theme }) => theme.primary};
  }
`;
