import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { GetStatisticsResponse } from "../../api/mahjong-api";
import Display from "./Display";
import { SupportedImage as Image } from "./Home.styled";
import {
  ActionButton as Button,
  SocialButton,
} from "../../components/common/Buttons.styled";
import Language from "../../components/nav/Language";
import { Helmet } from "react-helmet-async";
import MotdButton from "../../components/common/buttons/MotdButton";
import Statistics from "./Statistics";
import {
  Col,
  Row,
  Page,
  Backdrop,
  PageDescription as Description,
  FixedPanel as Panel,
} from "../../components/common/Page.styled";
import ios from "../../image/ios.png";
import android from "../../image/android.png";
import chrome from "../../image/chrome.png";
import firefox from "../../image/firefox.png";
import safari from "../../image/safari.png";
const googleAuthUrl = window._env_.GOOGLE_AUTH_URL;

export type Props = {
  clientId?: string;
  locale?: string;
  statistics?: GetStatisticsResponse;
  playVsBots?: () => void;
  selectLocale?: (l: string) => void;
};

const Home = ({
  clientId = "",
  statistics,
  locale = "en",
  playVsBots = () => {},
  selectLocale = () => {},
}: Props) => (
  <Page>
    <Helmet>
      <title>Mahjong Spot - Home</title>
      <meta name="description" content="Online Multi Player Mahjong" />
    </Helmet>

    <Backdrop>
      <Panel>
        <Display />
        <Statistics statistics={statistics} />
        <Language locale={locale} selectLocale={selectLocale} />
      </Panel>
      <Panel>
        <div>
          <Button onClick={() => playVsBots()}>
            <FormattedMessage id="PlayVsBots" />
          </Button>
        </div>
        <div>
          <NavLink to="/motd">
            <MotdButton />
          </NavLink>
        </div>
        <div>
          <NavLink to="/match">
            <Button>
              <FormattedMessage id="EnterLobby" />
            </Button>
          </NavLink>
        </div>

        <form method="POST" action={`${googleAuthUrl}&clientId=${clientId}`}>
          <SocialButton
            type="submit"
            name="provider"
            value="Google"
            title={`Login using Google`}
          />
        </form>
      </Panel>
    </Backdrop>
    <Backdrop>
      <Panel>
        Learn To Play
        <Description>
          16 tile mahjong game based on classic multiplayer mahjong mechanics.
          Check out the rules on how to score, or jump right to the calculator
          and use it with your in person games.
        </Description>
      </Panel>
      <Panel>
        <div>
          <NavLink to="/scoring">
            <Button>
              <FormattedMessage id="Rules" />
            </Button>
          </NavLink>
        </div>
        <div>
          <NavLink to="/calculator">
            <Button>
              <FormattedMessage id="Calculator" />
            </Button>
          </NavLink>
        </div>
      </Panel>
    </Backdrop>
    <Backdrop>
      <Panel>
        Play Everywhere!
        <Description>
          Designed to be responsive so that it can be enjoyed on a phone, tablet
          or large monitors.
        </Description>
      </Panel>
      <Panel>
        <Col>
          Mobile
          <Row>
            <Image src={ios} ratio={0.5} />
            <Image src={android} ratio={0.5} />
          </Row>
        </Col>
        <Col>
          Desktop
          <Row>
            <Image src={safari} ratio={0.5} />
            <Image src={chrome} ratio={0.5} />
            <Image src={firefox} ratio={0.43} />
          </Row>
        </Col>
      </Panel>
    </Backdrop>
    <Backdrop>
      <Panel>
        Customize
        <Description>
          Sign in to customize! Pick the tile color, turn time and the choice of
          discarding with gesture or tap.
        </Description>
      </Panel>
      <Panel>
        <form method="POST" action={`${googleAuthUrl}&clientId=${clientId}`}>
          <SocialButton
            type="submit"
            name="provider"
            value="Google"
            title={`Login using Google`}
          />
        </form>
      </Panel>
    </Backdrop>
  </Page>
);

export default Home;
