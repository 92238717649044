import React from "react";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useMatch, useNavigate, useLocation } from "react-router-dom";
import Community from "./Community";
import {
  useFindGameResultsQuery,
  useFindPlayersQuery,
  useGetPlayerQuery,
} from "./service";
import Loader from "../../components/common/Loader";
import { Player } from "../../api/mahjong-api";

const Container = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoading: isFindLoading, data } = useFindPlayersQuery();
  const { isLoading: isFindGameResultsLoading, data: gameResults } =
    useFindGameResultsQuery();

  const players = data?.players || [];

  const routeMatch = useMatch("/community/view/:playerId");
  const playerId = +(routeMatch?.params.playerId || "");

  const { data: getResponse, isLoading: isGetLoading } = useGetPlayerQuery(
    playerId,
    { skip: !playerId }
  );
  let player: Player = {
    id: 0,
    createdAt: "",
    createdBy: "",
  };

  if (getResponse?.player) {
    player = getResponse.player;
  }
  if (isFindLoading || isGetLoading || isFindGameResultsLoading) {
    return <Loader />;
  }

  return (
    <Community
      player={player}
      pathname={pathname}
      navigate={navigate}
      gameResults={gameResults}
      players={players}
      viewPlayer={(id) => navigate(`view/${id}`)}
    />
  );
};

export default Container;
