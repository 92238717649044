import React from "react";
import * as THREE from "three";
import { Discard } from "../../../../api/mahjong-api";
import GestureTile from "../../../../components/three/GestureTile";

export type WallProps = JSX.IntrinsicElements["mesh"] & {
  discards?: Discard[];
  ratio?: number;
};

const Discards = ({ discards = [], ratio = 1.3 }: WallProps) => {
  const chunkSize = 8;
  const chunks = [];
  for (let i = 0; i < discards.length; i += chunkSize) {
    const chunk = discards.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return (
    <>
      {chunks.map((ch, j) => (
        <group key={`g${j}`} position={new THREE.Vector3(-5, 0, -4 - j * 2)}>
          {ch?.map((c, i) => (
            <GestureTile
              x={i * 1.2 * ratio}
              code={c.tile?.code || ""}
              key={`${c}${i}`}
              scale={ratio}
              rotateX={-Math.PI / 2}
            />
          ))}
        </group>
      ))}
    </>
  );
};
export default Discards;
