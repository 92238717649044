import styled from "styled-components";

export const IconBack = styled.div`
  background-color: transparent;
  padding: 0.3rem;
`;

export const Overlay = styled.div`
  bottom: 1rem;
  position: absolute;
  z-index: 100;
  width: 87%;
`;
