import { RootState } from "../../app/store";

// Other code such as selectors can use the imported `RootState` type
export const selectClientId = (state: RootState) => state.client.clientId;

export const selectConnectionId = (state: RootState) =>
  state.client.connectionId;

export const selectLocale = (state: RootState) => state.client.locale;

export const selectColor = (state: RootState) => state.client.color;

export const selectProfileImage = (state: RootState) => state.client.imagePath;

export const selectProfileEmail = (state: RootState) => state.client.email;

export const selectPlayerId = (state: RootState) => state.client.playerId;

export const selectTurnTime = (state: RootState) => state.client.turnTime;

export const selectDiscardStyle = (state: RootState) =>
  state.client.discardStyle;
