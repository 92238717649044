import React from "react";
import { Tile } from "../../../../api/mahjong-api";
import { TileButton } from "../../../../components/game/TileButton";
import { SectionProps } from "../Hand";
import { Section } from "../constants";
import { Tray } from "./Tray";

type TilesProp = SectionProps & {
  tiles: Tile[];
};

const Concealed = ({
  onClick,
  onTrayClick = () => {},
  isActive,
  tiles,
}: TilesProp) => (
  <Tray
    name={Section.Concealed}
    onClick={() => onTrayClick(Section.Concealed)}
    isActive={isActive}
  >
    {tiles.map((t, i) => (
      <TileButton
        ratio={0.4}
        key={`${t.id}${i}`}
        code={t.code}
        onClick={() => onClick(i)}
      />
    ))}
  </Tray>
);

export default Concealed;
