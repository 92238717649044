import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-content: stretch;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;
