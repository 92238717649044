import React from "react";
import { GetTilesResponse } from "../../../api/mahjong-api";
import { PageDescription as Description } from "../../../components/common/Page.styled";
import { Tile } from "../../../components/game/Tile";
import { TdNoWrap as Td, RulesPanel as Panel } from "./List.styled";

type Props = {
  tiles?: GetTilesResponse;
};

const Tiles = ({ tiles }: Props) => {
  const { winds, characters, bamboos, dots, flowers, dragons } = tiles || {};
  return (
    <Panel>
      Tiles
      <Description>
        There are 144 tiles in total, split into 3 suits, dragons, winds and
        flowers.
      </Description>
      <table>
        <tbody>
          <tr>
            <Td>Winds</Td>
            <Td>
              {winds?.map((t, i) => (
                <Tile key={`${t.id}${i}`} code={t.code} ratio={0.3} />
              ))}
            </Td>
            <Td>x4</Td>
          </tr>
          <tr>
            <Td>Dragons</Td>
            <Td>
              {dragons?.map((t, i) => (
                <Tile key={`${t.id}${i}`} code={t.code} ratio={0.3} />
              ))}
            </Td>
            <Td>x4</Td>
          </tr>
          <tr>
            <Td>Characters</Td>
            <Td>
              {characters?.map((t, i) => (
                <Tile key={`${t.id}${i}`} code={t.code} ratio={0.3} />
              ))}
            </Td>
            <Td>x4</Td>
          </tr>
          <tr>
            <Td>Bamboos</Td>
            <Td>
              {bamboos?.map((t, i) => (
                <Tile key={`${t.id}${i}`} code={t.code} ratio={0.3} />
              ))}
            </Td>
            <Td>x4</Td>
          </tr>
          <tr>
            <Td>Dots</Td>
            <Td>
              {dots?.map((t, i) => (
                <Tile key={`${t.id}${i}`} code={t.code} ratio={0.3} />
              ))}
            </Td>
            <Td>x4</Td>
          </tr>
          <tr>
            <Td>Flowers</Td>
            <Td>
              {flowers?.map((t, i) => (
                <Tile key={`${t.id}${i}`} code={t.code} ratio={0.3} />
              ))}
            </Td>
            <Td>x1</Td>
          </tr>
        </tbody>
      </table>
    </Panel>
  );
};

export default Tiles;
