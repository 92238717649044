import React from "react";
import { CellProps, useTable } from "react-table";
import { FormattedMessage } from "react-intl";
import { HandResult, PatternMatchResult } from "../../api/mahjong-api";
import { Table, THead, Tr, Td } from "./PointsBreakdown.styled";

type Props = {
  result?: HandResult;
};

function compare(a: PatternMatchResult, b: PatternMatchResult) {
  if (a.points < b.points) {
    return 1;
  }
  if (a.points > b.points) {
    return -1;
  }
  return 0;
}

export const PointsBreakdown = ({
  result = { matchedRules: [] },
}: Props) => {
  const data = React.useMemo(
    () => [...(result?.matchedRules || [])].sort(compare),
    [result.matchedRules]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="Points"/>,
        id: "points",
        Cell: (r: CellProps<PatternMatchResult>) => r.row.original.points,
      },
      {
        Header: "Rule",
        id: "rule",
        Cell: (r: CellProps<PatternMatchResult>) => <FormattedMessage id={`Rule_${r.row.original.pattern}_Name`}/>,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <Table {...getTableProps()}>
      <THead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </THead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
              })}
            </Tr>
          );
        })}
      </tbody>
    </Table>
  );
};
