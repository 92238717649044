import React from "react";
import { Match as IMatch } from "../../api/mahjong-api";
import {
  Panel,
  Col,
  MainPanel,
  Card,
} from "../../components/common/Page.styled";
import { ActionButton as Button } from "../../components/common/Buttons.styled";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { PlayerMap } from "../game/Game";
import { Player, Players, Spot } from "./Create.styled";
import Avatar from "../game/Avatar";
import { ViewLabel as Label } from "./Create.styled";
import { PlayerName } from "./View.styled";

export type Props = {
  match: IMatch;
  playerMap?: PlayerMap;
  joinMatch?: () => void;
  startMatch?: () => void;
  playMatch?: () => void;
  canJoin?: boolean;
  canStart?: boolean;
  canPlay?: boolean;
};

const Match = ({
  match,
  playerMap = {},
  joinMatch = () => {},
  startMatch = () => {},
  playMatch = () => {},
  canJoin = false,
  canStart = false,
  canPlay = false,
}: Props) => {
  const { name, settings, createdAt, clients } = match;
  const v = clients || [];
  return (
    <Panel>
      <MainPanel>
        <Card>
          <Players>
            {v.map((c, i) => (
              <Spot key={`spot${i}`}>
                <Player>
                  <FormattedMessage id={`Player${i + 1}`} />
                </Player>
                <Avatar
                  ratio={0.75}
                  image={playerMap[c.clientId]?.imageUrl || ""}
                />
                <PlayerName>{playerMap[c.clientId]?.name || "Guest"}</PlayerName>
              </Spot>
            ))}
          </Players>
        </Card>
        <Card>
          <Col>
            <Label>
              <FormattedMessage id="Name" />
            </Label>
            {name}
          </Col>
          <Col>
            <Label>
              <FormattedMessage id="Rounds" />
            </Label>
            {settings?.totalRounds}
          </Col>
          <Col>
            <Label>
              <FormattedMessage id="CreatedAt" />
            </Label>
            <FormattedDate value={createdAt} />{" "}
            <FormattedTime value={createdAt} />
          </Col>
          <Col>
            {canJoin && (
              <Button onClick={() => joinMatch()}>
                <FormattedMessage id="Join" />
              </Button>
            )}
            {canPlay && (
              <Button onClick={() => playMatch()}>
                <FormattedMessage id="Play" />
              </Button>
            )}
            {canStart && (
              <Button onClick={() => startMatch()}>
                <FormattedMessage id="Start" />
              </Button>
            )}
          </Col>
        </Card>
      </MainPanel>
    </Panel>
  );
};

export default Match;
