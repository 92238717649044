import React from "react";
import { GameFrame, GameResult } from "../../../api/mahjong-api";
import { PlayerMap } from "../Game";
import GameOver from "./GameOver";
import MatchOver from "./MatchOver";
import { getMatchResult } from "../frame";
import MatchHistory from "./MatchHistory";
import { Overlay } from "./Display.styled";
import { useSpring } from "@react-spring/web";

export type Props = {
  frame?: GameFrame;
  clientId?: string;
  playerMap?: PlayerMap;
  shouldShowHistory?: boolean;
  matchResults?: GameResult[];
  showHistory?: () => void;
  hideHistory?: () => void;
  continueMatch?: () => void;
  goToLobby?: () => void;
  goToNewMatch?: () => void;
  goToMotd?: () => void;
};

const Display = ({
  frame,
  playerMap = {},
  shouldShowHistory,
  matchResults = [],
  hideHistory = () => {},
  showHistory = () => {},
  continueMatch = () => {},
  goToMotd = () => {},
  goToLobby,
  goToNewMatch,
}: Props) => {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Overlay style={props}>
      {shouldShowHistory && (
        <MatchHistory
          matchResults={matchResults}
          playerMap={playerMap}
          hideHistory={() => hideHistory()}
        />
      )}

      {!shouldShowHistory && frame?.isComplete && !frame?.matchComplete && (
        <GameOver
          frame={frame}
          currentScore={getMatchResult(matchResults, frame?.gameId)}
          playerMap={playerMap}
          continueMatch={continueMatch}
        />
      )}
      {!shouldShowHistory && frame?.isComplete && frame?.matchComplete && (
        <MatchOver
          playerMap={playerMap}
          showHistory={showHistory}
          result={getMatchResult(matchResults)}
          goToLobby={goToLobby}
          goToMotd={goToMotd}
        />
      )}
    </Overlay>
  );
};
export default Display;
