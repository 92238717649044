import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { GetTilesResponse, MeldKind, Tile } from "../../../../api/mahjong-api";
import { ActionButton } from "../../../../components/common/Buttons.styled";
import { Card } from "../../../../components/common/Page.styled";
import { Section } from "../constants";
import { MeldTypeButtons } from "./MeldTypeButtons";
import { Board, Pad, SideBoard } from "./TileBoard.styled";
import InputRow from "./InputRow";

export type Selection = {
  tile: Tile;
  kind?: MeldKind;
};

type Props = {
  tiles: GetTilesResponse;
  section: Section;
  onClick: (tile: Selection) => void;
  onCalculate: () => void;
};

export const TileBoard = ({
  tiles: { winds, characters, bamboos, dots, dragons },
  onClick,
  onCalculate,
}: Props) => {
  const [kind, setKind] = useState<MeldKind>(MeldKind.Pung);

  const onTileClick = (tile: Tile) => onClick({ tile, kind });
  return (
    <Card>
      <MeldTypeButtons meldKind={kind} activeButtonClick={(m) => setKind(m)} />

      <SideBoard>
        <InputRow tiles={winds} onClick={onTileClick} />
        <InputRow tiles={dragons} onClick={onTileClick} />
      </SideBoard>

      <Board>
        <Pad>
          <InputRow tiles={characters} onClick={onTileClick} />
        </Pad>
        <Pad>
          <InputRow tiles={bamboos} onClick={onTileClick} />
        </Pad>
        <Pad>
          <InputRow tiles={dots} onClick={onTileClick} />
        </Pad>
      </Board>

      <ActionButton onClick={onCalculate}>
        <FormattedMessage id="Calculate" />
      </ActionButton>
    </Card>
  );
};
