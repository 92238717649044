import React, { PropsWithChildren } from "react";
import ThemeProvider from "../ConnectedThemeProvider";
import IntlProvider from "../../../i18n/ConnectedIntlProvider";
import { store } from "../../../app/store";
import { Provider } from "react-redux";
import { Canvas as R3FCanvas, Dpr } from "@react-three/fiber";

type Props = {
  dpr?: Dpr;
}

const Canvas = ({ children, dpr }: PropsWithChildren<Props>) => (
    <R3FCanvas dpr={dpr}>
      <Provider store={store}>
        <ThemeProvider>
          <IntlProvider>
            {children}
          </IntlProvider>
        </ThemeProvider>
      </Provider>
    </R3FCanvas>
);
  
  export default Canvas;