import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectClientId,
  selectProfileEmail,
  selectProfileImage,
} from "../../features/auth/selector";
import TopNav from "./TopNav";

const Container = () => {
  const image = useSelector(selectProfileImage);
  const email = useSelector(selectProfileEmail);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const clientId = useSelector(selectClientId);

  return (
    <TopNav
      clientId={clientId}
      email={email}
      pathname={pathname}
      navigate={navigate}
      imageUrl={image}
    />
  );
};

export default Container;
