import React from "react";
import { FormattedMessage } from "react-intl";
import { GameFrame, ResultKind } from "../../../api/mahjong-api";
import { Frame, Card, Row, Button } from "./GameOver.styled";
import SelfPickWin from "./SelfPickWin";
import CaptureWin from "./CaptureWin";
import { PlayerMap } from "../Game";
import { MatchResult } from "../frame";
import Draw from "./Draw";
import { Title } from "./Display.styled";

export type Props = {
  frame?: GameFrame;
  currentScore?: MatchResult;
  playerMap: PlayerMap;
  continueMatch?: () => void;
};

const GameOver = ({
  playerMap,
  frame,
  currentScore,
  continueMatch,
}: Props) => {
  const clientId = frame?.requestClientId;
  const winningSeat = frame?.seats[clientId || ""];
  const isSelfPick = winningSeat?.result?.kind === ResultKind.SelfPick;
  const isDraw = winningSeat?.result?.kind === ResultKind.Draw;
  return (
    <Frame>
      <Title><FormattedMessage id="GameOver" /></Title>
      <Row>
        {isSelfPick && (
          <SelfPickWin
            currentScore={currentScore}
            playerMap={playerMap}
            frame={frame}
          />
        )}
        {isDraw && <Draw />}
        {!isSelfPick && !isDraw && (
          <CaptureWin
            currentScore={currentScore}
            playerMap={playerMap}
            frame={frame}
          />
        )}
      </Row>
      <Row>
        <Card>
          <Button onClick={continueMatch}>
            <FormattedMessage id="Continue" />
          </Button>
        </Card>
      </Row>
    </Frame>
  );
};

export default GameOver;
